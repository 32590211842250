import { DocumentData, where } from 'firebase/firestore'
import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import {
  ConnectionStatus,
  ConnectionType,
  ConversationType,
} from '../../../domain/types/Connection'
import { OrgType, Profile } from '../../../domain/types/Profile'

import * as profileService from '../profile/profileService'
import { ExploreCardData } from '../explore/ExploreCardData'
import { getExploreDataByOrgId } from '../explore/exploreService'
import { useEffect, useState } from 'react'
import useProfileStore from '../../appState/profileStore'

// eslint-disable-next-line
const conversationService = new FirestoreCollectionApi<any>('conversations')

const conversation_participantService = (conversationId) =>
  conversationService.getSubcollectionApi<ConvoParticipant>(
    conversationId,
    `participants`,
  )

export type ConvoMessage = {
  id?: string
  text: string
  createdById: string
  createdByName?: string
  updatedOn?: Date
  createdOn?: Date
}

export type ConvoParticipant = {
  profileId: string
  profileUid: string
  profileName: string
  permission?: string
  lastVisited?: Date
  lastMessageSent?: Date
  createdOn?: Date
  updatedOn?: Date
  profilePic?: string
  title?: string
}

export function getConvoByKey(
  connectionKey: string,
  convoType: ConnectionType,
) {
  return conversationService.getDocsByQuery([
    where('connectionKey', '==', connectionKey),
    where('connectionType', '==', convoType),
  ])
}

export async function createConversation(
  initConvo: {
    title?: string
    description?: string
    createdByUid?: string | undefined
    createdByName?: string | undefined
    fromOrgId?: string | undefined
    fromOrgType?: string | undefined
    fromOrgName?: string | undefined
    toOrgId?: string | undefined
    toOrgType?: string | undefined
    toOrgName?: string | undefined
    connectionType?: ConnectionType
    type?: ConversationType
    toUserId?: string | undefined
    fromUserId?: string | undefined
    fromUserName?: string | undefined
    toUserName?: string | undefined
  },
  message: ConvoMessage | null,
  participant: ConvoParticipant,
  connectionKey: string,
): Promise<DocumentData> {
  const data = {
    ...initConvo,
    createdOn: new Date(),
    connectionStatus: ConnectionStatus.PENDING,
    lastMessage: message,
    connectionKey,
    updatedAt: new Date(),
    version: '1.0.0',
  }

  const res = await conversationService.addDoc(data)

  if (res.id) {
    if (message) {
      await addMsgToConversation(res.id, message)
    }
    await addParticipantToConversation(res.id, participant)
    return res // Resolve with the result
  } else {
    throw new Error('Error creating conversation')
  }
}

export function updateConversationById(
  convoId: string,
  data: Partial<{
    connectionStatus: ConnectionStatus
    lastMessage: ConvoMessage
    updatedAt: Date
    version: string
    CancelledBy?: string
    isActive?: boolean
    acceptedByUid?: string
    acceptedByName?: string
    isArchived?: boolean
  }>,
): Promise<void> {
  return conversationService.updateDocByKey(convoId, data)
}

export function addMsgToConversation(
  convoId: string,
  message: ConvoMessage,
): Promise<[void, DocumentData]> {
  const newMessage = {
    ...message,
    createdOn: new Date(),
    updatedOn: new Date(),
  }
  const messageService = conversationService.getSubcollectionApi(
    convoId,
    'messages',
  )

  return Promise.all([
    conversationService.updateDocByKey(convoId, { lastMessage: newMessage }),
    messageService.addDoc(newMessage),
  ])
}

export function addParticipantToConversation(
  convoId: string,
  participant: ConvoParticipant,
): Promise<void> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    )
  return participantService.setDocByKey(participant.profileUid, {
    ...participant,
    createdOn: new Date(),
  })
}

export function updateParticipantToConversation(
  convoId: string,
  participant: ConvoParticipant,
): Promise<void> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    )
  return participantService.updateDocByKey(participant.profileUid, {
    ...participant,
    createdOn: new Date(),
  })
}

export function getConversationParticipants(
  convoId: string,
): Promise<ConvoParticipant[]> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    )
  return participantService.getAllDocs()
}

export function getConvoParticipantByUid(
  convoId: string,
  uid: string,
): Promise<ConvoParticipant> {
  return conversation_participantService(convoId).getDocByKey(uid)
}

export async function upsertConvoParticipant(
  convoId: string,
  participant: ConvoParticipant,
): Promise<boolean> {
  const existingParticipant = await getConvoParticipantByUid(
    convoId,
    participant.profileUid,
  )

  if (existingParticipant) {
    await updateParticipantToConversation(convoId, participant)
  } else {
    await addParticipantToConversation(convoId, participant)
  }

  try {
    // Update lastVisited against participant profile
    await profileService.setProfileConversations(
      participant.profileId,
      convoId,
      { lastVisited: new Date() },
    )
  } catch (error) {
    console.error('Error updating profile conversations', error)
  }

  return true
}

export function getOrgConversation(orgId: string): Promise<ConvoParticipant[]> {
  return conversationService.getDocsByQuery([
    where(orgId, '==', true),
    where('connectionType', '==', 'ORG_ORG'),
  ])
}

export function onConvoMessageUpdate(
  conversationId: string,
  callBack: (any) => void,
) {
  const messagesCollectionApi = conversationService.getSubcollectionApi(
    conversationId,
    'messages',
  )
  messagesCollectionApi.onCollectionUpdate(callBack)
}

export function onMyConversationsUpdate(
  profile: Profile,
  callBack: (any) => void,
) {
  const filteredCallBack = (data) => {
    const orgConvos = data.filter((c) => c.connectionType === 'ORG_ORG')

    let userConvos = []
    if (profile.uid) {
      userConvos = data.filter(
        (c) =>
          c.connectionType === 'USER_USER' &&
          c[profile.uid ?? '']?.toString() === 'true',
      )
    }

    data = [...orgConvos, ...userConvos]
    callBack(data)
  }

  conversationService.onQueryUpdate(
    profile.organisationId ? [where(profile.organisationId, '==', true)] : [],
    filteredCallBack,
  )
}

export function getConvoOrgs(
  orgs: { orgType: string; orgId: string }[],
): Promise<ExploreCardData[]> {
  const promises = orgs
    .filter((o) => o.orgId && o.orgType)
    .map((org) => getExploreDataByOrgId(org.orgId, org.orgType as OrgType))

  return Promise.all(promises)
}

export function getAllUsersOfOrgConnections(
  ConnectionsOrgIds: string[],
): Promise<Profile[][]> {
  const promises = ConnectionsOrgIds.map((orgId) =>
    profileService.getProfilesByOrgId(orgId),
  )

  return Promise.all(promises)
}

export const getOtherOrgIdByKeyProps = (
  keyProps: { [key: string]: { type: string } },

  profile: Profile,
) => {
  // this is to support old data format
  if (!profile) return ''

  try {
    const { organisationId } = profile

    for (const key in keyProps) {
      const value = keyProps[key]
      if (key !== organisationId && value.type === 'org') {
        return key
      }
    }
  } catch (error) {
    return 'unknown'
  }
}

export function useMyNetworkProfiles() {
  const { profile, orgConnections } = useProfileStore()

  const [networkProfiles, setnetworkProfiles] = useState<Profile[]>([])

  const [loading, setLoading] = useState<boolean>(false)

  const loadProfiles = async (orgIds: string[]) => {
    try {
      setLoading(true)
      const profiles = await getAllUsersOfOrgConnections(orgIds)

      setnetworkProfiles(profiles.flat())
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (profile?.organisationId && orgConnections?.length) {
      const orgIds = orgConnections
        .filter((o) => o.connectionStatus === ConnectionStatus.ACCEPTED)
        .map((org) => {
          let otherOrgId =
            org.toOrgId === profile.organisationId ? org.fromOrgId : org.toOrgId

          if (!otherOrgId && org?.keyProps) {
            otherOrgId = getOtherOrgIdByKeyProps(org.keyProps, profile)
          }
          return otherOrgId
        })
        .filter((o) => !!o)
      loadProfiles(orgIds)
    }
  }, [orgConnections, profile])

  return { loading, networkProfiles }
}

export function useConvoOrgs() {
  const { profile, orgConnections } = useProfileStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [convoOrgs, setConvoOrgs] = useState<ExploreCardData[]>([])

  useEffect(() => {
    if (profile?.organisationId && orgConnections?.length) {
      const convosOrgs: { orgType: string; orgId: string }[] =
        orgConnections.map((org) => {
          if (org.fromOrgId === profile.organisationId)
            return { orgType: org.toOrgType, orgId: org.toOrgId }
          else return { orgType: org.fromOrgType, orgId: org.fromOrgId }
        })

      getConvoOrgs(convosOrgs).then((orgs) => {
        setConvoOrgs(orgs)
        setLoading(true)
      })
    }
  }, [orgConnections, profile])

  return { loading, convoOrgs }
}

export function getConvoTitleByKeyProps(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyProps: any,
  connectionType: ConnectionType,
  profile: Profile,
) {
  // this is to support old data format
  if (!profile) return ''

  try {
    const { organisationId, uid } = profile

    if (connectionType === ConnectionType.USER_USER) {
      for (const key in keyProps) {
        const value = keyProps[key]
        if (key !== uid && value.type === 'user') {
          return value.name
        }
      }
    }

    if (connectionType === ConnectionType.ORG_ORG) {
      for (const key in keyProps) {
        const value = keyProps[key]
        if (key !== organisationId && value.type === 'org') {
          return value.name
        }
      }
    }
  } catch (error) {
    return 'unknown'
  }
}

export function getConvoTitle(convo, profile, convoOrgs, networkProfiles) {
  let convoTitle =
    profile?.organisationId === convo.toOrgId
      ? convo.fromOrgName
      : convo.toOrgName

  const isGroupChat = convo.connectionType === ConnectionType.ORG_ORG

  if (isGroupChat) {
    const convoOrgId =
      profile?.organisationId === convo.toOrgId
        ? convo.fromOrgId
        : convo.toOrgId

    const convoOrgInfo = convoOrgs.find((org) => org.id === convoOrgId)
    if (convoOrgInfo) {
      convoTitle = convoOrgInfo?.title
    }
  } else {
    const convoProfileId =
      profile?.uid === convo.fromUserId ? convo.toUserId : convo.fromUserId

    const convoProfile = networkProfiles.find(
      (profile) => profile.uid === convoProfileId,
    )

    if (convoProfile) {
      convoTitle =
        profile?.uid === convo.fromUserId
          ? convo.toUserName
          : convo.fromUserName
    }
  }

  if (!convoTitle && convo?.keyProps) {
    convoTitle = getConvoTitleByKeyProps(
      convo.keyProps,
      convo.connectionType,
      profile,
    )
  }
  return convoTitle
}
