import React from 'react'
import ColorCard from '../ColorCard'

interface ImageThumbnailProps {
  imageUrl?: string
  title: string
  circle?: boolean
  showFullName?: boolean
}

export const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
  imageUrl,
  title,
  circle,
  showFullName,
}) => {
  return imageUrl ? (
    <div className={`relative ${circle ? 'w-32 h-32' : 'w-full h-full'}`}>
      <img
        className={`object-cover w-full h-full ${circle ? 'rounded-full' : 'rounded-t-lg'}`}
        src={imageUrl}
        alt={title}
      />
    </div>
  ) : (
    <ColorCard name={title} circle={circle} showFullName={showFullName} />
  )
}
