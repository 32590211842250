import { useIsMobile } from '../../common/useIsMobile'

export interface TestimonialProps {
  url: string
  text: string
}

export const Testimonial: React.FC<TestimonialProps> = ({ url, text }) => {
  const isMobile = useIsMobile()
  const containerClasses = `flex items-center gap-4 bg-[#191B1E] text-white rounded-full overflow-hidden shadow-inner-glow-less-subtle ${
    isMobile ? 'p-1 max-w-[400px]' : 'p-4 max-w-full'
  }`

  const textClasses = `text-gray-300 text-sm italic leading-relaxed ${
    isMobile ? 'max-w-[250px] line-clamp-3 h-[45px]' : 'max-w-full'
  }`

  const imgClasses =
    'rounded-full object-cover border-4 border-gray-300 w-[28px] h-[28px] lg:w-[96px] lg:h-[96px]'

  return (
    <div className={containerClasses}>
      {/* Image Section */}
      <div className="flex-shrink-0">
        <img src={url} alt="Testimonial Avatar" className={imgClasses} />
      </div>

      {/* Text Section */}
      <div className={textClasses}>{text}</div>
    </div>
  )
}
