import { MailOutlined } from '@ant-design/icons'
import { Input, Button, Form } from 'antd'
import { useAuth } from '../../api/features/auth'
import { WVP_LOGO_NEW } from '../../v1/presentation/common/constants'
import toast from '../../v1/presentation/common/Toast'

export default function ForgetPwd() {
  const { sendPasswordResetEmail } = useAuth()
  const onFinish = async (values) => {
    const msg = await sendPasswordResetEmail(values.email)
    if (msg.text) {
      toast.info(msg.title, msg.text)
    }
  }
  return (
    <>
      <div key={1} className="login_page">
        <div className="header_text">
          <img className="logo_asset" src={WVP_LOGO_NEW} alt="Login" />

          <h2>Forgot Password</h2>
        </div>

        <Form name="signup-form" className="form_input" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Enter valid email address',
              },
            ]}
          >
            <Input
              size="large"
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Enter your email address"
              autoComplete="false"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn-sign">
              Send reset link
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}
