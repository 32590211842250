import * as React from 'react'
import { ExploreCardData } from '../../data/services/explore/ExploreCardData'
import {
  companySiteProperties,
  investorSiteProperties,
  realestateSiteProperties,
  SiteDataOption,
  SiteDataProperty,
} from '../../data/services/common/siteDataService'
import { Button, Space } from 'antd'
import useExploreStore from '../../data/appState/exploreStore'
import { CheckBoxIcon, CheckedBoxIcon } from '../common/utilComponents'

const propertyNameField = {
  diversity_designation: 'diversityDesignation',
  funding_round: 'fundingRound',
  industry_type: 'industries',
  minority_designation: 'minorityDesignation',
  arr_range: 'annualRecurringRevenue',
  business_model: 'businessModal',
  company_structure: 'structures',
  pre_funding_valuation: 'preFundingValuation',

  //RealestateOpportunity Properties
  assets_under_management: 'assetsUnderManagement',
  property_sector: 'sectors',
  investment_type: 'investmentType',
  investment_strategy: 'investmentStrategy',
  investment_structure: 'investmentStructure',
  return_structure: 'returnStructure',
  anticipated_term: 'anticipatedTerm',
  tax_advantage: 'taxAdvantage',

  //Investor Properties

  // assets_under_management:"assetsUnderManagement"
  investor_type: 'investorType',
}

export interface IExploreFiltersProps {
  eploreCards: ExploreCardData[]
  exploreType: string
}

type SiteDataPropertyWithCount = SiteDataProperty & {
  count: number
  options: SiteDataOptionWithCount[]
}
type SiteDataOptionWithCount = SiteDataOption & { count: number }

export default function ExploreFiltersView({
  eploreCards,
  exploreType,
}: IExploreFiltersProps) {
  const {
    selectedOptions,
    addSelectedOption,
    removeSelectedOption,
    clearSelectedOptions,
  } = useExploreStore()

  let exploreProperties: SiteDataProperty[] = []

  switch (exploreType) {
    case 'companies':
      exploreProperties = companySiteProperties
        ? Object.values(companySiteProperties)
        : []
      break
    case 'realestates':
      exploreProperties = realestateSiteProperties
        ? Object.values(realestateSiteProperties)
        : []
      break
    case 'investors':
      exploreProperties = investorSiteProperties
        ? Object.values(investorSiteProperties)
        : []
      break
    default:
      break
  }

  const explorePropertiesWithCount: SiteDataPropertyWithCount[] = []

  for (const property of exploreProperties) {
    let propertyCount = 0

    const propertyOptions: SiteDataOptionWithCount[] = []

    if (property?.options)
      for (const option of property.options) {
        const propertyValueCards = eploreCards.filter((card) => {
          const { orgData } = card
          const propertyNameFieldKey = propertyNameField[property.name]

          let hasValue = orgData[propertyNameFieldKey] === option.value

          if (
            !hasValue &&
            orgData[propertyNameFieldKey] &&
            orgData[propertyNameFieldKey].indexOf
          ) {
            hasValue =
              orgData[propertyNameFieldKey].indexOf(option.value) !== -1
          }

          return hasValue
        })
        propertyCount += propertyValueCards.length
        propertyOptions.push({ ...option, count: propertyValueCards.length })
      }
    explorePropertiesWithCount.push({
      ...property,
      options: propertyOptions,
      count: propertyCount,
    })
  }

  return (
    <div className="filter_meun">
      <div className="filter_lists">
        <div>
          {explorePropertiesWithCount
            .filter((p) => p.count > 0)
            .map((property: SiteDataPropertyWithCount, index: number) => (
              <div key={`filterdropdown-${index}`}>
                <FilterGroup
                  property={property}
                  selectedOptions={selectedOptions}
                  onSelect={(option) => {
                    addSelectedOption(option)
                  }}
                  onUnSelect={(option) => {
                    removeSelectedOption(option)
                  }}
                />
              </div>
            ))}
        </div>
      </div>

      <div className="filter_selected">
        <Space wrap>
          {selectedOptions.map((option, index: number) => {
            return (
              <Button
                key={`buttons-${index}`}
                onClick={(e) => {
                  e.preventDefault()
                  removeSelectedOption(option)
                }}
              >
                {option.label}

                <span className="close_btn">&#x2715;</span>
              </Button>
            )
          })}
          {selectedOptions.length > 0 ? (
            <Button
              onClick={(e) => {
                e.preventDefault()
                clearSelectedOptions()
                // selectedOptions.map((option) => {

                // });
              }}
            >
              Reset
            </Button>
          ) : (
            <></>
          )}
        </Space>
      </div>
    </div>
  )
}

export interface IFilterGroupProps {
  property: SiteDataPropertyWithCount
  selectedOptions: SiteDataOption[]
  onSelect: (option: SiteDataOption, property: SiteDataProperty) => void
  onUnSelect: (option: SiteDataOption, property: SiteDataProperty) => void
}

export function FilterGroup({
  property,
  selectedOptions,
  onSelect,
  onUnSelect,
}: IFilterGroupProps) {
  const propertyOptions = property.options as SiteDataOptionWithCount[]
  const filterView = (filterItem) => {
    const indexOfSelectedOption = selectedOptions.findIndex(
      (option) => option.value === filterItem.value,
    )

    return (
      <div
        className="label_box"
        key={filterItem.value}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          if (indexOfSelectedOption === -1) {
            if (onSelect) onSelect(filterItem, property)
          } else {
            if (onUnSelect) onUnSelect(filterItem, property)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            e.stopPropagation()

            if (indexOfSelectedOption === -1) {
              if (onSelect) onSelect(filterItem, property)
            } else {
              if (onUnSelect) onUnSelect(filterItem, property)
            }
          }
        }}
      >
        <Space>
          {indexOfSelectedOption !== -1 ? <CheckedBoxIcon /> : <CheckBoxIcon />}
          <div className="label_text">
            {' '}
            {filterItem.label + ` (${filterItem.count})`}
          </div>
        </Space>
      </div>
    )
  }

  return (
    <div className="drop-down-btn" key={property.name}>
      {/* <span>{property.name}</span> */}

      <div className="filter_lable"> {property.label}</div>

      {propertyOptions
        .filter((p) => p.count > 0)
        .map((filter) => filterView(filter))}
    </div>
  )
}
