import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { RealEstateSponsor } from '../../../domain/types/RealEstate'

const reSponserCollectionService =
  new FirestoreCollectionApi<RealEstateSponsor>('reSponser')

export function createreRealEstateSponsor(data: RealEstateSponsor) {
  return reSponserCollectionService.addDoc(data)
}

export function getRealEstateSponsorById(id: string) {
  return reSponserCollectionService.getDocByKey(id)
}

export function updateRealEstateSponsor(id: string, data: RealEstateSponsor) {
  return reSponserCollectionService.updateDocByKey(id, data)
}
