import React from 'react'
import { uploadPdfToApi } from '../../data/services/company/companyService'
import { UploadCloudIcon } from '../common/utilComponents'
import useSiteStore from '../../data/appState/siteStore'
import './FileUpload/__fileupload.scss'

interface Window {
  FIREBASE_CONFIG?: {
    projectId?: string
  }
}
declare const window: Window
export interface IPdfToImagesUploaderProps {
  onUploadComplete: ({ pdfFile, images, size, extractedData }) => void
  orgId?: string
}

export function PdfToImagesUploader(props: IPdfToImagesUploaderProps) {
  const companyId = props.orgId ?? 'no-org' // replace with the actual company ID

  const { setfullSiteLoader } = useSiteStore()
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const sFile = event.target.files[0]
      if (sFile) {
        setfullSiteLoader(true)
        setTimeout(() => {
          handleUpload(sFile)
        }, 200)
      }
    }
  }

  const handleUpload = async (selectedFile: File) => {
    try {
      let eniviroment: 'prod' | 'qa' = 'prod'

      const projectId = window?.FIREBASE_CONFIG?.projectId
      if (
        projectId === 'wealthvp-webapp-qa' ||
        projectId === 'wealthvp-webapp-dev'
      ) {
        eniviroment = 'qa'
      }
      const result = await uploadPdfToApi(selectedFile, companyId, eniviroment)
      if (result) {
        props.onUploadComplete({ ...result, size: selectedFile.size })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setfullSiteLoader(false)
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="pitch-deck-upload-container flex flex-col items-center">
        <div className="upload-box">
          <div className="upload-icon">
            <UploadCloudIcon />
          </div>
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            className="mt-2"
          />
          <div className="flex flex-col items-center text-center">
            <p>
              <span className="text-yellow-green font-normal text-sm">
                Click to upload
              </span>{' '}
              or drag and drop
            </p>
            <p className="file-size-info text-gray font-normal text-sm">
              PDF format (max. 10MB)
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
