import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useProfileStore from '../../data/appState/profileStore'
import { ShimmerSocialPost } from 'react-shimmer-effects'
import { Avatar, Button, Divider, Form, Input, InputNumber, Select } from 'antd'
import {
  Company,
  CompanyFile,
  CompanyFileCategories,
  TeamMember,
} from '../../domain/types/Company'
import { useEffect, useState, useRef } from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon'

import FileUploader from '../common/FileUploader'
import { formatBytes } from '../../data/services/explore/converters'
import { companySiteProperties } from '../../data/services/common/siteDataService'
import {
  getCompanyById,
  updateCompany,
} from '../../data/services/company/companyService'
import { SocialLinksEditor } from '../components/social-links-form-item'
import toast from '../common/Toast'
import {
  BackArrow,
  DeleteIcons,
  ImagePlus,
  SaveIcon,
} from '../common/utilComponents'
import { TeamMemberForm } from '../components/TeamMemberForm'
import { TeamMembersList } from '../components/TeamMembersList'
import { PdfToImagesUploader } from '../components/PdfToImagesUploader'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default function CompanyEdit() {
  const {
    funding_round,
    company_structure,
    business_model,
    industry_type,
    diversity_and_inclusion,
    economic_impact,
    social_impact,
    environmental_impact,
    use_of_funds,
  } = companySiteProperties ?? {}

  const { id: companyId } = useParams()
  const { profile } = useProfileStore()
  const navigateTo = useNavigate()
  const MAX_COUNT = 3
  const quillRef = useRef<ReactQuill | null>(null)

  const [company, setCompany] = React.useState<Company | null>(null)
  const [pictureUrl, setPictureUrl] = useState(company?.logoUri ?? '')
  const [socialLinks, setSocialLinks] = useState<string[]>(
    company?.socialLinks ?? [],
  )
  const [industries, setIndustries] = useState<string[]>(
    company?.industries ?? [],
  )
  const [companyFiles, setCompanyFiles] = useState<CompanyFile[]>(
    company?.companyFiles ?? [],
  )
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>(
    company?.teamMembers ?? [],
  )
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const [pitchDeckImages, setPitchDeckImages] = useState<string[]>([])
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    if (companyId) {
      if (profile?.organisationId !== companyId) {
        navigateTo('/*')
        return
      }
      getCompanyById(companyId)
        .then((data: Company) => {
          // eslint-disable-next-line
          const dateVal: any = data.closingDate
          if (dateVal?.toDate) {
            data.closingDate = dateVal.toDate()
          }
          if (data.websiteUri === 'https://null') {
            data.websiteUri = ''
          }
          setCompany(data)
          if (data.teamMembers?.length) {
            setTeamMembers(data.teamMembers)
          }
          if (data.socialLinks?.length) {
            setSocialLinks(data.socialLinks)
          }
          if (data.companyFiles?.length) {
            setCompanyFiles(data.companyFiles)
          }
          if (data.pitchDeckImageFiles?.length) {
            setPitchDeckImages(data.pitchDeckImageFiles)
          }

          if (data.logoUri?.length) {
            setPictureUrl(data.logoUri)
          }
          setTimeout(() => {
            handleTextChange()
          }, 1000)
        })
        .catch((err) => console.error(err))
    }
  }, [companyId, navigateTo, profile?.organisationId])

  const handleTextChange = () => {
    const editor = quillRef.current?.getEditor()
    if (editor) {
      const contentLength = editor?.getLength() - 1 // by default quilleditor has 1 char, so we are removing in count
      setCharCount(contentLength)
    }
  }

  const [form] = Form.useForm<Company>()

  if (!profile || !company) return <ShimmerSocialPost type="both" />

  const onFinish = async (values: Company) => {
    if (companyFiles) {
      const pDeck = companyFiles.find((f) => f.category === 'pitchDeck')
      if (!pDeck) {
        toast.error('Please upload pitch deck file', '')
        return
      }
    }

    if (
      values['businessModal'] &&
      typeof values['businessModal'] === 'string'
    ) {
      values['businessModal'] = [values['businessModal']]
    }

    if (values && values['industries'] && values['industries']?.length > 3) {
      toast.error('You can only select up to 3 Industries', '')
      return
    }
    if (!pictureUrl) {
      toast.error('Please upload profile picture', '')
      return
    }

    if (charCount < 1) {
      toast.error('Value Proposition', 'Please enter Value Proposition')
      setIsUpdating(false)
      return
    }

    if (charCount > 1000) {
      toast.error('Value Proposition', 'should be less than 1000 characters')
      setIsUpdating(false)
      return
    }

    values['logoUri'] = pictureUrl ? pictureUrl : undefined
    values.teamMembers = teamMembers
    values.companyFiles = companyFiles
    values['socialLinks'] = socialLinks.length !== 0 ? [...socialLinks] : []
    const updatedCompany = { ...company, ...values }

    if (pitchDeckImages.length) {
      updatedCompany.pitchDeckImageFiles = pitchDeckImages
    }

    try {
      await updateCompany(updatedCompany)
      navigateTo(`/company`)
      toast.success(`Your profile is updated`, '')
    } catch (error) {
      console.warn(error)
      toast.warning('Failed to save', '')
    } finally {
      setTimeout(() => {
        setIsUpdating(false)
      }, 600)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo)
    toast.error('Failed to save', 'Please fill the required fields')
  }

  const getFileCard = (file: CompanyFile) => {
    const url = file.url ? decodeURIComponent(file.url) : '' // TODO: should be required in the SDK
    const filePath = url.split('?')[0],
      fileExt = filePath.split('.').pop(),
      fileName = filePath.split('/').pop()?.split('--').pop()

    const iconStyels = defaultStyles[fileExt] ? defaultStyles[fileExt] : {}
    return (
      <div className="doc_files">
        <div className="" role="button" tabIndex={0}>
          <div className="file_icon">
            <FileIcon extension={fileExt} {...iconStyels} />
          </div>
        </div>
        <div className="load_preview" role="button" tabIndex={0}>
          <div className="file_name">{fileName}</div>
          <div>{formatBytes(file.size)}</div>
        </div>
        <div className="delete_icon">
          <DeleteIcons
            style={{ color: 'red' }}
            onClick={(e) => {
              e.preventDefault()
              const newFiles = companyFiles?.filter((cf) => cf.url !== file.url)
              setCompanyFiles?.([...(newFiles ?? [])])
            }}
          />
        </div>
      </div>
    )
  }

  const addFileToCompany = async (
    fileCategory: string,
    file: { url: string; size?: number; images?: string[] },
  ): Promise<void> => {
    let oFiles = companyFiles && companyFiles?.length ? [...companyFiles] : []

    if (fileCategory === CompanyFileCategories.intro)
      oFiles = oFiles?.filter((f) => f.category !== CompanyFileCategories.intro)

    if (fileCategory === CompanyFileCategories.pitchDeck) {
      setPitchDeckImages?.(file.images ?? [])
    }

    setCompanyFiles?.([...oFiles, { category: fileCategory, ...file }])
  }
  const pitchdeckView = () => {
    const pdFiles = companyFiles?.filter(
      (f) => f.category === CompanyFileCategories.pitchDeck,
    )

    return (
      <>
        <div className="content-center videoWrapper">
          {!pdFiles?.length ? (
            <div className={`${!pdFiles?.length ? 'no-content' : 'content'}`}>
              <PdfToImagesUploader
                orgId={profile.organisationId}
                onUploadComplete={({ pdfFile, images, size }) => {
                  addFileToCompany(CompanyFileCategories.pitchDeck, {
                    url: pdfFile,
                    images,
                    size,
                  })
                }}
              ></PdfToImagesUploader>
            </div>
          ) : null}

          <div className="edit-docs-view">
            {pdFiles?.map((file) => {
              return (
                <div className="w-100" key={'file'}>
                  {getFileCard(file)}
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  // intro view
  const getFileInfoByURL = (url: string) => {
    url = decodeURIComponent(url)
    const filePath = url.split('?')[0]
    return {
      filePath,
      fileExt: filePath.split('.').pop(),
      fileName: filePath.split('/').pop()?.split('--').pop(),
    }
  }

  const removeIntroVideo = (): void => {
    setCompanyFiles?.([
      ...(companyFiles ?? []).filter(
        (f) => f.category !== CompanyFileCategories.intro,
      ),
    ])
  }

  const IntroView = () => {
    let introFile, fileInfo
    if (companyFiles && companyFiles.length !== 0) {
      introFile = companyFiles.find(
        (f) => f.category === CompanyFileCategories.intro,
      )
      fileInfo = introFile && getFileInfoByURL(introFile.url)
    }

    if (!introFile)
      return (
        <div className="flex_warp">
          <div className="label">
            <div className="text-wrapper-2" style={{ color: '#ffffff' }}>
              Company Video
            </div>
          </div>

          {/* <div className={`${!introFile ? 'no-content' : 'content'}`}>
            <div className="upload-container">
              <label className="upload-box">
                <div className="upload-icon">
                  {' '}
                  <UploadCloudIcon />
                </div>

                <FileUploader
                  acceptFileTypes="video/mp4,video/x-m4v,video/*"
                  label="Add Intro Video"
                  onUploadComplete={(file) => {
                    if (file)
                      addFileToCompany(CompanyFileCategories.intro, file)
                  }}
                ></FileUploader>
                <div>
                  <p className="file-size-info">MP4 format (min. 1280x400px)</p>
                </div>
              </label>
            </div>
          </div>

          <span className="or_center">Or</span> */}

          <Input
            className="video_link"
            size="large"
            onBlur={(e) => {
              // setLink(e.target.value);
              addFileToCompany(CompanyFileCategories.intro, {
                url: e.target.value,
                size: 5584,
              })
            }}
            placeholder="Paste video URL"
          />
          <p className="text-[12px] font-normal text-gray">
            YouTube or Vimeo only (min. 1280x400px)
          </p>
        </div>
      )

    return (
      <div className="content-center videoWrapper">
        <div className="label">
          <div className="text-wrapper-2" style={{ color: '#ffffff' }}>
            Company Video
          </div>
        </div>
        <div className="video_file">
          <h5 className="video-title-txt" title={fileInfo?.fileName}>
            {fileInfo?.fileName}
          </h5>
          <div className="delete_icon">
            <DeleteIcons onClick={() => removeIntroVideo()} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="body_layout">
      <Form
        initialValues={company}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="sub_header">
          <div
            className="back_icon"
            role="button"
            tabIndex={0}
            onClick={() => navigateTo(-1)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigateTo(-1)
              }
            }}
          >
            <BackArrow />
            <h2>Back</h2>
          </div>

          <ul className="sub_header_actions">
            <li>
              <Button type="text" htmlType="submit" loading={isUpdating}>
                <SaveIcon /> <h2>Save</h2>
              </Button>
            </li>
          </ul>
        </div>

        <div className="wvp-p-flex-d">
          <>
            <h3 className="soft_green_title">Company Profile</h3>
            <div className="firm_profile_form">
              <div className="upload_wrapper">
                <div className="label">
                  <div className="text-wrapper" style={{ color: '#F04438' }}>
                    *
                  </div>
                  <div className="text-wrapper-2" style={{ color: '#ffffff' }}>
                    Firm Photo
                  </div>
                </div>
                <div className="images_upload">
                  {pictureUrl ? (
                    <Avatar
                      shape="square"
                      icon={<img alt="" src={pictureUrl} />}
                    />
                  ) : (
                    <>
                      <Avatar shape="square" icon={<ImagePlus />} />
                    </>
                  )}

                  <div className="upload-container">
                    <p className="upload-box">
                      <FileUploader
                        onUploadComplete={(e) => {
                          setPictureUrl(e?.url ?? '')
                        }}
                      />
                    </p>
                  </div>
                  <p className="text-wrapper-3">
                    JPG, PNG format (min. 600x600px)
                  </p>
                </div>
              </div>
              <div className="grid-3">
                <Form.Item
                  name="name"
                  label="Company Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter company name',
                    },
                  ]}
                >
                  <Input size="large" placeholder="company name" />
                </Form.Item>
                <Form.Item
                  name="fundingRound"
                  label="Funding Round"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Round',
                    },
                  ]}
                >
                  <Select options={funding_round?.options ?? []} />
                </Form.Item>
                <Form.Item
                  name="structures"
                  label="Offering Type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Structure',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    options={company_structure?.options ?? []}
                  />
                </Form.Item>

                <Form.Item
                  name="minimumInvestment"
                  label="Minimum Investment"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Minimum Investment',
                    },
                  ]}
                >
                  <InputNumber
                    prefix="$"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''}
                    placeholder="Minimum Investment"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="maximumInvestment"
                  label="Maximum Investment"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Maximum Investment',
                    },
                  ]}
                >
                  <InputNumber
                    prefix="$"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''}
                    placeholder="Maximum Investment"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="social_editor_view">
              <SocialLinksEditor
                value={socialLinks}
                onChange={(v) => {
                  setSocialLinks(v)
                }}
              />
            </div>
          </>

          <h3 className="Secondary-textcolor">Company Details</h3>
          <p className="small_textcolor">
            Upload a comprehensive pitch deck and fill your your company details
            to help investors discover and connect with you.
          </p>
          <div>
            <div className="label">
              <div className="text-wrapper" style={{ color: '#F04438' }}>
                *
              </div>
              <div className="text-wrapper-2" style={{ color: '#ffffff' }}>
                Pitch Deck
              </div>
            </div>
            {pitchdeckView()}
          </div>

          <div className="mt-4 grid-3">
            <Form.Item
              name="founded"
              label="Company Founded"
              rules={[
                {
                  required: true,
                  message: 'Please enter Company Founded',
                },
              ]}
            >
              <InputNumber type="number" placeholder="Company Founded" />
            </Form.Item>

            <Form.Item
              name="businessModal"
              label="Business Model"
              rules={[
                {
                  required: true,
                  message: 'Please select Business Model',
                },
              ]}
            >
              <Select options={business_model?.options ?? []} />
            </Form.Item>

            <Form.Item
              name="employeeCount"
              label="Employee Count"
              rules={[
                {
                  required: true,
                  message: 'Please enter Number of employees',
                },
              ]}
            >
              <InputNumber type="number" placeholder="Number of employees" />
            </Form.Item>

            <Form.Item
              name="country"
              label="Company Location"
              rules={[
                {
                  required: true,
                  message: 'Please enter Company Location',
                },
              ]}
            >
              <Input placeholder="Enter Company Location" />
            </Form.Item>

            <Form.Item
              name="websiteUri"
              label="Company Website"
              rules={[
                {
                  required: true,

                  message: 'Please enter valid website',
                },
                { type: 'url' },
                { type: 'string', min: 6 },
              ]}
            >
              <Input placeholder="Enter website" />
            </Form.Item>

            <Form.Item
              name="industries"
              label="Industry Focus"
              className="col-span-2"
              rules={[
                {
                  required: true,
                  message: 'Please select Industries',
                },
              ]}
            >
              <Select
                mode="multiple"
                maxCount={MAX_COUNT}
                placeholder="Please select"
                defaultValue={industries?.length ? [...industries] : []}
                onChange={(e) => setIndustries(e)}
                options={industry_type?.options ?? []}
              />
            </Form.Item>
          </div>

          <h3 className="Secondary-textcolor">Financial Overview</h3>
          <p className="small_textcolor">
            Let investors know the core financials of the company. Be as concise
            and accurate as possible.
          </p>

          <div className="grid-3">
            <Form.Item
              name="valuation"
              label="Valuation"
              rules={[
                {
                  required: true,
                  message: 'Please enter Valuation',
                },
              ]}
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') ?? ''}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="fundingRaisedToDate"
              label="Raised to Date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Raised to-date',
                },
              ]}
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="annualRevenue"
              label="Annual Revenue"
              rules={[
                {
                  required: true,
                  message: 'Please enter Annual Revenue',
                },
              ]}
            >
              <InputNumber
                prefix="$"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') ?? ''}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="useOfFunds"
              label="Use of Funds"
              rules={[
                {
                  required: true,
                  message: 'Please enter Use of Funds',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Please Select"
                options={use_of_funds?.options ?? []}
              />
            </Form.Item>
          </div>

          <h3 className="Secondary-textcolor">Impact Areas of Interest</h3>
          <p className="small_textcolor">
            Enter your top three choices for each category so impact companies
            accurately match with you.
          </p>
          <div className="grid-2">
            <Form.Item name="socialImpact" label="Social Impact">
              <Select
                maxCount={MAX_COUNT}
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Please Select"
                options={social_impact?.options ?? []}
              />
            </Form.Item>

            <Form.Item name="environmentImpact" label="Environment Impact">
              <Select
                maxCount={MAX_COUNT}
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Please Select"
                options={environmental_impact?.options ?? []}
              />
            </Form.Item>
            <Form.Item name="economicImpact" label="Economic Impact">
              <Select
                maxCount={MAX_COUNT}
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Please Select"
                options={economic_impact?.options ?? []}
              />
            </Form.Item>

            <Form.Item
              name="diversityandInclusion"
              label="Diversity and Inclusion"
            >
              <Select
                maxCount={MAX_COUNT}
                style={{ width: '100%' }}
                mode="multiple"
                placeholder="Please Select"
                options={diversity_and_inclusion?.options ?? []}
              />
            </Form.Item>
          </div>
          <Divider></Divider>
          <h3 className=" soft_green_title" style={{ paddingBottom: 0 }}>
            Product Overview
          </h3>
          <p className="small_textcolor">
            Highlight the value of your product and include a video to inspire
            and engage potential investors. The Video will show up on the
            Investor’s explore page.
          </p>
          <div className="flex flex-wrap gap-4">
            {' '}
            <div>{IntroView()}</div>
            <div className="relative flex-1 w-full textarea_height">
              <Form.Item
                name="valueProposition"
                label="Value Proposition"
                rules={[
                  { required: true, message: 'Please enter Value Proposition' },
                ]}
                validateStatus={charCount < 1 ? 'error' : ''}
                help={charCount < 1 ? 'Please enter Value Proposition' : ''}
              >
                <ReactQuill
                  theme="snow"
                  ref={quillRef}
                  onChange={handleTextChange}
                />
              </Form.Item>

              <div className="err-message">
                {charCount > 1000 ? (
                  <p>Characters should not be more than 1000</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="counter">
                <span id="charCount">{charCount} </span> / 1000
              </div>
            </div>
          </div>
          <Divider></Divider>
          <h3 className="soft_green_title" style={{ paddingBottom: 0 }}>
            Team Overview
          </h3>
          <p className="small_textcolor">
            Highlight the value of your product and include a video to inspire
            and engage potential investors. The Video will show up on the
            Investor’s explore page.
          </p>

          <TeamMemberForm
            onAddMember={(teamMember) => {
              setTeamMembers([...teamMembers, teamMember])
            }}
          ></TeamMemberForm>

          <div className="mt-6">
            <TeamMembersList
              teamMembers={teamMembers}
              onDeleteMember={(index) => {
                teamMembers.splice(index, 1)
                setTeamMembers?.([...teamMembers])
              }}
            ></TeamMembersList>
          </div>

          <div className="actions_btn " style={{ justifyContent: 'flex-end' }}>
            <Form.Item>
              <Button
                type="default"
                className="mr-4"
                onClick={() => navigateTo(-1)}
              >
                Back
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-primary"
                loading={isUpdating}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  )
}
