export const formatRevenueRange = (range: string): string => {
  const [start, end] = range.split('_')
  return `$${start}-${end}`
}

export const formatAllCapsSnakeToReadableStructure = (
  structure: string,
): string => {
  return structure
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
