import { Avatar, Button, Form, Input } from 'antd'
import * as React from 'react'
import { TeamMember } from '../../domain/types/Company'
import FileUploader from '../common/FileUploader'
import { useState } from 'react'
import toast from '../common/Toast'
import { ImagePlus } from '../common/utilComponents'

export interface ITeamMemberFormProps {
  onAddMember: (TeamMember) => void
}
const { TextArea } = Input

export function TeamMemberForm(props: ITeamMemberFormProps) {
  const [teamMember, setTeamMember] = useState<TeamMember>({})
  const [reseting, setReseting] = useState<boolean>(false)

  const updateField = (key: string, value: string) =>
    setTeamMember({ ...teamMember, [key]: value })

  const handleAddClick = () => {
    if (!teamMember.displayName) {
      toast.warning('Please enter Name')
      return
    }
    if (!teamMember.title) {
      toast.warning('Please enter Title')
      return
    }
    if (!teamMember.photoUrl) {
      toast.warning('Please Upload a Photo')
      return
    }
    if (!teamMember.socialLink) {
      toast.warning('Please enter your social link')
      return
    }
    const tm = { ...teamMember }

    props.onAddMember({ ...tm })
    setTeamMember({})
    reset()
  }

  const reset = () => {
    setReseting(true)
    setTimeout(() => {
      setReseting(false)
    }, 500)
  }

  if (reseting) return <div className="empty-form"></div>

  return (
    <div>
      <Form requiredMark={true} layout="vertical">
        <div className="flex flex-wrap gap-4">
          <div className="team_upload">
            <div className="upload_wrapper">
              <div className="label">
                <div className="text-wrapper" style={{ color: '#F04438' }}>
                  *
                </div>
                <div className="text-wrapper-2" style={{ color: '#ffffff' }}>
                  Profile Photo
                </div>
              </div>
              <div className="img_wrapper">
                {teamMember?.photoUrl ? (
                  <>
                    <Avatar
                      shape="circle"
                      alt="Image"
                      icon={<img alt="" src={teamMember.photoUrl} />}
                    />
                  </>
                ) : (
                  <>
                    <Avatar shape="circle" alt="Image" icon={<ImagePlus />} />
                  </>
                )}

                <div className="upload-container">
                  <p className="upload-box">
                    <FileUploader
                      acceptFileTypes="image/*"
                      onUploadComplete={(file) => {
                        if (file?.url) {
                          updateField('photoUrl', file?.url)
                        }
                      }}
                    />
                  </p>
                </div>
                <p className="text-wrapper-3">
                  JPG, PNG format (min. 150x150px)
                </p>
              </div>
            </div>
          </div>

          <div className="flex-1 w-full">
            <div className="grid-3">
              <Form.Item
                label="Full Name"
                name="displayName"
                rules={[{ required: true, message: 'Please enter Name' }]}
              >
                <Input
                  placeholder="Name"
                  id="tb-dsname"
                  onChange={(e) => updateField('displayName', e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Title',
                  },
                ]}
              >
                <Input
                  placeholder="Chief Executive Officer (CEO)"
                  onChange={(e) => updateField('title', e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Social Link"
                name="socialLink"
                rules={[
                  { required: true, message: 'Please enter Social link' },
                ]}
              >
                <Input
                  placeholder="Social Link"
                  id="tb-dsname"
                  onChange={(e) => updateField('socialLink', e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="description_editor">
              <Form.Item name="description" label="Description">
                <TextArea
                  showCount
                  maxLength={150}
                  onChange={(e) => updateField('description', e.target.value)}
                  style={{ resize: 'none', borderRadius: '0.25rem' }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        {/* <div className="mt-4 w-72">
        
        </div> */}

        <Form.Item>
          <Button
            type="default"
            className="mt-4 text-[16px] font-medium p-0 bg-transparent"
            style={{ width: 'fit-content' }}
            onClick={handleAddClick}
          >
            Add Member
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
