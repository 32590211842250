import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Invite } from '../../domain/types/Invite'
import {
  getCurrentUser,
  signOutUser,
} from '../../data/services/auth/authService'
import { useEffect, useState } from 'react'
import {
  getInviteById,
  inviteSignup,
} from '../../data/services/Invites/InviteService'
import { LoginFooter, LoginHeader } from './Login'
import { Button, Divider, Form, Input, Select } from 'antd'
import {
  investorSiteProperties,
  useLoadSiteData,
} from '../../data/services/common/siteDataService'
import { InvestmentFirm } from '../../domain/types/Investor'
import { Profile } from '../../domain/types/Profile'
import { ProcessState } from '../common/types'
import toast from '../common/Toast'
import { Company } from '../../domain/types/Company'
import { RealEstateSponsor } from '../../domain/types/RealEstate'

export function InviteOnboard() {
  const { loaded } = useLoadSiteData()
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.Idle,
  )

  const { inviteid } = useParams()
  const [form] = Form.useForm()
  const { investor_type } = investorSiteProperties ?? {}
  const [isUserLoggedVerified, setIsUserLoggedVerified] =
    React.useState<boolean>(false)

  const [invite, setInvite] = React.useState<Invite | null>(null)

  useEffect(() => {
    const User = getCurrentUser()
    if (User?.uid) {
      signOutUser().then(() => {
        setIsUserLoggedVerified(true)
      })
    } else {
      setIsUserLoggedVerified(true)
    }
  }, [])

  useEffect(() => {
    if (isUserLoggedVerified && inviteid) {
      getInviteById(inviteid).then((invite: Invite) => {
        if (invite) {
          setInvite(invite)
        }
      })
    }
  }, [isUserLoggedVerified, inviteid])

  if (!loaded) {
    return (
      <div className="loader_center">
        <h1>Loading...</h1>
      </div>
    )
  }

  if (!isUserLoggedVerified) {
    return (
      <div className="loader_center">
        <h1>Logging out...</h1>
      </div>
    )
  }

  if (!invite) {
    return (
      <div className="loader_center">
        <h1>Invite not found</h1>
      </div>
    )
  }

  const handleOrgSignUp = async (values) => {
    setProcessState(ProcessState.Loading)
    const profile = {} as Profile

    profile.firstName = values.firstName
    profile.lastName = values.lastName
    profile.email = values.email
    profile.organisationName = values.firmName
    profile.displayName = values.firstName + ' ' + values.lastName
    profile.orgType = 'INVESTOR'
    profile.updatedOn = new Date()
    profile.isActive = true
    profile.userStatus = 'activated' // payment
    profile.isWalkThroughPending = true

    let orgData: Company | InvestmentFirm | RealEstateSponsor = {} as Company

    orgData.name = values.firmName ?? ''
    orgData.websiteUri = values.firmWebSite ?? ''
    orgData.updatedOn = new Date()
    orgData.isActive = true
    orgData.isHidden = false
    orgData.teamMembers = []

    if (invite.createToOrgType === 'INVESTOR') {
      orgData = { ...orgData } as InvestmentFirm
      orgData.linkedinUri = values.linkedinUrl ?? ''
      orgData.investorType = values.investorType
      orgData.createdOn = new Date()
    }
    invite.db_ref_id = inviteid ?? ''
    try {
      await inviteSignup(
        values.email,
        values.password,
        orgData,
        invite.createToOrgType,
        profile,
        invite,
      )
      toast.success('Sign Up', 'You have been signed up successfully')
      window.location.href = '/login'
    } catch (error) {
      console.error('Error', error)
      toast.error('Error', 'An error occured while signing up')
    }
  }

  const { firstName, title, lastName } = invite.userInfo || {}
  let { orgName, website } = invite.accountInfo || {}

  // temp fix for orgName and website casing issue. No Need of this in Prod
  if (!orgName || !website) {
    const accountInfo = invite.accountInfo as never as {
      OrgName: string
      Website: string
    }
    orgName = accountInfo?.OrgName
    website = accountInfo?.Website
  }

  const isAdminInvite: boolean = invite?.createdByOrgType === 'ADMIN'

  return (
    <div
      className={
        'login_page invitee_signup ' + (isAdminInvite ? ' admin_invite' : '')
      }
    >
      <LoginHeader title={'Sign Up'} />

      <div className="invitee_text">
        <h5>
          You have been invited by{' '}
          <b className="highlight_color">{invite.createdByName}</b>
          {!invite.createdByOrgName ? (
            <></>
          ) : (
            <span>
              from
              <b className="highlight_color"> {invite.createdByOrgName}</b>
            </span>
          )}
        </h5>
      </div>
      <div style={{ width: '0px', overflow: 'hidden', height: '0px' }}>
        {JSON.stringify({ firstName, title, lastName, orgName, website })}
      </div>
      <div className="invitee_form">
        <Form
          layout={'vertical'}
          initialValues={{
            email: invite.createToEmail,
            firstName: firstName,
            lastName: lastName,
            firmName: orgName,
            firmWebSite: website,
            investorType: 'SINGLE_FAMILY_OFFICE',
            linkedinUrl: isAdminInvite
              ? 'https://www.linkedin.com/company/wealthvp'
              : '',
          }}
          form={form}
          onFinish={handleOrgSignUp}
        >
          <div className="flex_box">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please Provide First Name',
                },
              ]}
            >
              <Input
                placeholder="First Name"
                value={firstName}
                defaultValue={firstName}
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Last Name',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
          <div className="flex_box">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Email',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Password',
                },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>
          </div>

          <Divider
            orientation="left"
            plain
            style={{ color: 'white', borderColor: '#ccc' }}
          ></Divider>
          <div>
            <h3 className="soft_green">Firm Information</h3>
          </div>

          <div className="flex_box">
            <Form.Item
              name="firmName"
              label="Firm Name"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Firm Name',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              name="investorType"
              label="Investor Type"
              rules={[
                {
                  required: true,
                  message: 'Please select investor type',
                },
              ]}
            >
              <Select
                style={{ width: '100%' }}
                options={investor_type?.options ?? []}
              />
            </Form.Item>
          </div>

          <div className="flex_box">
            <Form.Item
              name="firmWebSite"
              label="Firm WebSite"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Firm WebSite',
                },
              ]}
            >
              <Input placeholder="Firm WebSite" />
            </Form.Item>

            <Form.Item
              name="linkedinUrl"
              label="Linkedin Url"
              rules={[
                {
                  required: true,
                  message: 'Please Provide Linkedin Url',
                },
              ]}
            >
              <Input placeholder="Linkedin Url" />
            </Form.Item>
          </div>

          <div className="flex_button">
            <Button
              type="primary"
              htmlType="submit"
              loading={processState === ProcessState.Loading}
            >
              {' '}
              Sign Up
            </Button>
          </div>
        </Form>
      </div>

      <div></div>
      <LoginFooter />
    </div>
  )
}
