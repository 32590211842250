import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { InvestmentFirm } from '../../../domain/types/Investor'
import { cleanObject } from '../../../presentation/common/utils'
import { DocumentData } from 'firebase/firestore'

const invFirmCollectionService = new FirestoreCollectionApi<InvestmentFirm>(
  'investmentFirms',
)

export function getAllInvestmentFirms(): Promise<InvestmentFirm[]> {
  return invFirmCollectionService.getAllDocs()
}

export function getInvestmentFirmsCount(): Promise<number> {
  return invFirmCollectionService.getDocsCount()
}

export function getInvestmentFirmById(id: string): Promise<InvestmentFirm> {
  return invFirmCollectionService.getDocByKey(id)
}

export function updateInvestorFirm(data: InvestmentFirm): Promise<void> {
  const cleanData = { ...cleanObject(data) }

  return invFirmCollectionService.updateDocByKey(data.id, cleanData)
}

export function createInvestmentFirm(
  data: InvestmentFirm,
): Promise<DocumentData> {
  const cleanData = { ...cleanObject(data) }
  return invFirmCollectionService.addDoc(cleanData)
}
