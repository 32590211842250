import React from 'react'
import { Progress } from 'antd'
import './__progress.scss'
interface ProgressBarProps {
  percent: number
  title: string
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ percent, title }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full mb-5">
      <div className="relative w-full flex items-center">
        <Progress
          percent={percent}
          showInfo={true}
          strokeColor="#D9FF00"
          trailColor="#4B4B4B"
          strokeWidth={12}
          className="w-full custom-progress"
        />
      </div>
      <p className="text-[#D9FF00] text-xs mt-2">{title}</p>
    </div>
  )
}
