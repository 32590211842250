import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { Invite } from '../../../domain/types/Invite'
import { DocumentData, where } from 'firebase/firestore'
import useProfileStore from '../../appState/profileStore'
import { useState, useEffect } from 'react'
import { createProfile, getAllUsersByOrgIds } from '../profile/profileService'
import { OrgType, Profile } from '../../../domain/types/Profile'
import { signUpWithEmailAndPassword } from '../auth/authService'
import { InvestmentFirm } from '../../../domain/types/Investor'
import { Company } from '../../../domain/types/Company'
import { RealEstateSponsor } from '../../../domain/types/RealEstate'
import {
  createInvestmentFirm,
  updateInvestorFirm,
} from '../investor/investorService'
import { createCompany } from '../company/companyService'
import { createreRealEstateSponsor } from '../realEstate/realEstateSponsorService'

const invitesCollectionService = new FirestoreCollectionApi<Invite>('invites')

export function createInvite(data: Invite): Promise<DocumentData> {
  return invitesCollectionService.addDoc({
    ...data,
    createdOn: new Date(),
    [data.createdById]: true,
    [data.createdByOrgId]: true,
  })
}

function updateInvite(id: string, data: Invite): Promise<void> {
  return invitesCollectionService.updateDocByKey(id, data)
}

export function acceptInvite(id: string, data: Invite): Promise<void> {
  return updateInvite(id, {
    ...data,
    status: 'accepted',
    acceptedOn: new Date(),
    [data.acceptedById]: true,
    [data.acceptedByOrgId]: true,
  })
}

export function getInviteById(id: string): Promise<Invite> {
  return invitesCollectionService.getDocByKey(id)
}

export function getInvitesCount(): Promise<number> {
  return invitesCollectionService.getDocsCount()
}

export function getInvites(): Promise<Invite[]> {
  return invitesCollectionService.getAllDocs()
}

export function deleteInvite(id: string): Promise<void> {
  return invitesCollectionService.deleteDocByKey(id)
}

export function getMyInvites(uid: string): Promise<Invite[]> {
  return invitesCollectionService.getDocsByQuery([
    where('createdById', '==', uid),
  ])
}

export function getInviteByEmail(email: string): Promise<Invite[]> {
  return invitesCollectionService.getDocsByQuery([
    where('createToEmail', '==', email),
  ])
}

export function getInvitesByOrg(orgId: string): Promise<Invite[]> {
  return invitesCollectionService.getDocsByQuery([where(orgId, '==', true)])
}

export function getInvitesByOrgId(orgId: string): Promise<Invite[]> {
  return invitesCollectionService.getDocsByQuery([
    where('createdByOrgId', '==', orgId),
  ])
}

export async function inviteSignup(
  email: string,
  password: string,
  orgData: InvestmentFirm | Company | RealEstateSponsor,
  orgType: OrgType,
  profile: Profile,
  invite: Invite,
): Promise<void> {
  const res = await signUpWithEmailAndPassword(email, password)

  let firmRes: DocumentData | null = null
  if (orgType === 'INVESTOR') {
    firmRes = await createInvestmentFirm(orgData)
  }
  if (orgType === 'COMPANY') {
    firmRes = await createCompany(orgData as Company)
  }
  if (orgType === 'REALESTATE') {
    firmRes = await createreRealEstateSponsor(orgData as RealEstateSponsor)
  }
  let profileRes: DocumentData | null = null
  if (firmRes?.id && res?.user?.uid) {
    profile.organisationId = firmRes.id
    profile.uid = res?.user?.uid
    profile.updatedOn = new Date()
    profile.isActive = true
    profile.userStatus = 'activated' // payment
    profile.isWalkThroughPending = true
    profile.uid = res.user.uid
    if (orgType)
      profile.orgType = orgType as 'COMPANY' | 'INVESTOR' | 'REALESTATE'

    profileRes = await createProfile(profile)
  } else {
    throw new Error('Profile Not Created')
  }

  if (profileRes?.id) {
    await acceptInvite(invite.db_ref_id, {
      status: 'accepted',
      updatedOn: new Date(),
      acceptedOn: new Date(),
      acceptedById: res?.user?.uid,
      acceptedByEmail: email,
      acceptedByName: profile.displayName,
      acceptedByOrgId: firmRes.id,
      acceptedByOrgName: 'Investor Firm',
    } as Invite)

    if (orgType === 'INVESTOR')
      await updateInvestorFirm({
        ...orgData,
        id: firmRes.id,
        createdById: profileRes?.id,
      })

    if (orgType === 'REALESTATE')
      await createreRealEstateSponsor({
        ...orgData,
        id: firmRes.id,
        createdById: profileRes?.id,
      } as RealEstateSponsor)

    if (orgType === 'COMPANY')
      await createCompany({
        ...orgData,
        id: firmRes.id,
        createdById: profileRes?.id,
      } as Company)
  } else {
    console.error('Profile Not Created')
  }
}

export function useMyInvitesProfiles(): {
  isloaded: boolean
  invitesProfiles: Profile[]
} {
  const { profile } = useProfileStore()

  const [invitesProfiles, setInviteProfiles] = useState<Profile[]>([])

  const [isloaded, setIsloaded] = useState<boolean>(false)

  const loadProfiles = async (orgId: string) => {
    try {
      const invites: Invite[] = await getInvitesByOrg(orgId)
      const orgIds = invites
        .filter((i) => i.status === 'accepted')
        .map((invite) => {
          return orgId === invite.acceptedByOrgId
            ? invite.createdByOrgId
            : invite.acceptedByOrgId
        })
      const profiles = await getAllUsersByOrgIds(orgIds)
      setInviteProfiles(profiles.flat())
    } catch (error) {
      console.error(error)
    } finally {
      setIsloaded(true)
    }
  }

  useEffect(() => {
    if (profile?.organisationId) {
      loadProfiles(profile?.organisationId)
    }
  }, [profile])

  return { isloaded, invitesProfiles }
}
