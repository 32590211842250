import React from 'react'
import { UploadAvatar } from '../../v1/presentation/components/UploadAvatar/UploadAvatarNew'
import { Input, Form, Select, FormInstance, InputNumber } from 'antd'
import { SiteDataOption } from '../../v1/data/services/common/siteDataService'
import { AccountInfo } from './CompanyOnboarding'

interface CompanyDetailsSectionProps {
  businessModelOptions: SiteDataOption[]
  industryFocusOptions: SiteDataOption[]
  form: FormInstance<AccountInfo>
  onChange: (url: string) => void
}

export const CompanyDetailsSection: React.FC<CompanyDetailsSectionProps> = ({
  businessModelOptions,
  industryFocusOptions,
  onChange,
  form,
}) => {
  const MAX_COUNT = 3

  return (
    <div className="flex flex-col items-center px-4 md:px-0">
      {/* Avatar Upload Section */}
      <div className="mt-4">
        <div className="flex flex-col items-center">
          <Form.Item
            name="logoUri"
            label="Upload Company Logo"
            valuePropName="value"
            rules={[{ required: true, message: 'Please upload a file!' }]}
            labelCol={{ style: { textAlign: 'center', fontSize: '18px' } }}
            preserve={true}
          >
            <UploadAvatar
              value={form.getFieldValue('logoUri') || null}
              onChange={(url) => url && onChange(url)}
            />
          </Form.Item>
        </div>
      </div>

      {/* Form Fields Section */}
      <div className="mt-4 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-4">
          {/* Adjusted form fields for closer alignment */}
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              { required: true, message: 'Please input your company name!' },
            ]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Website"
            name="companyWebsite"
            rules={[
              { required: true, message: 'Please input your company website!' },
              { type: 'url', message: 'Please enter a valid URL' },
            ]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Business Model"
            name="businessModel"
            rules={[
              { required: true, message: 'Please input your business model!' },
            ]}
            className="w-full"
            preserve={true}
          >
            <Select options={businessModelOptions ?? []} />
          </Form.Item>
          <Form.Item
            label="Company Location"
            name="companyLocation"
            rules={[
              {
                required: true,
                message: 'Please input your company location!',
              },
            ]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company Founded"
            name="companyFounded"
            rules={[
              { required: true, message: 'Please input your company founded!' },
            ]}
            className="w-full"
            preserve={true}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Employee Count"
            name="employeeCount"
            rules={[
              { required: true, message: 'Please input your employee count!' },
            ]}
            className="w-full"
            preserve={true}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Industry Focus"
            name="industryFocus"
            rules={[
              { required: true, message: 'Please input your industry focus!' },
            ]}
            className="w-full"
            preserve={true}
          >
            <Select
              mode="multiple"
              maxCount={MAX_COUNT}
              options={industryFocusOptions ?? []}
              maxTagCount={MAX_COUNT}
            />
          </Form.Item>
          <Form.Item
            label="Company Social Link"
            name="companySocialLink"
            rules={[
              {
                required: true,
                message: 'Please input your company social link!',
              },
              {
                type: 'url',
                message: 'Please enter a valid URL',
              },
            ]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
    </div>
  )
}
