import React from 'react'

interface StepProps {
  title: string
  subtitle: string
  isActive: boolean
  onClick: () => void
  canClick?: boolean
}
export const Step: React.FC<StepProps> = ({
  title,
  subtitle,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={`p-4 text-black border-t-4 ${isActive ? 'border-yellow-green' : 'border-light-gray'}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick()
        }
      }}
    >
      <h1
        className={`text-[14px] font-semibold ${isActive ? 'text-yellow-green' : 'text-light-gray'}`}
      >
        {title}
      </h1>
      <h2
        className={`text-[14px] font-normal ${isActive ? 'text-yellow-green' : 'text-light-gray'}`}
      >
        {subtitle}
      </h2>
    </div>
  )
}
