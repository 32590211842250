import * as React from 'react'
import { SocialIcon } from 'react-social-icons'
import { useEffect } from 'react'

export interface ISocialIconsProps {
  iconsList: string[]
}

export default function SocialIcons(props: ISocialIconsProps) {
  useEffect(() => {
    setTimeout(() => {
      const iconElements = document.querySelectorAll('.social-icon')

      for (let ei = 0; ei < iconElements.length; ei++) {
        const element = iconElements[ei]
        const attr = element.getAttribute('aria-label')
        if (attr) element.classList.add(attr)
      }
    }, 2000)
  }, [])
  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`
    } else {
      return url
    }
  }
  return (
    <div className="social_icons_view">
      {props?.iconsList?.length !== 0 ? (
        <>
          {props.iconsList &&
            props?.iconsList?.map((item: string, index: number) => (
              <div key={index} className="social_info">
                <SocialIcon
                  target="_blank"
                  url={getUrl(item)}
                  rel="noopener noreferrer"
                />
              </div>
            ))}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
