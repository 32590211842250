import { useCallback, useEffect, useState } from 'react'
import { Stepper } from '../../v1/presentation/components/Stepper/Stepper'
import { Button, Form } from 'antd'
import { PitchDeckSection } from './PitchDeckSection'
import { PersonalDetailsSection } from './PersonalDetailsSection'
import { CompanyDetailsSection } from './CompanyDetailsSection'
import { ProductDetailsSection } from './ProductDetailsSection'
import { FinancialDetailsSection } from './FinancialDetailsSection'
import { useForm } from 'antd/es/form/Form'
import { companySiteProperties } from '../../v1/data/services/common/siteDataService'
import toast from '../../v1/presentation/common/Toast'
import { useNavigate } from 'react-router-dom'
import { Company, CompanyFile } from '../../v1/domain/types/Company'
import {
  getCompanyById,
  updateCompany,
} from '../../v1/data/services/company/companyService'
import useProfileStore from '../../v1/data/appState/profileStore'
import { updateProfile } from '../../v1/data/services/profile/profileService'
import { CompanyOnboardingSideContent } from './CompanyOnboardingSideContent/CompanyOnboardingSideContent'
import { ProgressBar } from '../../v1/presentation/components/Progressbar/ProgressBar'
import { useIsMobile } from '../../v1/presentation/common/useIsMobile'

export interface AccountInfo {
  pitchDeckImageFiles: string[]
  photoURL: string
  firstName: string
  lastName: string
  title: string
  personalSocialLink: string
  companyName: string
  companyWebsite: string
  businessModel: string[]
  companyLocation: string
  companyFounded: string
  employeeCount: number
  industryFocus: string[]
  companySocialLink: string
  fundingRound: string
  offeringType: string[]
  minimumInvestment: number
  maximumInvestment: string
  valueProposition: string
  valuation: string
  raisedToDate: number
  annualRevenue: string
  useOfFunds: string[]
  logoUri: string
}

export const CompanyOnboarding = () => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [form] = useForm<AccountInfo>()
  const navigateTo = useNavigate()
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [pitchDeckImages, setPitchDeckImages] = useState<string[]>([])
  const { profile, setProfile } = useProfileStore()
  const [company, setCompany] = useState<Company | undefined>(undefined)
  const [photoURL, setPhotoURL] = useState<string | undefined>(undefined)
  const [logoUri, setLogoUri] = useState<string | undefined>(undefined)
  const isMobile = useIsMobile()
  const [companyFiles, setCompanyFiles] = useState<CompanyFile[]>(
    company?.companyFiles ?? [],
  )

  useEffect(() => {
    if (profile?.organisationId) {
      const personalInitialValues: Partial<AccountInfo> = {
        pitchDeckImageFiles: [],
        photoURL: profile.photoURL ?? '',
        firstName: profile.firstName ?? '',
        lastName: profile.lastName ?? '',
        title: profile.title ?? '',
        personalSocialLink: profile.socialLinks?.[0] ?? '',
      }
      let companyInitialValues: Partial<AccountInfo> = {
        pitchDeckImageFiles: [],
        companyName: '',
        companyWebsite: '',
        businessModel: [],
        companyLocation: '',
        companyFounded: '',
        employeeCount: 0,
        industryFocus: [],
        companySocialLink: '',
        fundingRound: '',
        offeringType: [],
        minimumInvestment: 0,
        maximumInvestment: '',
        valuation: '',
        raisedToDate: 0,
        annualRevenue: '',
        useOfFunds: [],
        logoUri: '',
        valueProposition: '',
      }
      getCompanyById(profile.organisationId)
        .then((currCompany) => {
          setCompany(currCompany)
          companyInitialValues = {
            pitchDeckImageFiles: currCompany?.pitchDeckImageFiles ?? [],
            companyName: currCompany?.name ?? '',
            companyWebsite: !currCompany?.websiteUri?.includes('null')
              ? currCompany?.websiteUri
              : '',
            businessModel: currCompany?.businessModal ?? [],
            companyLocation: currCompany?.country ?? '',
            companyFounded: currCompany?.founded ?? '',
            employeeCount: currCompany?.employeeCount
              ? currCompany.employeeCount
              : undefined,
            industryFocus: currCompany?.industries ?? [],
            companySocialLink: currCompany?.socialLinks?.[0] ?? '',
            fundingRound: currCompany?.fundingRound ?? '',
            offeringType: currCompany?.structures ?? [],
            minimumInvestment: currCompany?.minimumInvestment ?? 0,
            maximumInvestment: currCompany?.maximumInvestment ?? '',
            valuation: currCompany?.valuation ?? '',
            valueProposition: currCompany?.valueProposition ?? '',
            raisedToDate: currCompany?.fundingRaisedToDate ?? 0,
            annualRevenue: currCompany?.annualRevenue ?? '',
            useOfFunds: currCompany?.useOfFunds ?? [],
            logoUri: currCompany?.logoUri ?? '',
          }
        })
        .finally(() => {
          form.setFieldsValue({
            ...personalInitialValues,
            ...companyInitialValues,
          })
        })
    }
  }, [profile, form])

  const {
    funding_round,
    company_structure,
    business_model,
    use_of_funds,
    industry_type,
  } = companySiteProperties ?? {}

  const steps = [
    {
      title: 'Your Details',
      subtitle: 'Basic account information',
    },
    {
      title: 'Company Details',
      subtitle: 'How investors find you',
    },
    {
      title: 'Product Details',
      subtitle: 'Explaining the value',
    },
    {
      title: 'Funding Details',
      subtitle: 'Your investment request',
    },
  ]

  const handleFinish = useCallback(
    async (values: AccountInfo) => {
      setIsUpdating(true)
      if (!profile || !company) return

      const profileData = {
        photoURL: photoURL,
        firstName: values.firstName,
        lastName: values.lastName,
        title: values.title,
        socialLinks: [values.personalSocialLink],
        hasCompletedWalkThrough: activeStep === steps.length,
      }

      const companyData = {
        id: profile.organisationId ?? '',
        pitchDeckImageFiles: pitchDeckImages.length
          ? pitchDeckImages
          : company.pitchDeckImageFiles,
        name: values.companyName,
        websiteUri: values.companyWebsite,
        businessModal: values.businessModel,
        country: values.companyLocation,
        founded: values.companyFounded,
        employeeCount: values.employeeCount,
        industries: values.industryFocus,
        socialLinks: [values.companySocialLink],
        fundingRound: values.fundingRound,
        structures: values.offeringType,
        minimumInvestment: values.minimumInvestment,
        maximumInvestment: values.maximumInvestment,
        valuation: values.valuation,
        valueProposition: values.valueProposition,
        fundingRaisedToDate: values.raisedToDate,
        annualRevenue: values.annualRevenue,
        useOfFunds: values.useOfFunds,
        logoUri: logoUri,
        companyFiles: companyFiles,
      }
      try {
        // Save profile and company data on each submission
        await updateProfile({ ...profile, ...profileData })
        await updateCompany({ ...company, ...companyData })

        if (activeStep === steps.length) {
          // Navigate only if it's the final step
          toast.success(
            'Onboarding complete! Your profile and company information have been successfully updated.',
          )
          setProfile({ ...profile, hasCompletedWalkThrough: true })
          navigateTo('/explore')
        } else {
          toast.success('Progress saved! You can continue later.')
        }
      } catch (error) {
        console.error('Error during onboarding:', error)
        toast.error('Failed to save your progress. Please try again.')
      } finally {
        setTimeout(() => {
          setIsUpdating(false)
        }, 600)
      }
    },
    [
      profile,
      company,
      photoURL,
      pitchDeckImages,
      activeStep,
      steps.length,
      logoUri,
      companyFiles,
      navigateTo,
      setProfile,
    ],
  )

  const handleFinishFailed = (errorInfo) => {
    toast.error('Failed to save', 'Please fill the required fields')
    console.error('Form Validation Failed:', errorInfo)
  }

  const handleStepChange = async (nextStep: number) => {
    if (!profile || !company) return

    try {
      const stepFieldNames = {
        0: ['pitchDeckImageFiles'],
        1: ['firstName', 'lastName', 'title', 'personalSocialLink', 'photoURL'],
        2: [
          'logoUri',
          'companyName',
          'companyWebsite',
          'businessModel',
          'companyLocation',
          'companyFounded',
          'employeeCount',
          'industryFocus',
          'companySocialLink',
        ],
        3: ['valueProposition', 'companyVideo'],
        4: [
          'fundingRound',
          'offeringType',
          'minimumInvestment',
          'maximumInvestment',
          'valuation',
          'raisedToDate',
          'annualRevenue',
          'useOfFunds',
        ],
      }

      const currentFields = stepFieldNames[activeStep || 0]

      // Validate current step fields
      await form.validateFields(currentFields)

      // Save progress after validating the current step
      const currentValues = form.getFieldsValue()
      await handleFinish(currentValues)

      // Move to the next step
      setActiveStep(nextStep)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      console.error('Validation error:', error)
    }
  }

  const activeStepSection = {
    0: {
      title: 'Jumpstart Onboarding With Your Pitch Deck',
      subtitle:
        'Upload your pitch deck, and our AI will securely autofill your profile to speed up setup.',
    },
    1: {
      title: 'Introduce Yourself to Investors',
      subtitle: 'Help investors get to know you and your role better.',
    },
    2: {
      title: 'Build a Profile That Attracts Investors',
      subtitle:
        'Your profile is the first impression for potential investors—make it count by sharing key details.',
    },
    3: {
      title: 'Showcase Your Product With a Compelling Pitch',
      subtitle:
        'Attract investors through a solid value proposition and an authentic video.',
    },
    4: {
      title: 'Provide Your Financial Details to Attract the Right Investors',
      subtitle:
        'Accurate data leads to intelligent investments and less time debating a straightforward deal.',
    },
  }

  const isPitchDeckSideContent = activeStep === 0 || activeStep === 3

  return (
    <div className="flex flex-row min-h-screen">
      {!isMobile && (
        <div
          className={`w-1/4 py-6 ${!isMobile && !isPitchDeckSideContent ? 'px-0' : 'px-2'} bg-gray-900`}
        >
          <CompanyOnboardingSideContent activeStep={activeStep} />
        </div>
      )}

      <div className="lg:w-3/4 w-full flex flex-col items-center p-2 lg:p-12">
        {isMobile ? (
          <ProgressBar
            title={steps.at(activeStep - 1)?.title ?? ''}
            percent={(activeStep / steps.length) * 100}
          />
        ) : (
          <Stepper
            steps={steps}
            activeStep={activeStep - 1}
            onStepChange={handleStepChange}
          />
        )}

        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          <div
            className={`${isMobile ? 'w-[80%]' : 'w-full'} mx-auto ${activeStep !== 0 && activeStep !== 3 && isMobile ? 'mb-6' : !isMobile && 'mb-4'}`}
          >
            <h1
              className={`${isMobile ? 'text-[20px]' : 'text-[30px]'} font-bold text-center`}
            >
              {activeStepSection[activeStep].title}
            </h1>
            <p
              className={`${isMobile ? 'text-[14px]' : 'text-[16px]'} text-center mt-1`}
            >
              {activeStepSection[activeStep].subtitle}
            </p>
          </div>

          {isMobile && <CompanyOnboardingSideContent activeStep={activeStep} />}

          <div style={{ display: activeStep === 0 ? 'block' : 'none' }}>
            <PitchDeckSection
              form={form}
              setPitchDeckImages={setPitchDeckImages}
              orgId={profile?.organisationId}
              company={company}
              companyFiles={companyFiles}
              setCompanyFiles={setCompanyFiles}
            />
          </div>
          <div style={{ display: activeStep === 1 ? 'block' : 'none' }}>
            <PersonalDetailsSection form={form} onChange={setPhotoURL} />
          </div>
          <div style={{ display: activeStep === 2 ? 'block' : 'none' }}>
            <CompanyDetailsSection
              form={form}
              businessModelOptions={business_model.options}
              industryFocusOptions={industry_type.options}
              onChange={setLogoUri}
            />
          </div>
          <div style={{ display: activeStep === 3 ? 'block' : 'none' }}>
            <ProductDetailsSection
              form={form}
              setCompanyFiles={setCompanyFiles}
              companyFiles={companyFiles}
            />
          </div>
          <div style={{ display: activeStep === 4 ? 'block' : 'none' }}>
            <FinancialDetailsSection
              form={form}
              fundingRoundOptions={funding_round.options}
              offeringTypeOptions={company_structure.options}
              useOfFunds={use_of_funds.options}
            />
          </div>
          <div className="mt-[50px] flex flex-col items-center gap-4 md:flex-row md:justify-center mb-20">
            {activeStep !== 0 && (
              <Button
                className="w-[300px]"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
            )}
            {activeStep === steps.length ? (
              <Form.Item>
                <Button
                  type="primary"
                  className="w-[300px]"
                  htmlType="submit"
                  loading={isUpdating}
                >
                  Submit
                </Button>
              </Form.Item>
            ) : (
              <Button
                type="primary"
                className="w-[300px]"
                onClick={() =>
                  handleStepChange(
                    activeStep !== undefined ? activeStep + 1 : 0,
                  )
                }
              >
                Continue
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}
