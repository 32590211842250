import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { updateProfile } from '../../../data/services/profile/profileService'
import useProfileStore from '../../../data/appState/profileStore'

export const OnboardingModal = ({
  showOnboardingModal,
}: {
  showOnboardingModal: boolean
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false) // Initial modal state is false
  const [iframeKey, setIframeKey] = useState(0) // Used to force iframe unmount/remount
  const { profile, setProfile } = useProfileStore()
  useEffect(() => {
    if (showOnboardingModal) {
      setTimeout(() => setIsModalOpen(true), 100)
    } else {
      setIsModalOpen(false)
    }
  }, [showOnboardingModal])

  const handleCancel = async () => {
    if (profile) {
      try {
        await updateProfile({ ...profile, hasSeenOnboardingVideo: true })
        setProfile({ ...profile, hasSeenOnboardingVideo: true })
      } catch (error) {
        console.error('Error updating profile:', error)
      } finally {
        // Send message to pause the video
        const iframe = document.querySelector('iframe')
        if (iframe) {
          iframe.contentWindow?.postMessage(
            JSON.stringify({ method: 'pause' }),
            '*',
          )
        }

        setIsModalOpen(false)
        setIframeKey((prevKey) => prevKey + 1)
      }
    }
  }

  return (
    <Modal
      title="Welcome to WealthVP!"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={800}
      centered
    >
      <p className="text-gray mb-4 text-left">
        Check out our video for tips on getting started and enhancing your
        profile.
      </p>
      <div className="relative w-full h-0 pb-[56.25%]">
        {isModalOpen && (
          <iframe
            key={iframeKey} // Ensure iframe remounts when key changes
            src="https://player.vimeo.com/video/1024438671"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo Video"
            className="absolute top-0 left-0 w-full h-full"
          />
        )}
      </div>
    </Modal>
  )
}
