import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { RealEstateOpportunity } from '../../../domain/types/RealEstateOpportunity'
import { where } from 'firebase/firestore'
import { cleanObject } from '../../../presentation/common/utils'

const reOpportunityCollectionService =
  new FirestoreCollectionApi<RealEstateOpportunity>('re-Opportunities')

export function createOpportunity(data: RealEstateOpportunity) {
  return reOpportunityCollectionService.addDoc(data)
}

export function getAllRealEstateOppurtunities(): Promise<
  RealEstateOpportunity[]
> {
  return reOpportunityCollectionService.getAllDocs()
}

export function getAllOpportunitiesByFirmId(
  id: string,
): Promise<RealEstateOpportunity[]> {
  return reOpportunityCollectionService
    .getDocsByQuery([where('sponserId', '==', id)])
    .then((data) => {
      return data
    })
}

export function getOpportunityByFirmId(
  id: string,
): Promise<RealEstateOpportunity> {
  return reOpportunityCollectionService.getDocByKey(id)
}

export function updateOpportunity(data: RealEstateOpportunity): Promise<void> {
  const cleanData = { ...cleanObject(data) }
  return reOpportunityCollectionService.updateDocByKey(data.id, cleanData)
}
