import { create } from 'zustand'

interface ConversationsState {
  conversations: Record<string, string[]>
  filters: Record<'isUnread' | 'isArchived' | 'isGroup' | 'isDirect', boolean>
  showFilters: boolean
  searchKey: string
  addConversationMsgs: (convoId, convoMsgs) => void
  updateFilter: (filterName: string, value: boolean) => void
  toggleFilters: () => void
  resetFilters: () => void
  setSearchKey: (key: string) => void
}

const useConversationsStore = create<ConversationsState>()((set) => ({
  conversations: {},
  showFilters: false,
  searchKey: '',
  filters: {
    isUnread: false,
    isArchived: false,
    isGroup: false,
    isDirect: false,
  },
  updateFilter: (filterName, value) => {
    set((state) => ({
      filters: { ...state.filters, [filterName]: value },
    }))
  },
  toggleFilters: () => {
    set((state) => ({ showFilters: !state.showFilters }))
  },
  addConversationMsgs: (convoId, convoMsgs) => {
    set((state) => ({
      conversations: { ...state.conversations, [convoId]: convoMsgs },
    }))
  },
  resetFilters: () => {
    set(() => ({
      filters: {
        isUnread: false,
        isArchived: false,
        isGroup: false,
        isDirect: false,
      },
    }))
  },
  setSearchKey: (key) => {
    set(() => ({ searchKey: key }))
  },
}))

export default useConversationsStore
