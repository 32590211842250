import React, { useEffect } from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { InvestmentFirm } from '../../domain/types/Investor'
import { getInvestmentFirmById } from '../../data/services/investor/investorService'
import { useNavigate, useParams } from 'react-router-dom'
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView'
import { BackArrow } from '../common/utilComponents'

export default function InvestorFirmMessageView() {
  const { id: investorFirmId } = useParams()

  const { profile } = useProfileStore()
  const orgId = investorFirmId || profile?.organisationId
  const navigateTo = useNavigate()

  const [investmentFirm, setInvestmentFirm] =
    React.useState<InvestmentFirm | null>(null)

  useEffect(() => {
    if (orgId)
      getInvestmentFirmById(orgId)
        .then((data) => {
          setInvestmentFirm(data)
        })
        .catch((err) => console.error(err))
  }, [orgId])

  return (
    <div className="body_layout">
      <div className="sub_header">
        <div
          className="back_icon"
          onClick={() => navigateTo(-1)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateTo(-1)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <BackArrow />
          <h1>Back</h1>
        </div>
      </div>

      <div className="wvp-p-flex-d">
        <div className="left-top-position">
          <div className="left-i</div>nfo"></div>

          <div className="deck-slideshow">
            <div className="re-company-deck">
              {investmentFirm ? (
                <ExpandedInvestmentFirmView
                  investmentFirm={investmentFirm}
                  showSocialIcons={true}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
