//Settings

import * as React from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { Button } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { getProfilesByOrgId } from '../../data/services/profile/profileService'
import { BackArrow, CloseIcon } from '../common/utilComponents'
import { InviteUser } from '../components/InviteUser'
import { getCompanyMembers } from '../../data/services/company/companyService'
import { CopyOutlined } from '@ant-design/icons'
import toast from '../common/Toast'
import { useNavigate } from 'react-router-dom'

type OrgMember = {
  name: string
  email: string
  status: string
  role: string
  onboardLink: string
}

const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value).then(
    () => {
      toast.success('Copied to clipboard', '')
    },
    () => {
      console.error('Failed to copy')
      toast.error('Failed to copy ', '')
    },
  )
}

export function Settings() {
  const navigateTo = useNavigate()
  const { profile } = useProfileStore()

  const [isPopupOpen, setIsPopupOpen] = React.useState(false)

  const openPopup = () => setIsPopupOpen(true)
  const closePopup = () => setIsPopupOpen(false)

  const [orgMembers, setOrgMembers] = useState<OrgMember[]>([])

  const loadOrgMembers = useCallback(async () => {
    if (profile?.organisationId) {
      let _orgMembers: OrgMember[] = []

      const profiles = await getProfilesByOrgId(profile?.organisationId)
      if (profiles?.length) {
        _orgMembers = profiles.map((p) => {
          return {
            name: p.displayName,
            email: p.email,
            status: p.userStatus,
            role: p.role,
          } as OrgMember
        })
      }
      const companyMembers = await getCompanyMembers(profile?.organisationId)

      if (companyMembers?.length) {
        _orgMembers = [
          ..._orgMembers,
          ...companyMembers.map((m) => {
            const member = {
              name: m.name,
              email: m.email,
              status: m.status,
              role: m.role,
            } as OrgMember
            if (m.status === 'INVITED') {
              member.onboardLink = `${window.location.origin}/onboarding?token=${m.orgId}_${m.db_ref_id}`
            }

            return member
          }),
        ]
      }
      setOrgMembers(_orgMembers)
    }
  }, [profile?.organisationId])

  useEffect(() => {
    loadOrgMembers()
  }, [loadOrgMembers])

  return (
    <div className="body_layout">
      <div className="sub_header">
        <div
          className="back_icon"
          role="button"
          tabIndex={0}
          onClick={() => navigateTo(-1)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateTo(-1)
            }
          }}
        >
          <BackArrow />
          <h3>Back</h3>
        </div>
      </div>
      <div className="setting_header">
        <h3 className="pb-0 soft_green_title">User Management</h3>
        <div className="web_add_btn">
          {profile?.orgType !== 'INVESTOR' ? (
            <Button type="primary" onClick={openPopup}>
              Invite
            </Button>
          ) : (
            <></>
          )}
        </div>

        {!isPopupOpen ? (
          <></>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div className="popup-header">
                <h2>Add User</h2>
                <CloseIcon onClick={closePopup} />
              </div>

              <InviteUser
                onAddComplete={(isAdded) => {
                  closePopup()
                  if (isAdded) {
                    loadOrgMembers()
                    //  window.location.reload();
                  }
                }}
              ></InviteUser>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className="table-container slim_scrollbar ">
          <table>
            <thead>
              <tr>
                <th>USER</th>
                <th>ROLE</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {orgMembers.map((member, index: number) => {
                return (
                  <tr key={`${member.email}-${index}`}>
                    <td className="capitalize">{member.name}</td>
                    <td className="capitalize">{member.role}</td>
                    <td className="capitalize">
                      {member?.status?.toLowerCase()}

                      {member.onboardLink ? (
                        <Button
                          className="copy_btn"
                          type="default"
                          onClick={() => copyToClipboard(member.onboardLink)}
                        >
                          <CopyOutlined /> Copy link
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="ms_add_btn">
        {profile?.orgType !== 'INVESTOR' ? (
          <Button type="primary" onClick={openPopup}>
            Invite
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export interface IOrgUsersProps {
  orgId?: string
}
