import React, { useEffect } from 'react'
import EmailLogin from '../components/EmailLogin'
import useProfileStore from '../../data/appState/profileStore'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { WVP_LOGO_NEW } from '../common/asset_paths'
import { getUrlParameterValue } from '../common/utils'
import { OrgType } from '../../domain/types/Profile'

export const LoginHeader = ({ title }) => {
  return (
    <div className="header_text">
      <img className="logo_asset" src={WVP_LOGO_NEW} alt="WealthVP" />
      <h2>{title}</h2>
    </div>
  )
}

export const LoginFooter = () => {
  return (
    <>
      <div className="terms">
        By continuing, you agree to our <br />
        <Link to="../assets/tou202309.pdf" target="_blank">
          Terms of Use and Acceptable Use Policy
        </Link>
      </div>
    </>
  )
}
const Login: React.FC = () => {
  const { profile } = useProfileStore()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/login') {
      if (profile) {
        const redirectPath = decodeURI(getUrlParameterValue('rediectPath'))
        if (redirectPath) {
          navigate(redirectPath)
        } else {
          const profileOrgType = profile?.orgType as OrgType
          const isAdmin = profileOrgType === 'ADMIN'
          if (isAdmin) {
            navigate('/admin/explore')
          } else {
            if (
              profile.isWalkThroughPending &&
              profileOrgType === 'INVESTOR' &&
              profile.organisationId
            ) {
              navigate(`/investor/${profile.organisationId}/edit`)
            } else {
              navigate('/explore')
            }
          }
        }
      }
    }
  }, [location.pathname, profile, navigate])

  return (
    <div className="login_page">
      <LoginHeader title={'Login'} />

      <EmailLogin />

      <LoginFooter />
    </div>
  )
}

export default Login
