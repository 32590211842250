export const ORG_TYPES = {
  INVESTOR: 'INVESTOR',
  COMPANY: 'COMPANY',
  FUND: 'FUND',
  DEAL: 'DEAL',
  PERSON: 'PERSON',
  UNKNOWN: 'UNKNOWN',
}

export const DetailsListFields = {
  INDUSTRIES: 'Industries',
  VALUATION: 'Pre-Money',
  OFFERING: 'Structure',
}
