import { create } from 'zustand'
import { Profile } from '../../domain/types/Profile'
import { ConnectionStatus } from '../../domain/types/Connection'

interface ProfileState {
  profile: Profile | null
  isAuthenticated: boolean
  // eslint-disable-next-line
  orgConnections: any[]
  unReadConvoIds: string[]
  setProfile: (userProfile: Profile) => void
  removeProfile: () => void
  setAuthenticated: (isAuthenticated: boolean) => void
  // eslint-disable-next-line
  setOrgConnections: (orgConnections: any[]) => void
  setUnReadConvoIds: (unReadConvoIds: string[]) => void
  removeUnReadConvoId: (unReadConvoId: string) => void
}

const useProfileStore = create<ProfileState>()((set) => ({
  profile: null,
  isAuthenticated: false,
  orgConnections: [],
  unReadConvoIds: [],
  setProfile: (userProfile) => {
    set(() => ({ profile: userProfile }))
  },
  removeProfile: () => {
    set(() => ({ profile: null }))
  },

  setAuthenticated: (isAuthenticated) => {
    set(() => ({ isAuthenticated }))
  },

  setOrgConnections: (orgConnections) => {
    const filterOrgConnections = orgConnections.filter(
      (convo) =>
        !(
          convo.connectionStatus === ConnectionStatus.CANCELLED ||
          convo.isActive === false
        ),
    )

    set(() => ({ orgConnections: filterOrgConnections }))
  },
  setUnReadConvoIds: (unReadConvoIds) => {
    const val: string[] = Array.from(new Set(unReadConvoIds))
    set(() => ({ unReadConvoIds: val }))
  },

  removeUnReadConvoId: (unReadConvoId) => {
    set((state) => ({
      unReadConvoIds: state.unReadConvoIds.filter((id) => id !== unReadConvoId),
    }))
  },
}))

export default useProfileStore
