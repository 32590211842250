/* eslint-disable */
import { initializeApp } from 'firebase/app'

import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  setDoc,
  query,
  onSnapshot,
  deleteDoc,
  writeBatch,
} from '@firebase/firestore'
import { getDownloadURL, ref, getStorage } from 'firebase/storage'
import { getMessaging } from 'firebase/messaging'
import {
  CollectionReference,
  DocumentData,
  getCountFromServer,
  QueryConstraint,
  updateDoc,
} from 'firebase/firestore'
import { convertDatesToFirestoreTimestamps } from '../services/explore/converters'
import { cleanObject } from '../../presentation/common/utils'

const app = initializeApp(window.FIREBASE_CONFIG)
export const firestore = getFirestore(app)
export const fireStorage = getStorage(app)

let fbMessagingVal: any = null

try {
  // Temp Solution: THis line of should be avoided in Native WebViews
  const urlParams = new URLSearchParams(window.location.search),
    isWebVIew = urlParams.get('isWebVIew') === 'true'
  if (!isWebVIew) {
    fbMessagingVal = getMessaging(app)
  }
} catch (error) {
  console.error('Error in Firebase Messaging', error)
}

export const fbMessaging = fbMessagingVal

export const db = fireStorage
export const storage = fireStorage

// const converter = <T>() => ({
//   toFirestore: (data: Partial<T>) => data,
//   fromFirestore: (snap) => snap.data() as T,
// });

/**
 * Takes an array of relative paths and returns an array of URLs.
 *
 * @param relativePaths - Array of relative paths to files in Firebase Storage.
 * @returns Promise<string[]> - A promise that resolves to an array of URLs.
 */
export const getStorageImageUrls = async (
  relativePaths: string[],
): Promise<string[]> => {
  try {
    const urlPromises = relativePaths.map(async (path) => {
      const imageRef = ref(storage, path)
      return await getDownloadURL(imageRef)
    })

    const urls = await Promise.all(urlPromises)
    return urls
  } catch (error) {
    console.error('Error getting download URLs', error)
    throw error
  }
}

const convertQuerySnapshotToDatalist = <T>(querySnapshot: any): T[] => {
  const data: T[] = []
  querySnapshot.forEach((doc) => {
    const docData = { ...doc.data(), db_ref_id: doc.id } as T
    if (docData) data.push(docData)
  })
  return data
}

export class FirestoreCollectionApi<T> {
  public collectionRef: CollectionReference<DocumentData>

  constructor(path: string) {
    this.collectionRef = collection(firestore, path)
  }

  // Below are the read operations
  getAllDocs(): Promise<T[]> {
    return getDocs(this.collectionRef).then((querySnapshot) =>
      convertQuerySnapshotToDatalist<T>(querySnapshot),
    )
  }

  getDocsCount(): Promise<number> {
    return getCountFromServer(this.collectionRef).then(
      (querySnapshot) => querySnapshot.data().count,
    )
  }

  getDocByKey(key: string): Promise<T> {
    const docREf = doc(this.collectionRef, key)
    return getDoc(docREf).then((docSnap) => docSnap.data() as T)
  }

  getDocsByQuery(queries: QueryConstraint[]): Promise<T[]> {
    return getDocs(query(this.collectionRef, ...queries)).then(
      (querySnapshot) => convertQuerySnapshotToDatalist<T>(querySnapshot),
    )
  }

  onCollectionUpdate(callback: (data: T[]) => void): void {
    onSnapshot(this.collectionRef, (querySnapshot) => {
      const data = convertQuerySnapshotToDatalist<T>(querySnapshot)
      callback(data)
    })
  }

  onQueryUpdate(
    queries: QueryConstraint[],
    callback: (data: T[]) => void,
  ): void {
    onSnapshot(query(this.collectionRef, ...queries), (querySnapshot) => {
      const data = convertQuerySnapshotToDatalist<T>(querySnapshot)
      callback(data)
    })
  }

  // Below are the write operations, Be Aware of the differences in the methods

  /*
       Purpose:      Adds a new document to a collection
       ID Handling:  Automatically generated
       Creation:     Always creates a new document
       Use Case:     When the document ID is not known beforehand
    */
  addDoc(data: any): Promise<DocumentData> {
    const cleanData = { ...cleanObject(data) } as any
    const cleanDataWithTimeStamps = convertDatesToFirestoreTimestamps(cleanData)
    return addDoc(this.collectionRef, cleanDataWithTimeStamps)
  }

  /*
       Purpose:      Sets data for a document (create or overwrite)
       ID Handling:  Requires specified ID
       Creation:     Creates or overwrites
       Use Case:     When the document ID is known and you want to create or overwrite it
    */
  setDocByKey(key: string, data: any): Promise<void> {
    const cleanData = { ...cleanObject(data) } as any
    const cleanDataWithTimeStamps = convertDatesToFirestoreTimestamps(cleanData)
    const docREf = doc(this.collectionRef, key)
    return setDoc(docREf, cleanDataWithTimeStamps)
  }

  /*
        Purpose:      Updates specific fields in a document
        ID Handling:  Requires specified ID
        Creation:     Only updates if the document exists
        Use Case:     When you need to update certain fields without affecting other fields
      */
  updateDocByKey(key: string, data: any): Promise<void> {
    const cleanData = { ...cleanObject(data) } as any
    const cleanDataWithTimeStamps = convertDatesToFirestoreTimestamps(cleanData)
    const docREf = doc(this.collectionRef, key)
    return updateDoc(docREf, cleanDataWithTimeStamps)
  }

  bulkUpdateByDocIds(
    docIds: string[], // Array of document IDs
    updateData: any,
  ): Promise<void> {
    const batch = writeBatch(firestore) // Create a batch

    docIds.forEach((id) => {
      const docRef = doc(this.collectionRef, id)
      const cleanData = { ...cleanObject(updateData) } as any
      const cleanDataWithTimestamps =
        convertDatesToFirestoreTimestamps(cleanData)

      batch.update(docRef, cleanDataWithTimestamps) // Add each document update to the batch
    })

    return batch.commit() // Commit all updates in a single batch
  }

  /*
        Purpose:      Deletes a document from a collection
        ID Handling:  Requires specified ID
        Creation:     Deletes the document
        Use Case:     When you need to delete a specific document
      */
  deleteDocByKey(key: string): Promise<void> {
    const docREf = doc(this.collectionRef, key)
    return deleteDoc(docREf)
  }

  // Method to get a reference to a subcollection
  getSubcollectionApi<U>(
    docId: string,
    subcollectionPath: string,
  ): FirestoreCollectionApi<U> {
    const fullPath = `${this.collectionRef.path}/${docId}/${subcollectionPath}`
    return new FirestoreCollectionApi<U>(fullPath)
  }
}
