import { useEffect, useState } from 'react'
import { ConfigProvider, Table } from 'antd'
import { getAllProfiles } from '../../../data/services/profile/profileService'
import { ColumnsType } from 'antd/es/table'
import { Profile } from '../../../domain/types/Profile'

export function Users() {
  const [users, setUsers] = useState<Profile[]>([])

  const columns: ColumnsType<Profile> = [
    {
      // title: `Username (${users?.length})`,
      title: 'Account Name',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: sortBy('displayName'),
    },

    {
      title: 'Account ID',
      dataIndex: 'organisationId',
      key: 'organisationId',
      sorter: sortBy('organisationId'),
    },

    {
      title: 'Org Name',
      dataIndex: 'organisationName',
      key: 'organisationName',
      sorter: sortBy('organisationName'),
    },

    {
      title: 'Account Type',
      dataIndex: 'orgType',
      key: 'orgType',
      sorter: sortBy('orgType'),
    },
    {
      title: 'Account Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      sorter: sortBy('userStatus'),
    },
  ]

  const loadUsers = () => {
    getAllProfiles().then((res: Profile[]) => {
      res = res.map((r: Profile) => {
        return {
          ...r,
          key: r.uid,
          location: `${r.country ? r.country + ',' : ''}  ${r.state ? r.state + ',' : ''} ${r.city ? r.city : ''}`,
        }
      })
      setUsers(res)
    })
  }

  useEffect(() => {
    loadUsers()
  }, [])

  return (
    <>
      <div className="users_view body_layout">
        <h1 className="header_title">Users</h1>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowHoverBg: '#0f1217',
              },
            },
          }}
        >
          <Table dataSource={users} columns={columns} key="table" />
        </ConfigProvider>
      </div>
    </>
  )
  function sortBy<T, P extends keyof T>(key: P) {
    return (a: T, b: T) => {
      const aa = a[key]
      const bb = b[key]

      if (typeof aa === 'string' && typeof bb === 'string') {
        return aa.localeCompare(bb)
      } else if (typeof aa === 'number' && typeof bb === 'number') {
        return aa - bb
      }
      return 0
    }
  }
}
