import * as React from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { Invitees } from './Invitees'
import { Accounts, OrgSelection } from './Accounts'
import { useNavigate, useParams } from 'react-router-dom'
import AccountView from './AccountView'
import { BackArrow } from '../../common/utilComponents'

export function AccountSection() {
  const { status } = useParams() as { status: string }
  const navigateTo = useNavigate()
  const [orgSelection, setOrgSelection] = React.useState<OrgSelection | null>(
    null,
  )

  const items: TabsProps['items'] = [
    {
      key: 'all',
      label: 'Accounts',
      children: (
        <Accounts
          selectedOrg={orgSelection}
          onSelectAccount={(selection) => {
            setOrgSelection(selection)
          }}
        />
      ),
    },
    {
      key: 'pending',
      label: 'Invites',
      children: <Invitees />,
    },
  ]

  const onChange = (key: string) => {
    navigateTo('/admin/accounts/' + key)
  }

  return (
    <div>
      <div className="accounts_view body_layout">
        <div className="header_view">
          <h1>Accounts</h1>
        </div>

        <div className={orgSelection ? 'hidden' : ''}>
          <Tabs
            defaultActiveKey={status ?? 'all'}
            items={items}
            onChange={onChange}
          />
        </div>
        {orgSelection && (
          <div className="mt-6 body_layout">
            <div className=" accounts_view sub_header">
              <div className="back_icon">
                <BackArrow onClick={() => setOrgSelection(null)}></BackArrow>
                <h2>Back</h2>
              </div>
            </div>
            <AccountView selectedOrg={orgSelection}></AccountView>
          </div>
        )}
      </div>
    </div>
  )
}
