/* eslint-disable */
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import {
  getFirestore,
  collection as _collection,
  doc as _doc,
  getDocs as _getDocs,
  getDoc as _getDoc,
  addDoc as _addDoc,
  setDoc as _setDoc,
  query,
  onSnapshot as _onSnapshot,
  deleteDoc as _deleteDoc,
} from '@firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getMessaging } from 'firebase/messaging'

const app = initializeApp(window.FIREBASE_CONFIG)

export const firestore = getFirestore(app)
export const fireStorage = getStorage(app)

let fbMessagingVal: any = null

try {
  // Temp Solution: This line should be avoided in Native WebViews
  const urlParams = new URLSearchParams(window.location.search),
    isWebVIew = urlParams.get('isWebVIew') === 'true'
  if (!isWebVIew) {
    fbMessagingVal = getMessaging(app)
  }
} catch (error) {
  console.error('Error in Firebase Messaging', error)
}

export const fbMessaging = fbMessagingVal

export const db = fireStorage
export const storage = fireStorage

class FirebaseApi {
  public db = firestore
  public auth = getAuth(app)
  public storage = fireStorage

  collection(path: string) {
    return _collection(firestore, path)
  }

  deleteDoc(path: string, ...args: any[]) {
    return _deleteDoc(this.doc(path, ...args))
  }

  doc(path: string, ...args: any[]) {
    return _doc(this.collection(path), ...args)
  }

  getDocs(path: string, ...args: any[]) {
    return _getDocs(query(this.collection(path), ...args))
  }

  getDoc(path: string, ...args: any[]) {
    return _getDoc(this.doc(path, ...args))
  }

  addDoc(path: string, args: any) {
    return _addDoc(this.collection(path), args)
  }

  setDoc(docREf: any, data: any) {
    return _setDoc(docREf, data, { merge: true })
  }

  get onSnapshot() {
    return _onSnapshot
  }
}

const firebaseApiInstance = new FirebaseApi()
export default firebaseApiInstance
