import React, {
  useState,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { Progress } from 'antd'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { fireStorage as storage } from '../../data/db/FireStoreApi'
import toast from '../common/Toast'

export interface UploadedFile {
  name: string
  url: string
  size: number
  type: string
}

export interface UploadInputState {
  file: UploadedFile | null
  busy: boolean
  progress: number
}

export interface UploadInputProps {
  hidden?: boolean
  onChange?: (state: UploadInputState) => void
  onComplete?: (file: UploadedFile | null) => void
  accept?: string
}

export interface UploadInputRef {
  open: () => void
}

const UploadInput = forwardRef<UploadInputRef, UploadInputProps>(
  ({ accept, hidden, onChange, onComplete }, forwardedRef) => {
    const input = React.useRef<HTMLInputElement>(null)

    const state = useRef<UploadInputState>({
      file: null,
      busy: false,
      progress: 0,
    })
    const changeFn = useRef<((state: UploadInputState) => void) | null>(
      onChange ?? null,
    )
    const completeFn = useRef<((file: UploadedFile | null) => void) | null>(
      onComplete ?? null,
    )

    useEffect(() => {
      changeFn.current = onChange ?? null
    }, [onChange])

    useEffect(() => {
      completeFn.current = onComplete ?? null
    }, [onComplete])

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] ?? null
        if (!file) return

        state.current.busy = true
        changeFn.current?.(state.current)
        const storageRef = ref(
          storage,
          `/userUploads/${Date.now()}--${file.name}`,
        )
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const p = snapshot.bytesTransferred / snapshot.totalBytes
            state.current.progress = p
            changeFn.current?.(state.current)
          },
          (err) => {
            console.error(err)
            state.current.busy = false
            changeFn.current?.(state.current)
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref)
              state.current.file = {
                url,
                size: file.size,
                name: file.name,
                type: file.type,
              }
              state.current.progress = 1
              state.current.busy = false
              changeFn.current?.(state.current)
              completeFn.current?.(state.current.file)
            } catch (err) {
              console.error(err)

              state.current.file = null
              state.current.progress = 0
              state.current.busy = false
              changeFn.current?.(state.current)
              completeFn.current?.(state.current.file)
            }
          },
        )
      },
      [],
    )

    React.useImperativeHandle(forwardedRef, () => ({
      open: () => {
        input.current?.click()
      },
    }))

    return (
      <input
        style={{ display: hidden ? 'none' : 'inherit' }}
        hidden={hidden}
        ref={input}
        type="file"
        multiple={false}
        accept={accept}
        onChange={handleChange}
      />
    )
  },
)

UploadInput.displayName = 'UploadInput'

export interface FileUploaderProps {
  onUploadComplete: (file: { url: string; size: number } | null) => void
  acceptFileTypes?: string
  label?: string
}

export interface FileUploaderRef {
  open: () => void
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onUploadComplete,
  ...props
}) => {
  let acceptTypes =
    'video/mp4,video/x-m4v,video/*,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*'
  if (props.acceptFileTypes) acceptTypes = props.acceptFileTypes

  const [file, setFile] = useState<UploadedFile | null>(null)
  const [progress, setProgress] = useState(0)
  const [busy, setBusy] = useState(false)

  const handleChange = React.useCallback(
    (state: { file: UploadedFile | null; busy: boolean; progress: number }) => {
      const disallowedTypes = [
        'application/vnd.ms-powerpoint',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ]

      if (state.file && disallowedTypes.includes(state.file.type)) {
        setFile(null)
        setProgress(0)
        setBusy(false)
        onUploadComplete(null)
        toast.error('Unsupported File type', 'This file format is not allowed')
        return
      }

      setFile(state.file)
      setProgress(state.progress)
      setBusy(state.busy)

      if (!state.busy) {
        onUploadComplete(state.file)
      }
    },
    [onUploadComplete],
  )

  return (
    <>
      {busy ? (
        <></>
      ) : (
        <>
          <UploadInput accept={acceptTypes} onChange={handleChange} />
          <label>{props.label}</label>
        </>
      )}

      {!progress ? (
        <></>
      ) : (
        <div className="uploading-progress">
          <p> Uploading... {file?.name}</p>
          <Progress
            percent={Math.round(progress * 100)}
            status={busy ? 'active' : 'success'}
          ></Progress>
        </div>
      )}
    </>
  )
}

export default FileUploader
