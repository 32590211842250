/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useNavigate, Link } from 'react-router-dom'
import {
  addMsgToConversation,
  ConvoParticipant,
  onConvoMessageUpdate,
  updateConversationById,
  getConversationParticipants,
  upsertConvoParticipant,
  ConvoMessage,
  getOtherOrgIdByKeyProps,
} from '../../../data/services/conversation/conversationService'
import toast from '../../common/Toast'
import { useCallback, useEffect, useRef, useState } from 'react'
import useProfileStore from '../../../data/appState/profileStore'
import {
  ConnectionStatus,
  ConnectionType,
} from '../../../domain/types/Connection'
import { getExploreDataByOrgId } from '../../../data/services/explore/exploreService'
import { ExploreCardData } from '../../../data/services/explore/ExploreCardData'
import { DEFAULT_IMG } from '../../common/constants'
import { Avatar, Button, Modal } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import {
  displayDateFormatter,
  readableTime,
} from '../../../data/services/explore/converters'
import {
  ArchiveIcons,
  BackArrow,
  DetailsIcons,
  DotsVerticalIcons,
  LucideSend,
  RedDeleteIcons,
} from '../../common/utilComponents'
import useConversationsStore from '../../../data/appState/conversationsStore'
import ColorCard from '../ColorCard'

export interface IChatWindowProps {
  conversationId: string
}

export function ChatWindow({ conversationId }: IChatWindowProps) {
  const { profile, orgConnections, removeUnReadConvoId } = useProfileStore()
  const detailsRef = useRef<HTMLDetailsElement | null>(null)
  //const [messages, setMessages] = useState<any>({});

  const { conversations, addConversationMsgs } = useConversationsStore()
  const [convoParticipants, setConvoParticipants] = useState<
    ConvoParticipant[]
  >([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isArchiveModalVisibile, setIsArchiveModalVisibile] = useState(false)

  const handleCloseModal = () => {
    setIsModalVisible(false)
  }
  // Check if firm is real estate and connection is pending - modal not visible
  const handleOpenMessagermodal = (connectionStatus, orgData) => {
    if (orgData?.orgType != null && connectionStatus !== 'PENDING') {
      setIsModalVisible(!isModalVisible)
    }
  }

  let messages: ConvoMessage[] = []

  if (conversations && conversations[conversationId]) {
    messages = conversations[conversationId] as unknown[] as ConvoMessage[]
  }

  const [newMessage, setNewMessage] = useState<string>('')

  const navigateTo = useNavigate()

  const currentConversation = orgConnections?.find(
    (convo) => convo.db_ref_id === conversationId,
  )

  let otherOrgId =
    currentConversation?.toOrgId === profile?.organisationId
      ? currentConversation?.fromOrgId
      : currentConversation?.toOrgId
  let otherOrgType =
    currentConversation?.toOrgId === profile?.organisationId
      ? currentConversation?.fromOrgType
      : currentConversation?.toOrgType

  const isDirectConvo =
    currentConversation?.connectionType === ConnectionType.USER_USER

  const connectionStatus: ConnectionStatus = isDirectConvo
    ? ConnectionStatus.ACCEPTED
    : currentConversation?.connectionStatus

  const isPendingState = connectionStatus === ConnectionStatus.PENDING

  const isActive = connectionStatus === ConnectionStatus.ACCEPTED

  const [orgData, setOrgData] = useState<ExploreCardData | null>(null)

  const convoRawData = currentConversation

  if ((!otherOrgId || !otherOrgType) && convoRawData?.keyProps && profile) {
    const resOrgId = getOtherOrgIdByKeyProps(convoRawData.keyProps, profile)

    if (resOrgId && convoRawData.keyProps[resOrgId]?.type === 'org') {
      otherOrgId = resOrgId
      otherOrgType = convoRawData.keyProps[resOrgId].orgType
    }
  }

  useEffect(() => {
    setOrgData(null)
    if (!otherOrgId || !otherOrgType) return
    getExploreDataByOrgId(otherOrgId, otherOrgType).then(setOrgData)
  }, [otherOrgId, otherOrgType])

  useEffect(() => {
    getConversationParticipants(conversationId).then(setConvoParticipants)
  }, [conversationId])

  const updateParticipantToConversation = useCallback(() => {
    if (!profile || !conversationId) return

    const { uid, displayName, photoURL, db_ref_id, title } = profile
    if (!uid) return
    const dateVal = new Date()

    const convoParticipant: ConvoParticipant = {
      profileId: db_ref_id,
      profileUid: uid,
      profileName: displayName || 'Participant',
      lastVisited: dateVal,
      updatedOn: dateVal,
      profilePic: photoURL ?? '',
      title: title,
    }

    upsertConvoParticipant(conversationId, convoParticipant)

    removeUnReadConvoId(conversationId)
  }, [conversationId, profile, removeUnReadConvoId])

  useEffect(() => {
    updateParticipantToConversation()
  }, [profile, conversationId, updateParticipantToConversation])

  useEffect(() => {
    if (currentConversation) {
      const { db_ref_id: convoId } = currentConversation

      onConvoMessageUpdate(convoId, (msgs) => {
        addConversationMsgs(convoId, msgs)
      })
    }
  }, [currentConversation, addConversationMsgs])

  const cancelConnectionRequest = () => {
    updateConversationById(currentConversation.db_ref_id, {
      updatedAt: new Date(),
      connectionStatus: ConnectionStatus.CANCELLED,
      CancelledBy: profile?.uid,
      isActive: false,
    })
  }
  const arChiveConvesation = async () => {
    try {
      setIsArchiveModalVisibile(false)
      await updateConversationById(currentConversation.db_ref_id, {
        [`archivedState.${profile?.uid}`]: true,
      })
      handleOptionClick()
      toast.success('Conversation Archived')
    } catch (error) {
      toast.error('Failed to Archive conversation')
    }
  }

  const unArChiveConvesation = async () => {
    try {
      setIsArchiveModalVisibile(false)
      await updateConversationById(currentConversation.db_ref_id, {
        [`archivedState.${profile?.uid}`]: false,
      })
      handleOptionClick()
      toast.success('Conversation Unarchived')
    } catch (error) {
      toast.error('Failed to Unarchive conversation')
    }
  }
  const handleOptionClick = () => {
    if (detailsRef.current) {
      detailsRef.current.removeAttribute('open') // Close the dropdown by removing the 'open' attribute
    }
    console.log(currentConversation, '.....')
  }
  const acceptConnectionRequest = async () => {
    await updateConversationById(currentConversation.db_ref_id, {
      connectionStatus: ConnectionStatus.ACCEPTED,
      isActive: true,
      updatedAt: new Date(),
      acceptedByUid: profile?.uid,
      acceptedByName: profile?.displayName,
    })

    await updateParticipantToConversation()
  }

  if (!conversationId) return <div> No conversation selected </div>

  const SendNewMessage = async () => {
    setNewMessage('')
    if (!newMessage.trim() || !currentConversation || !profile?.uid) {
      return
    }

    await addMsgToConversation(conversationId, {
      text: newMessage,
      createdById: profile?.uid,
      createdByName: profile?.displayName,
    })
    if (profile) {
      try {
        await upsertConvoParticipant(conversationId, {
          profileId: profile.id,
          profileUid: profile.uid,
          profileName: profile.displayName ?? '',
          profilePic: profile.photoURL,
          lastMessageSent: new Date(),
          updatedOn: new Date(),
          title: profile.title,
        })
      } catch (e) {
        console.error('Error updating participant', e)
      }
    }
  }

  const PendingActions = () => {
    const isRequestFromMyside =
      currentConversation?.fromOrgId === profile?.organisationId ||
      currentConversation?.createdByUid === profile?.uid

    if (currentConversation?.connectionType === ConnectionType.ORG_ORG) {
      // if the connection is org to org
      if (!isRequestFromMyside) {
        return (
          <div className="chat_actions_btn">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Reject{' '}
            </button>
            <button
              className="btn btn_accept ant-btn-primary"
              onClick={acceptConnectionRequest}
            >
              {' '}
              Accept{' '}
            </button>
          </div>
        )
      } else {
        return (
          <div className="chat_actions_btn">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Cancel Request{' '}
            </button>
          </div>
        )
      }
    } else {
      // if the connection is user to user
      if (isRequestFromMyside) {
        return (
          <div className="chat_actions_btn ">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Cancel Request{' '}
            </button>
          </div>
        )
      } else {
        return (
          <div className="chat_actions_btn ">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Reject{' '}
            </button>
            <button
              className="btn btn_accept ant-btn-primary"
              onClick={acceptConnectionRequest}
            >
              {' '}
              Accept{' '}
            </button>
          </div>
        )
      }
    }
  }
  const getConvoTitleByKeyProps = (
    keyProps: Record<string, { type: string; name: string }>,
    connectionType: ConnectionType,
  ) => {
    // this is to support old data format
    if (!profile) return ''

    try {
      const { organisationId, uid } = profile

      if (connectionType === ConnectionType.USER_USER) {
        for (const key in keyProps) {
          const value = keyProps[key]
          if (key !== uid && value.type === 'user') {
            return value.name
          }
        }
      }

      if (connectionType === ConnectionType.ORG_ORG) {
        for (const key in keyProps) {
          const value = keyProps[key]
          if (key !== organisationId && value.type === 'org') {
            return value.name
          }
        }
      }
    } catch (error) {
      return 'unknown'
    }
  }
  const PendingConvoMessage = () => {
    const isRequestByMe = currentConversation?.createdByUid === profile?.uid

    if (currentConversation?.connectionType === ConnectionType.ORG_ORG) {
      const isRequestFromMyside =
        currentConversation?.fromOrgId === profile?.organisationId

      const orgName = isRequestFromMyside
        ? currentConversation?.toOrgName
        : currentConversation?.fromOrgName

      const createdByName = currentConversation?.createdByName
        ? currentConversation?.createdByName
        : 'Someone'

      const sentOn = currentConversation?.createdOn
        ? displayDateFormatter(
            currentConversation?.createdOn,
            'MMM DD, YYYY h:mm A',
          )
        : ''

      if (isRequestFromMyside)
        return isRequestByMe ? (
          <div className="messageContainer">
            You have Requested to {orgName} on {sentOn}
          </div>
        ) : (
          <div>
            {' '}
            A Request a has been to {orgName} on {sentOn}
          </div>
        )
      else
        return (
          <div>
            {' '}
            {createdByName} from {orgName} Requested a connection to your
            organization on {sentOn}
          </div>
        )
    } else {
      return isRequestByMe ? (
        <div>You have Requested </div>
      ) : (
        <div> You Have got Request </div>
      )
    }
  }

  let convoAvatar = (
    <Avatar
      shape="circle"
      size={36}
      src={<UserOutlined style={{ color: '#44475b' }} />}
    />
  )
  let convoTitle: string | undefined = ''
  let isGroupChat = false
  let subTitle: string | undefined = [
    // 'You',
    ...convoParticipants
      .filter((c) => c.profileUid !== profile?.uid)
      .map((c) => c.profileName),
  ]
    .filter((t) => !!t)
    .join(', ')

  if (currentConversation) {
    convoTitle =
      profile?.organisationId === currentConversation?.toOrgId
        ? currentConversation.fromOrgName
        : currentConversation.toOrgName
    isGroupChat = currentConversation.connectionType === ConnectionType.ORG_ORG
  }

  let convoAvatarImgUrl: undefined | string
  if (isGroupChat) {
    if (orgData) {
      convoAvatarImgUrl = orgData?.imageUrl
      convoTitle = orgData?.title
      if (!convoTitle) {
        convoTitle =
          profile?.organisationId === currentConversation.toOrgId
            ? currentConversation.fromOrgName
            : currentConversation.toOrgName
      }
    }
  } else {
    const convoParticpantId =
      currentConversation?.fromUserId === profile?.uid
        ? currentConversation.toUserId
        : currentConversation?.fromUserId
    const convoParticipant = convoParticipants.find(
      (c) => c.profileUid === convoParticpantId,
    )

    if (convoParticipant?.profilePic)
      convoAvatarImgUrl = convoParticipant?.profilePic
    subTitle = convoTitle
    if (currentConversation) {
      convoTitle =
        profile?.uid === currentConversation.fromUserId
          ? currentConversation.toUserName
          : currentConversation.fromUserName
    }
  }

  if (!convoTitle && currentConversation?.keyProps) {
    convoTitle = getConvoTitleByKeyProps(
      currentConversation.keyProps,
      currentConversation.connectionType,
    )
  }

  if (convoAvatarImgUrl) {
    convoAvatar = <Avatar shape="circle" size={36} src={orgData?.imageUrl} />
  } else {
    convoAvatar = <ColorCard name={convoTitle?.charAt(0) || 'O'} />
  }

  const sortedMessages = [...messages]
    // eslint-disable-next-line
    .filter((m: any) => {
      if (currentConversation?.connectionType === ConnectionType.USER_USER) {
        const createdBy = m.createdById
        return currentConversation[createdBy] === true
      }
      return true
    })
    // eslint-disable-next-line
    .sort((a: any, b: any) => {
      return b.createdOn.toDate().getTime() - a.createdOn.toDate().getTime()
    })

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      SendNewMessage()
    }
  }
  const MessagesList = (convoMessages: ConvoMessage[]) => {
    return convoMessages.map((message, index) => {
      const isMyMessage = message.createdById === profile?.uid
      return (
        <div
          key={message.id || index}
          className={
            isMyMessage ? 'chat-msg right-msg' : 'chat-msg left-msg  gap-2'
          }
        >
          <div className="convo-avatar"> {convoAvatar} </div>
          <div>
            <div className="chat-text">
              <span>{message.text} </span>
            </div>
            <div className="chat-status">
              <span>{!isMyMessage ? message?.createdByName : ''} </span>
              <span>• </span>
              <span>{readableTime(message?.createdOn)} </span>
            </div>
          </div>
        </div>
      )
    })
  }

  const detailItemValue = (value: string | number | string[] | number[]) => {
    if (typeof value == 'object' && value.length) {
      // eslint-disable-next-line
      return value.map((item: any, index) => (
        <span key={index}>{(index ? ', ' : '') + item}</span>
      ))
    } else {
      return value
    }
  }

  const statusClass =
    currentConversation?.connectionType === ConnectionType.ORG_ORG
      ? currentConversation?.connectionStatus
      : ''

  let isOtherOrgIdDisabled = false
  if (orgData?.orgData?.isDisabled) {
    isOtherOrgIdDisabled = orgData.orgData.isDisabled === true ? true : false
  }

  return (
    <div
      className={`chat_view ${isOtherOrgIdDisabled === true ? 'isdisabled' : ''}`}
    >
      <div className="chat_header max-sm:gap-2 max-sm:p-4 bg-[#191B1E] border-[#0C0E12] border-b-[1px] h-[77px] flex justify-between max-sm:justify-start items-center rounded-tr-[8px] max-sm:bg-[#0F1217] max-sm:rounded-none max-sm:border-b-0">
        <div className="back_icon">
          <BackArrow onClick={() => navigateTo(-1)} />
        </div>
        <div
          className="chat_user_content max-sm:flex-1"
          role="button"
          tabIndex={0}
          onClick={() => handleOpenMessagermodal(connectionStatus, orgData)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleOpenMessagermodal(connectionStatus, orgData)
            }
          }}
        >
          {/* <div className="convo-avatar"> {convoAvatar} </div> */}
          <div className="chat_title sub_header_title ">
            <h1>{convoTitle}</h1>

            <div className="chat_sub_title sub_header_actions"> {subTitle}</div>
          </div>
        </div>

        <div className="p-2 cursor-pointer max-sm:p-0">
          {' '}
          <div className="relative inline-block ">
            <details className="group" ref={detailsRef}>
              <summary className="inline-flex px-3 py-2 cursor-pointer max-sm:p-0">
                <DotsVerticalIcons />
              </summary>

              <div
                className="absolute right-0 z-10 mt-2 w-[200px] origin-top-right  divide-y divide-[#515151]  bg-cool-black border border-[#1E2225] rounded shadow-[0px 4px 4px 0px rgba(0, 0, 0, 0.25);]  ring-black/5 focus:outline-none ring-1 ring-black ring-opacity-5 group-open:block hidden transition ease-out duration-200"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
              >
                <div className="py-1" role="none">
                  <a
                    onClick={() => {
                      handleOptionClick
                    }}
                    className="flex items-center gap-2 px-2 py-2 text-[16px] font-medium text-white  hover:text-white"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-0"
                  >
                    <DetailsIcons /> Details
                  </a>
                  <a
                    onClick={() => {
                      setIsArchiveModalVisibile(true)
                    }}
                    className="flex items-center gap-2 px-2 py-2 text-[16px] font-medium text-white hover:text-white"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-1"
                  >
                    {' '}
                    <ArchiveIcons />
                    {profile?.uid && currentConversation ? (
                      <>
                        {currentConversation.archivedState &&
                        currentConversation.archivedState[profile?.uid]
                          ? 'Unarchive'
                          : 'Archive'}
                      </>
                    ) : (
                      <></>
                    )}
                  </a>
                </div>
                <div className="">
                  <a
                    className="flex items-center gap-2 px-2 py-2 text-[16px] font-medium text-[#F04438] hover:text-[#F04438]"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-2"
                  >
                    {' '}
                    <RedDeleteIcons /> Delete
                  </a>
                </div>
              </div>
            </details>
          </div>
        </div>
      </div>
      <Modal onCancel={handleCloseModal} footer={null}>
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-opacity-50 hidden:">
          <div className="w-[343px]  absolute right-20  flex flex-col h-screen max-h-[600px] bg-[#191B1E] border border-[#515151] shadow-[0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);]">
            <div className="flex items-center justify-between px-6 pt-6 pb-2 mb-6 ">
              <h2 className="text-xl font-semibold text-white">
                Message Details
              </h2>
              <button className="text-xl text-[#a1a1a1] hover:text-white">
                &times;
              </button>
            </div>
            <div className="px-6">
              <input
                type="text"
                placeholder="Name group"
                className="w-full px-3 py-2 mb-2 text-white placeholder-[#a1a1a1] bg-light-black rounded focus:outline-none focus:ring-2 focus:ring-yellow-green"
              />

              <button className="mb-4 text-sm font-medium text-left cursor-pointer text-yellow-green">
                Save
              </button>
            </div>

            <div className="px-6 mb-4">
              <h3 className="mb-2 text-sm text-[#a1a1a1] flex items-center justify-between">
                People <span className="">3/6</span>
              </h3>
              <ul className="space-y-3">
                <li className="flex items-center space-x-3">
                  <img
                    src="https://via.placeholder.com/40"
                    alt="User"
                    className="object-cover w-10 h-10 rounded-full"
                  />
                  <div>
                    <p className="text-sm font-medium">
                      Elizabeth J. Katherine
                    </p>
                    <p className="text-xs text-[#a1a1a1]">
                      EG Investments of Arizona
                    </p>
                  </div>
                </li>
                <li className="flex items-center space-x-3">
                  <img
                    src="https://via.placeholder.com/40"
                    alt="User"
                    className="object-cover w-10 h-10 rounded-full"
                  />
                  <div>
                    <p className="text-sm font-medium">Eliza Thornberry</p>
                    <p className="text-xs text-[#a1a1a1]">
                      Wild Thornberry Pharma
                    </p>
                  </div>
                </li>
                <li className="flex items-center space-x-3">
                  <img
                    src="https://via.placeholder.com/40"
                    alt="User"
                    className="object-cover w-10 h-10 rounded-full"
                  />
                  <div>
                    <p className="text-sm font-medium">Elan Musk</p>
                    <p className="text-xs text-[#a1a1a1]">Muskers Investors</p>
                  </div>
                </li>
                <li className="flex items-center space-x-3">
                  <span className="flex items-center justify-center w-5 text-sm h-5 text-[#a1a1a1] bg-[#515151] rounded-full">
                    +
                  </span>
                  <p className="text-[#a1a1a1] text-sm font-medium">
                    Add Member
                  </p>
                </li>
              </ul>
            </div>

            <div className="px-6 mb-4">
              <h3 className="mb-2 text-sm font-medium text-[#a1a1a1]">
                Settings
              </h3>
              <div className="flex items-center justify-between">
                <span className="text-sm text-white">Mute</span>
                <label
                  htmlFor="mute-toggle"
                  className="relative inline-flex items-center cursor-pointer"
                >
                  <input
                    id="mute-toggle"
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                  />
                  <div className="w-[52px] h-[30px] bg-[#a1a1a1] filter peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-yellow-green rounded-full peer peer-checked:after:translate-x-5 peer-checked:bg-yellow-green after:content-[''] after:absolute after:top-[0.3rem] after:left-1 after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                </label>
              </div>
              <p className="text-xs text-[#a1a1a1]">
                Stops notifications for this message.
              </p>
            </div>

            <div className="flex items-center justify-between py-4 px-6 mt-auto border-t bg-[0F1217] border-[#A1A1A1]">
              <button className="text-[#F04438] hover:text-white">
                Leave Group
              </button>
              <button className="px-4 py-2 text-[#E4E4E4] border border-[#ffffff] rounded-md hover:bg-gray-600">
                Archive
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={isModalVisible} onCancel={handleCloseModal} footer={null}>
        <div className="message-details">
          <h2 className="popup_title">Message Details</h2>

          <div className="company-details">
            <h2>{orgData?.title}</h2>
            {orgData?.detailsList?.map((dlItem, index) => {
              if (dlItem?.label && dlItem?.value) {
                return (
                  <div className="list_group" key={index}>
                    <div key={`id-${dlItem.label}`} className="text-group">
                      <label>{dlItem?.label}</label>
                      <span>
                        {dlItem?.value ? detailItemValue(dlItem?.value) : ''} {}
                      </span>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
          {orgData?.orgType?.toLowerCase() === 'realestate' ? (
            <></>
          ) : (
            <Link
              to={`/${orgData?.orgType?.toLowerCase()}/Messenger/${orgData?.id}`}
            >
              <p className="company-profile-link">
                View {orgData?.orgType?.toLowerCase()} Profile
              </p>
            </Link>
          )}
          <div className="members">
            <h4>Members</h4>
            <div className="members_list">
              {convoParticipants && convoParticipants?.length > 0 ? (
                convoParticipants.map((member, index: number) => {
                  return (
                    <div className="member" key={index}>
                      {member?.profilePic ? (
                        <Avatar
                          shape="circle"
                          size={36}
                          src={member?.profilePic}
                        />
                      ) : (
                        <div className="convo-avatar">
                          <ColorCard
                            name={member?.profileName?.charAt(0) || 'O'}
                          />
                        </div>
                      )}
                      <div className="member-info">
                        <span className="member-name">
                          {member?.profileName}
                        </span>
                        <span className="member-role">{member?.title}</span>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="member-role">NO MEMBERS</div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <div
        className={`relative z-10 ${isArchiveModalVisibile ? 'flex' : 'hidden'}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 transition-opacity bg-gray-500/75"
          aria-hidden="true"
        ></div>
        <div className="fixed inset-0 bg-[#191B1E] bg-opacity-50 flex items-center justify-center z-50">
          <div className=" p-6 relative bg-[#191B1E] border border-[#515151] shadow-[0px 4px 4px 0px rgba(0, 0, 0, 0.25);] rounded  max-w-[500px] w-full">
            <button
              onClick={() => setIsArchiveModalVisibile(false)}
              className="absolute top-6 right-6 text-[#a1a1a1] hover:text-gray-700 focus:outline-none"
              aria-label="Close Modal"
            >
              ✕
            </button>

            <h2 className="mb-4 text-base font-semibold text-white">
              {profile?.uid && currentConversation ? (
                <>
                  {currentConversation.archivedState &&
                  currentConversation.archivedState[profile?.uid]
                    ? 'Unarchive'
                    : 'Archive'}
                </>
              ) : (
                <></>
              )}{' '}
              Message?
            </h2>
            <p className="text-[#C6C6C6] text-base mb-6">
              This hides the message and mutes notifications, retrievable by
              filtering your messages or requests.
            </p>

            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setIsArchiveModalVisibile(false)}
                className="px-4 py-2 w-1/2 bg-[#191B1E] text-white border border-white rounded hover:bg-gray-300 focus:outline-none"
              >
                Back
              </button>
              <button
                onClick={() => {
                  if (
                    profile?.uid &&
                    currentConversation.archivedState &&
                    currentConversation.archivedState[profile?.uid]
                  ) {
                    unArChiveConvesation()
                  } else {
                    arChiveConvesation()
                  }
                }}
                className="w-1/2 px-4 py-2 font-semibold rounded bg-yellow-green text-cool-black hover:bg-yellow-green focus:outline-none"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="request_div bg-[#0F1113] rounded-br-[8px]">
        {
          // SHOW PENDING Content or ACTIONS ONLY IF THE CONNECTION STATUS IS PENDING
          !isPendingState ? null : (
            <div className="request_section slim_scrollbar">
              <ConvoOrgDetails orgData={orgData}> </ConvoOrgDetails>
              <h3 className="pending_msg"> {PendingConvoMessage()}</h3>
              {PendingActions()}
            </div>
          )
        }

        <div className={'chat_window ' + statusClass}>
          <div className="chating_screen slim_scrollbar">
            {MessagesList(sortedMessages)}
          </div>

          {!isActive ? null : (
            <div className="chat-input-div rounded-br-[8px] bg-[#0F1113]">
              {/* <Iconsax/> */}
              <textarea
                className="text-input bg-light-black h-11 placeholder:#a1a1a1"
                placeholder="Type a message"
                value={newMessage}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setNewMessage(e.target.value)
                }}
              />
              <div className="lucide_send_icon">
                <LucideSend onClick={SendNewMessage} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export function ConvoOrgDetails(props) {
  const { orgData } = props

  if (!orgData) return <div> No Data </div>

  const detailItemValue = (value: string | number | string[] | number[]) => {
    if (typeof value == 'object' && value.length) {
      // eslint-disable-next-line
      return value.map((item: any, index) => (
        <span key={index}>{(index ? ', ' : '') + item}</span>
      ))
    } else {
      return value
    }
  }

  return (
    <div
      key={orgData?.id}
      className={'request_user ' + orgData?.orgType}
      id={orgData?.id}
    >
      <div className="req_profile_card">
        <div className="card_profile">
          <img
            className="card_img"
            src={orgData?.imageUrl ? orgData.imageUrl : DEFAULT_IMG}
            alt="images"
          />
        </div>
        <div className="req_card">
          <div className="header_title">{orgData?.title} </div>
          <div
            className="entity-descriotion multine-ellipsis"
            dangerouslySetInnerHTML={{
              __html: orgData?.about,
            }}
          />
          <div className="table_list">
            {orgData?.detailsList?.map((dlItem) => {
              if (
                dlItem?.label &&
                dlItem?.value &&
                (!Array.isArray(dlItem?.value) ||
                  (Array.isArray(dlItem?.value) && dlItem?.value.length !== 0))
              ) {
                return (
                  <div key={`id-${dlItem.label}`} className="text-group">
                    <label>{dlItem?.label}</label>
                    <span>
                      {dlItem?.value ? detailItemValue(dlItem?.value) : ''} {}
                    </span>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
          {orgData?.orgType?.toLowerCase() === 'realestate' ? (
            <></>
          ) : (
            <div className="btn_secondary">
              <Link
                to={`/${orgData?.orgType?.toLowerCase()}/Messenger/${orgData?.id}`}
              >
                <Button type="default">View Profile</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
