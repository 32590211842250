import React, { useState } from 'react'
import { Form, FormInstance, Input } from 'antd'
import { CompanyFile } from '@wealthvp/javascript-sdk'
import { CompanyFileCategories } from '../../v1/domain/types/Company'
import { AccountInfo } from './CompanyOnboarding'
const { TextArea } = Input

interface ProductDetailsSectionProps {
  companyFiles?: CompanyFile[]
  setCompanyFiles?: React.Dispatch<React.SetStateAction<CompanyFile[]>>
  form: FormInstance<AccountInfo>
}
export const ProductDetailsSection: React.FC<ProductDetailsSectionProps> = ({
  companyFiles,
  setCompanyFiles,
}) => {
  const [pitch, setPitch] = useState('')
  const maxPitchLength = 1000

  const addFileToCompany = async (file: {
    url: string
    size?: number
    images?: string[]
  }): Promise<void> => {
    let oFiles = companyFiles && companyFiles?.length ? [...companyFiles] : []

    oFiles = oFiles?.filter((f) => f.category !== CompanyFileCategories.intro)

    setCompanyFiles?.([
      ...oFiles,
      { category: CompanyFileCategories.intro, ...file },
    ])
  }

  return (
    <div className="flex flex-col items-center px-4 md:px-0">
      <div className="w-full max-w-3xl mt-6">
        <Form.Item
          label="Pitch Your Product"
          name="valueProposition"
          rules={[
            { required: true, message: 'Please enter your product pitch!' },
          ]}
        >
          <TextArea
            rows={6}
            maxLength={maxPitchLength}
            value={pitch}
            onChange={(e) => setPitch(e.target.value)}
            placeholder="Write a compelling pitch (up to 300 characters)"
            className="resize-none"
          />
        </Form.Item>
        <div className="text-right text-sm text-gray">
          {pitch.length} / {maxPitchLength} characters
        </div>
        <Form.Item
          label="Company Video"
          name="companyVideo"
          className="w-full md:w-[308px]"
        >
          <Input
            placeholder="Paste video URL"
            onBlur={(e) => {
              // setLink(e.target.value);
              if (e.target.value !== '') {
                addFileToCompany({
                  url: e.target.value,
                  size: 5584,
                })
              }
            }}
          />
        </Form.Item>
        <div className="text-left text-gray text-xs">
          YouTube or Vimeo only (min. 1280x400px)
        </div>
      </div>
    </div>
  )
}
