import React from 'react'
import { VideoThumbnail } from './VideoThumbnail'
import { ImageThumbnail } from './ImageThumbnail'
import { getVideoEmbedUrl } from '../../common/utils'

interface ThumbnailProps {
  videoUrl?: string
  imageUrl?: string
  title: string
  circle?: boolean
  showFullName?: boolean
}

const Thumbnail: React.FC<ThumbnailProps> = ({
  imageUrl,
  title,
  videoUrl,
  circle,
  showFullName,
}) => {
  const videoLink = videoUrl && getVideoEmbedUrl(videoUrl)

  return videoUrl ? (
    <VideoThumbnail videoUrl={videoUrl} videoLink={videoLink} />
  ) : (
    <ImageThumbnail
      imageUrl={imageUrl}
      title={title}
      circle={circle}
      showFullName={showFullName}
    />
  )
}

export default Thumbnail
