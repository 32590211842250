export const getReadableFundingRound = (round: string): string => {
  return round
    .replace(/_/g, '-')
    .replace(/-AND-ABOVE$/, ' and Above')
    .replace(/-ROUND$/, '-Round')
}

export const getStartOfWeek = (date: Date): Date => {
  const startOfWeek = new Date(date)
  const day = startOfWeek.getDay()
  const diff = startOfWeek.getDate() - day // adjust when day is not Sunday
  startOfWeek.setDate(diff)
  startOfWeek.setHours(0, 0, 0, 0)
  return startOfWeek
}
export const getEndOfWeek = (startOfWeek: Date): Date => {
  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setDate(startOfWeek.getDate() + 7)
  endOfWeek.setHours(23, 59, 59, 999)
  return endOfWeek
}
