//InviteUser

import { Button, Form, Input } from 'antd'
import * as React from 'react'
import useProfileStore from '../../data/appState/profileStore'
//import { MemberResponse, NewMember } from '@wealthvp/javascript-sdk';
import { getUserProfileByEmail } from '../../data/services/profile/profileService'
import toast from '../common/Toast'
//import { memberService } from '../../../api';
import { MailOutlined } from '@ant-design/icons'
import { createCompanyMember } from '../../data/services/company/companyService'
//import { CompanyMember } from '../../domain/types/Company';
import useSiteStore from '../../data/appState/siteStore'

export interface IInviteUserProps {
  onAddComplete: (isAdded: boolean) => void
}

export function InviteUser(props: IInviteUserProps) {
  const { profile: currentUser } = useProfileStore()

  const { setRootLoader } = useSiteStore()

  const [form] = Form.useForm()
  const addMember = async (values) => {
    try {
      setRootLoader(true)

      let { email } = values
      email = email.toLowerCase()
      if (!email || !currentUser?.organisationId || !currentUser?.orgType)
        return

      // TODO : Should not retrieve all user info here.
      const res = await getUserProfileByEmail(email)
      if (res) {
        toast.error(
          `Email ${email} already exists.`,
          'Please try with another email',
        )
        return
      }

      switch (currentUser?.orgType) {
        case 'COMPANY':
          await createCompanyMember(currentUser.organisationId, {
            email: values.email,
            name: values.name,
            role: 'Member',
            orgId: currentUser.organisationId,
            orgType: currentUser.orgType,
            invitedById: currentUser.id,
            status: 'INVITED',
            updatedOn: new Date(),
            createdOn: new Date(),
          })
          break
        // case 'INVESTOR':
        //   memberRes = await memberService.createInvestmentFirmMember({
        //     member: newMember,
        //     investmentFirmId: currentUser.organisationId,
        //   });
        //   break;
        // case 'REALESTATE':
        //   memberRes = await memberService.createRealEstateSponsorMember({
        //     member: newMember,
        //     realEstateSponsorId: currentUser.organisationId,
        //   });
        //   break;

        default:
          toast.error('Invite Member not supported', 'Failed to Invite user')
          break
      }

      toast.success('Invited user successfully', '')
      props.onAddComplete(true)
    } catch (e) {
      toast.error('Something went wrong', 'Failed to Invite user')
    } finally {
      setRootLoader(false)
    }

    // if (memberRes) {
    //   if (memberRes.errors.length) {
    //     console.error(memberRes.errors);
    //     toast.error('Something went wrong', 'Failed to Invite user');
    //     props.onAddComplete();
    //   } else if (memberRes.data) {
    //     toast.success('Invited user successfully', '');
    //     props.onAddComplete();
    //     // getOrganisationMembers(currentUser?.organisationId);
    //   }
    // }
  }

  return (
    <Form
      onFinish={addMember}
      form={form}
      requiredMark={false}
      layout="vertical"
      className="invite_form"
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Please enter email',
          },
        ]}
        label="Email"
      >
        <Input
          size="large"
          name="userEmail"
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="example@gmail.com"
        />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: 'Please enter  name',
          },
        ]}
        label="Full name"
      >
        <Input size="large" placeholder="Enter full name" name="name" />
      </Form.Item>
      <Form.Item name="role" label="Role">
        <Input
          size="large"
          placeholder="Select"
          defaultValue={'Member'}
          name="role"
          disabled={true}
        />
      </Form.Item>
      <div className="form-actions">
        <Form.Item>
          <Button
            className="cancel-btn"
            htmlType="submit"
            size="large"
            type="default"
            onClick={() => {
              props.onAddComplete(false)
            }}
          >
            Cancel
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            className="invite-btn"
            htmlType="submit"
            size="large"
            type="primary"
          >
            Invite
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}
