import { getAllRealEstateOppurtunities } from '../realEstate/realEstateOpportunityService'
import { getAllCompanies, getCompanyById } from '../company/companyService'
import { ExploreCardData } from './ExploreCardData'
import {
  convertCompanyToExploreCardData,
  convertInvestmentFirmToCardData,
  convertRealEstateToExploreCardData,
  convertREOpportunityToExploreCardData,
} from './converters'
import {
  getAllInvestmentFirms,
  getInvestmentFirmById,
} from '../investor/investorService'
import { OrgType } from '../../../domain/types/Profile'
import { getReSponsorById } from '../realEstate/realEstateService'

export function getExploreData(
  exploreType: string,
): Promise<ExploreCardData[]> {
  return new Promise<ExploreCardData[]>((resolve, reject) => {
    try {
      if (exploreType === 'companies') {
        getAllCompanies().then((companies) => {
          resolve(
            companies
              .filter((c) => !c.isHidden)
              .filter((c) => !c.isDisabled)
              .map(convertCompanyToExploreCardData),
          )
        })
      } else if (exploreType === 'realestates') {
        getAllRealEstateOppurtunities().then((realestates) => {
          resolve(
            realestates
              .filter((c) => !c.isHidden)
              .filter((c) => !c.isDisabled)
              .map(convertREOpportunityToExploreCardData),
          )
        })
      } else if (exploreType === 'investors') {
        getAllInvestmentFirms().then((investors) => {
          resolve(
            investors
              .filter((c) => !c.isHidden && !c.hideFromCompanies)
              .filter((c) => !c.isDisabled)
              .map(convertInvestmentFirmToCardData),
          )
        })
      }
    } catch (e) {
      reject(e)
    }
  })
}

export function getExploreDataByOrgId(
  orgId: string,
  orgType: OrgType,
): Promise<ExploreCardData> {
  return new Promise<ExploreCardData>((resolve, reject) => {
    try {
      if (orgType === 'COMPANY') {
        getCompanyById(orgId).then((company) => {
          resolve(convertCompanyToExploreCardData(company))
        })
      } else if (orgType === 'REALESTATE') {
        getReSponsorById(orgId).then((realestate) => {
          resolve(convertRealEstateToExploreCardData(realestate))
        })
        // we need to remove this code post testing the realestate connections
        // getOpportunityByFirmId(orgId).then((realestate) => {
        //   resolve(convertREOpportunityToExploreCardData(realestate));
        // });
      } else if (orgType === 'INVESTOR') {
        getInvestmentFirmById(orgId).then((investmentFirm) => {
          resolve(convertInvestmentFirmToCardData(investmentFirm))
        })
      }
    } catch (e) {
      reject(e)
    }
  })
}
