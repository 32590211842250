import * as React from 'react'
import { useEffect, useState } from 'react'
import { InvestmentFirm } from '../../domain/types/Investor'
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity'
import { Company } from '../../domain/types/Company'
import { OrgType } from '../../domain/types/Profile'
import { CompanyPageContent } from './Company/CompanyPageContents'
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView'
import { ExpandedREOpportunity } from '../components/ExpandedREOppurtunityVIew'
import { getCompanyById } from '../../data/services/company/companyService'
import { getInvestmentFirmById } from '../../data/services/investor/investorService'
import { getAllOpportunitiesByFirmId } from '../../data/services/realEstate/realEstateOpportunityService'

export function OrgView({ orgType, id }: { orgType?: string; id?: string }) {
  const orgTypeVal: OrgType = orgType?.toUpperCase() as OrgType

  const [company, setCompany] = useState<Company>()

  const [reOpp, setReOpp] = useState<RealEstateOpportunity>()

  const [invFirm, setinvFirm] = useState<InvestmentFirm>()

  useEffect(() => {
    if (orgTypeVal && id) {
      if (orgTypeVal === 'COMPANY') {
        getCompanyById(id).then(setCompany)
      }
      if (orgTypeVal === 'INVESTOR') {
        getInvestmentFirmById(id).then(setinvFirm)
      }
      if (orgTypeVal === 'REALESTATE') {
        getAllOpportunitiesByFirmId(id).then((res) => {
          if (res?.length > 0) {
            setReOpp(res[0])
          }
        })
      }
    }
  }, [id, orgTypeVal])

  const OrgViewElement = (): JSX.Element => {
    if (orgTypeVal === 'COMPANY' && company)
      return <CompanyPageContent company={company} />

    if (orgTypeVal === 'INVESTOR' && invFirm)
      return (
        <ExpandedInvestmentFirmView
          investmentFirm={invFirm}
        ></ExpandedInvestmentFirmView>
      )

    if (orgTypeVal === 'REALESTATE' && reOpp)
      return <ExpandedREOpportunity opportunity={reOpp} />

    return <></>
  }

  return (
    <div className="relative" style={{ minHeight: '80vh' }}>
      <div className="sticky top-[80px] z-20 flex justify-between items-center px-4 py-2 shadow-md rounded-md bg-cool-black"></div>
      <div className="mt-4 overflow-y-auto slide-view">
        <OrgViewElement></OrgViewElement>
      </div>
    </div>
  )
}
