import { where } from 'firebase/firestore'
import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { Profile } from '../../../domain/types/Profile'

const profileCollectionService = new FirestoreCollectionApi<Profile>('profile')

const profile_conversationService = (profileId: string) =>
  profileCollectionService.getSubcollectionApi<Profile>(
    profileId,
    `conversations`,
  )

export function createProfile(data: Profile) {
  return profileCollectionService.addDoc(data)
}

export function getProfileById(profileId: string): Promise<Profile> {
  return profileCollectionService.getDocByKey(profileId)
}

export function getUserProfile(uid: string): Promise<Profile> {
  return profileCollectionService
    .getDocsByQuery([where('uid', '==', uid)])
    .then((profiles) => {
      if (profiles?.length === 0) throw new Error('Profile not found')

      if (profiles?.length > 1) {
        // Update 'Multiple profiles found error to DB
        // throw new Error('Multiple profiles found for the user');
      }
      if (profiles?.length && profiles[0]) {
        const profile = { ...profiles[0], id: profiles[0].db_ref_id }
        return profile
      } else {
        throw new Error('Profile not found')
      }
    })
}

export function getUserProfileByEmail(email: string): Promise<Profile | null> {
  return profileCollectionService
    .getDocsByQuery([where('email', '==', email)])
    .then((profiles) => {
      if (profiles?.length === 0) return null

      if (profiles?.length > 1) {
        // Update 'Multiple profiles found error to DB
        // throw new Error('Multiple profiles found for the user');
      }
      if (profiles?.length && profiles[0]) {
        const profile = { ...profiles[0], id: profiles[0].db_ref_id }
        return profile
      } else {
        throw new Error('Profile not found')
      }
    })
}

export function updateProfile(profile: Partial<Profile>): Promise<void> {
  if (!profile.db_ref_id) {
    throw new Error(
      "The profile's database reference ID (db_ref_id) is required.",
    )
  }
  return profileCollectionService.updateDocByKey(profile.db_ref_id, profile)
}

export function updateNotificationViewedOn(profileId: string): Promise<void> {
  return profileCollectionService.updateDocByKey(profileId, {
    notificationsViewedOn: new Date(),
  })
}

export function disableWalkThrough(profileId: string): Promise<void> {
  return profileCollectionService.updateDocByKey(profileId, {
    isWalkThroughPending: false,
  })
}

export function setAppDownloadPromptPreference(
  profileId: string,
  isDisabled: boolean,
): Promise<void> {
  return profileCollectionService.updateDocByKey(profileId, {
    disableAppDownloadPrompt: isDisabled,
  })
}

export function getProfilesByOrgId(orgId: string): Promise<Profile[]> {
  return profileCollectionService.getDocsByQuery([
    where('organisationId', '==', orgId),
  ])
}

export function getAllProfilesByType(orgType: string): Promise<Profile[]> {
  return profileCollectionService.getDocsByQuery([
    where('orgType', '==', orgType),
  ])
}

export function isProfileValidForLogin(profile: Profile): boolean {
  // Can improve this logic to check for more status or any other validations
  //profile.isActive
  return profile.userStatus === 'activated' || profile.userStatus === 'payment'
}

export function setProfileConversations(
  profileId: string,
  conversationId: string,
  convoState: Partial<Profile>,
): Promise<void> {
  return profile_conversationService(profileId).setDocByKey(
    conversationId,
    convoState,
  )
}

export function getProfileConversationsByProfileId(
  profileId: string,
): Promise<Profile[]> {
  return profile_conversationService(profileId).getAllDocs()
}

export function getProfilesCount(): Promise<number> {
  return profileCollectionService.getDocsCount()
}

// for admin
export function getAllProfiles(): Promise<Profile[]> {
  return profileCollectionService.getAllDocs()
}

export function getAllUsersByOrgIds(OrgIds: string[]): Promise<Profile[][]> {
  const promises = OrgIds.map((orgId) => getProfilesByOrgId(orgId))

  return Promise.all(promises)
}
