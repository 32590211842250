import React from 'react'
import { Form, FormInstance, InputNumber, Select } from 'antd'
import { SiteDataOption } from '../../v1/data/services/common/siteDataService'
import { AccountInfo } from './CompanyOnboarding'

interface FinancialDetailsSectionProps {
  fundingRoundOptions: SiteDataOption[]
  offeringTypeOptions: SiteDataOption[]
  form: FormInstance<AccountInfo>
  useOfFunds: SiteDataOption[]
}

export const FinancialDetailsSection: React.FC<
  FinancialDetailsSectionProps
> = ({ fundingRoundOptions, offeringTypeOptions, useOfFunds }) => {
  const MAX_COUNT = 3

  return (
    <div className="flex flex-col items-center px-4 md:px-0">
      <div className="mt-4 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
          {/* Funding Round */}
          <Form.Item
            name="fundingRound"
            label="Funding Round"
            rules={[
              {
                required: true,
                message: 'Please select Round',
              },
            ]}
          >
            <Select options={fundingRoundOptions ?? []} />
          </Form.Item>

          {/* Offering Type */}
          <Form.Item
            label="Offering Type"
            name="offeringType"
            rules={[
              { required: true, message: 'Please select an offering type!' },
            ]}
            className="w-full"
            labelAlign="left"
          >
            <Select
              mode="multiple"
              options={offeringTypeOptions ?? []}
              maxTagCount={MAX_COUNT}
            />
          </Form.Item>

          {/* Minimum Investment */}
          <Form.Item
            label="Minimum Investment"
            name="minimumInvestment"
            rules={[
              {
                required: true,
                message: 'Please input the minimum investment!',
              },
            ]}
            className="w-full"
            labelAlign="left"
          >
            <InputNumber
              prefix="$"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''}
              style={{ width: '100%' }}
            />
          </Form.Item>

          {/* Maximum Investment */}
          <Form.Item
            label="Maximum Investment"
            name="maximumInvestment"
            rules={[
              {
                required: true,
                message: 'Please input the maximum investment!',
              },
            ]}
            className="w-full"
            labelAlign="left"
          >
            <InputNumber
              prefix="$"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''}
              style={{ width: '100%' }}
            />
          </Form.Item>

          {/* Valuation */}
          <Form.Item
            label="Valuation"
            name="valuation"
            rules={[{ required: true, message: 'Please input the valuation!' }]}
            className="w-full"
            labelAlign="left"
          >
            <InputNumber
              prefix="$"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '') ?? ''}
              style={{ width: '100%' }}
            />
          </Form.Item>

          {/* Raised to Date */}
          <Form.Item
            label="Raised to Date"
            name="raisedToDate"
            rules={[
              {
                required: true,
                message: 'Please input the amount raised to date!',
              },
            ]}
            className="w-full"
            labelAlign="left"
          >
            <InputNumber
              prefix="$"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''}
              style={{ width: '100%' }}
            />
          </Form.Item>

          {/* Annual Revenue */}
          <Form.Item
            name="annualRevenue"
            label="Annual Revenue"
            rules={[
              {
                required: true,
                message: 'Please enter Annual Revenue',
              },
            ]}
          >
            <InputNumber
              prefix="$"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '') || ''}
              style={{ width: '100%' }}
            />
          </Form.Item>

          {/* Use of Funds */}
          <Form.Item
            label="Use of Funds"
            name="useOfFunds"
            rules={[
              { required: true, message: 'Please specify the use of funds!' },
            ]}
            className="w-full"
            labelAlign="left"
          >
            <Select
              mode="multiple"
              options={useOfFunds ?? []}
              maxTagCount={MAX_COUNT}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  )
}
