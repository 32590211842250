import * as React from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { useNavigate } from 'react-router-dom'
import { ShimmerSocialPost } from 'react-shimmer-effects'
import {
  NotificationIcon,
  PersonIcon,
  ProfileIcon,
  UsersIcons,
} from '../common/utilComponents'

export default function Profile() {
  const { profile } = useProfileStore()
  const orgId = profile?.organisationId
  const navigateTo = useNavigate()

  const onEditProfile = () => {
    navigateTo('edit')
  }

  if (!profile) return <ShimmerSocialPost type="both" />
  return (
    <div className="body_layout">
      <div className="fixed_title">
        <h3>Account Settings</h3>
      </div>

      <div className="container_profile_view">
        {/* company user card */}

        {profile.orgType === 'COMPANY' ? (
          <div
            className="card"
            role="button"
            tabIndex={0}
            onClick={() => navigateTo(`/companyProfile/edit`)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigateTo(`/companyProfile/edit`)
              }
            }}
          >
            <div className="icon">
              <PersonIcon />
            </div>
            <h3>Personal Profile</h3>
            <p>
              Fill out your personal profile so Investors can learn more about
              you.
            </p>
          </div>
        ) : (
          <></>
        )}

        {/* investor / realesate card */}
        {profile.orgType !== 'COMPANY' ? (
          <div
            className="card"
            role="button"
            tabIndex={0}
            onClick={onEditProfile}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onEditProfile()
              }
            }}
          >
            <div className="icon">
              <PersonIcon />
            </div>
            <h3>Personal Profile</h3>
            <p>
              Update your login details and information so we know how to
              contact you.
            </p>
          </div>
        ) : (
          <></>
        )}

        <div
          className="card"
          role="button"
          tabIndex={0}
          onClick={() => navigateTo(`/${profile?.orgType}/${orgId}/edit`)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateTo(`/${profile?.orgType}/${orgId}/edit`)
            }
          }}
        >
          <div className="icon">
            <ProfileIcon />
          </div>
          <h3>{profile?.orgType?.toLowerCase()} Profile</h3>
          {profile.orgType !== 'COMPANY' ? (
            <p>
              Provide your investor and firm&apos;s information to allow
              companies to accurately match with you.
            </p>
          ) : (
            <></>
          )}
          {profile.orgType === 'COMPANY' ? (
            <p>
              Provide your company’s information to allow investors to
              accurately match with you.
            </p>
          ) : (
            <></>
          )}
          <p></p>
        </div>

        {/* user mamnagement company */}
        {profile.orgType === 'COMPANY' ? (
          <div
            className="card"
            role="button"
            tabIndex={0}
            onClick={() => navigateTo(`/settings`)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigateTo(`/settings`)
              }
            }}
          >
            <div className="icon">
              <UsersIcons />
            </div>
            <h3>User Management</h3>
            <p>Update and invite additional admin users to this account.</p>
          </div>
        ) : (
          <></>
        )}

        <div className="card">
          <div className="icon">
            <NotificationIcon />
          </div>
          <h3>Notifications</h3>
          <p>
            Choose notification preferences and how you want to be contacted.
          </p>
        </div>
      </div>
    </div>
  )
}
