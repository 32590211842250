import * as React from 'react'
import { Profile } from '../../domain/types/Profile'
import { useEffect, useState } from 'react'
import { getProfilesByOrgId } from '../../data/services/profile/profileService'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

export interface IOrgUsersProps {
  orgId: string
  onListLoaded: (profiles: Profile[]) => void
}

export function OrgUsers({ orgId, onListLoaded }: IOrgUsersProps) {
  const [orgProfiles, setOrgProfiles] = useState<Profile[]>([])

  useEffect(() => {
    getProfilesByOrgId(orgId).then((users) => {
      if (users?.length > 0) {
        setOrgProfiles(users)
      } else setOrgProfiles([])
    })
  }, [orgId])

  useEffect(() => {
    onListLoaded(orgProfiles)
  }, [orgProfiles, onListLoaded])
  const columns: ColumnsType<Profile> = [
    {
      title: 'Fist Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Last login',
      dataIndex: 'updatedOn',
      key: 'updatedOn',
    },
    {
      title: 'Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
    },
  ]

  return (
    <div className="table w-full ">
      <Table
        dataSource={orgProfiles}
        columns={columns}
        key="table"
        className="w-full"
      />
    </div>
  )
}
