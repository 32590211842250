import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from 'antd'
import { getCompaniesCount } from '../../../data/services/company/companyService'
import { getInvestmentFirmsCount } from '../../../data/services/investor/investorService'
import { getProfilesCount } from '../../../data/services/profile/profileService'
import { getReSponsersCount } from '../../../data/services/realEstate/realEstateService'
import useSiteStore from '../../../data/appState/siteStore'

export function AdminDashboard() {
  const navigateTo = useNavigate()

  const { setRootLoader } = useSiteStore()

  interface LinkCard {
    title: string
    count: number | string
    link: string
  }

  const [linkCards, setLinkCards] = React.useState<LinkCard[]>([
    {
      title: 'Companies',
      count: '-',
      link: '/admin/accounts/companies',
    },
    {
      title: 'Investors',
      count: '-',
      link: '/admin/accounts/investmentFirms',
    },
    {
      title: 'Real Estate',
      count: '-',
      link: '/admin/accounts/reSponsers',
    },
    {
      title: 'Profiles',
      count: '-',
      link: '/admin/users',
    },
  ])

  const loadLinkcardsData = React.useCallback(async () => {
    try {
      interface LinkCard {
        title: string
        count: number | string
        link: string
      }

      const _linkCards: LinkCard[] = []
      const companiesCount = await getCompaniesCount()

      _linkCards.push({
        title: 'Companies',
        count: companiesCount,
        link: '/admin/accounts/companies',
      })

      const investorsCount = await getInvestmentFirmsCount()

      _linkCards.push({
        title: 'Investors',
        count: investorsCount,
        link: '/admin/accounts/investmentFirms',
      })

      const realEsatesCount = await getReSponsersCount()

      _linkCards.push({
        title: 'Real Estate',
        count: realEsatesCount,
        link: '/admin/accounts/reSponsers',
      })

      const profilesCount = await getProfilesCount()

      _linkCards.push({
        title: 'Profiles',
        count: profilesCount,
        link: '/admin/users',
      })

      setLinkCards([..._linkCards])
    } catch (error) {
      console.error(error)
    } finally {
      setRootLoader(false)
    }
  }, [setRootLoader])

  React.useEffect(() => {
    setRootLoader(true)
    setTimeout(() => {
      loadLinkcardsData()
    }, 400)
  }, [loadLinkcardsData, setRootLoader])

  if (linkCards.length === 0) {
    return <div> Loading...</div>
  }

  return (
    <div className="dashboard_view body_layout">
      <h1 className="dash_title"> Dashboard</h1>

      <div className="admin_grid_3">
        {linkCards.map((linkCard, index) => {
          return (
            <div className="section_grid" key={index}>
              <div className="gap-5">
                <Card
                  size="small"
                  bordered={false}
                  onClick={() => navigateTo(linkCard.link)}
                >
                  <div style={{ textAlign: 'center' }}>
                    <h1> {linkCard.count}</h1>
                    <h4> {linkCard.title}</h4>
                  </div>
                </Card>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
