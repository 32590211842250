import React, { useEffect } from 'react'
import { onUserAuthStateChange } from '../../data/services/auth/authService'
import {
  getUserProfile,
  isProfileValidForLogin,
} from '../../data/services/profile/profileService'
import { Profile } from '../../domain/types/Profile'
import useProfileStore from '../../data/appState/profileStore'
import useSiteStore from '../../data/appState/siteStore'
import { Flex, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import toast from '../common/Toast'

export type IBaseLayoutProps = React.PropsWithChildren
export function BaseLayout(props: IBaseLayoutProps) {
  const { setProfile, removeProfile, setAuthenticated } = useProfileStore()
  const { rootLoaderState, setRootLoader, fullSiteLoaderState, loaderMessage } =
    useSiteStore()

  useEffect(() => {
    onUserAuthStateChange(async (user) => {
      if (user?.uid) {
        try {
          const profile: Profile = await getUserProfile(user.uid)
          if (profile && isProfileValidForLogin(profile)) {
            setProfile(profile)
          } else {
            toast.error('Profile not valid', 'Please login again')
            removeProfile()
          }
        } catch (error) {
          removeProfile()
        } finally {
          setAuthenticated(true)
          setRootLoader(false)
        }
      } else {
        removeProfile()
        setAuthenticated(true)
        setRootLoader(false)
      }
    })
  }, [removeProfile, setAuthenticated, setProfile, setRootLoader])

  return (
    <div className="baselayout">
      {!fullSiteLoaderState ? (
        <></>
      ) : (
        <div className="full-screen-Loader">
          <div className="loader_div">
            <Flex align="center" gap="middle">
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            </Flex>
            <span className="loader_text">{loaderMessage}</span>
          </div>
        </div>
      )}
      {rootLoaderState ? <div className="loader-line"></div> : <></>}
      {props.children}
    </div>
  )
}
