import {
  Avatar,
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Switch,
} from 'antd'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { InvestmentFirm, TeamMember } from '../../domain/types/Investor'
import { useEffect, useState, useRef } from 'react'
import {
  getInvestmentFirmById,
  updateInvestorFirm,
} from '../../data/services/investor/investorService'
import { investorSiteProperties } from '../../data/services/common/siteDataService'
import FileUploader from '../common/FileUploader'
import { SocialLinksEditor } from '../components/social-links-form-item'
import toast from '../common/Toast'
import { BackArrow, ImagePlus, SaveIcon } from '../common/utilComponents'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import useProfileStore from '../../data/appState/profileStore'
import {
  disableWalkThrough,
  updateProfile,
} from '../../data/services/profile/profileService'
import { Profile } from '../../domain/types/Profile'

export default function InvestorFirmEditPage() {
  const { profile, setProfile } = useProfileStore()
  const MAX_COUNT = 3
  const quillRef = useRef<ReactQuill | null>(null)

  const [form] = Form.useForm<InvestmentFirm>()
  const [investorFirm, setInvestorFirm] = useState<InvestmentFirm | null>(null)
  const [industries] = useState<string[]>(investorFirm?.industries ?? [])
  const [charCount, setCharCount] = useState(0)
  const [pictureUrl, setPictureUrl] = useState<string | undefined>(
    investorFirm?.logoUri ?? '',
  )
  const [investorImageUrl, setinvestorImageUrl] = useState<string>(
    profile?.photoURL ?? '',
  )
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>(
    investorFirm?.teamMembers ?? [],
  )
  const [socialLinks, setSocialLinks] = useState<string[]>(
    investorFirm?.socialLinks ?? [],
  )
  const [welcomeModalTogglePropt, setWelcomeModalTogglePropt] =
    React.useState<boolean>(profile?.isWalkThroughPending ? true : false)
  const [growNetworkTogglePropt, setGrowNetworkTogglePropt] =
    React.useState<boolean>(false)

  const { id: firmId } = useParams()
  const navigateTo = useNavigate()

  const {
    assets_under_management,
    investor_type,
    industry_type,
    investment_round,
    investment_size,
    social_impact,
    environmental_impact,
    economic_impact,
    diversity_and_inclusion,
  } = investorSiteProperties ?? {}

  useEffect(() => {
    if (firmId) {
      if (firmId !== profile?.organisationId) {
        navigateTo('/*')
        return
      }
      getInvestmentFirmById(firmId)
        .then((data: InvestmentFirm) => {
          if (data.websiteUri === 'https://null') {
            data.websiteUri = ''
          }
          form.setFieldsValue(data)
          setInvestorFirm(data)
          if (data.teamMembers?.length) {
            setTeamMembers(data.teamMembers)
          }
          if (data.socialLinks?.length) {
            setSocialLinks(data.socialLinks)
          }
          if (data.logoUri?.length) {
            setPictureUrl(data.logoUri)
          }
          if (data.profileImageUri?.length) {
            setinvestorImageUrl(data.profileImageUri)
          }
          setTimeout(() => {
            handleTextChange()
          }, 1000)
        })
        .catch((err) => console.error(err))
    }
  }, [firmId, form, navigateTo, profile?.organisationId])

  const onFinish = async (values: InvestmentFirm) => {
    try {
      setIsUpdating(true)

      if (!investorImageUrl) {
        toast.error('Please upload Investor profile picture', '')
        setIsUpdating(false)

        return
      }
      if (!pictureUrl) {
        toast.error('Please upload profile picture', '')
        setIsUpdating(false)

        return
      }
      if (charCount < 1) {
        toast.error('Firm Description', 'Please enter Firm Description')
        setIsUpdating(false)
        return
      }

      if (charCount > 1000) {
        toast.error('Firm Description', 'should be less than 1000 characters')
        setIsUpdating(false)
        return
      }

      values.teamMembers = teamMembers
      values['socialLinks'] = socialLinks.length !== 0 ? [...socialLinks] : []
      values['logoUri'] = pictureUrl ? pictureUrl : undefined
      values['profileImageUri'] = investorImageUrl
        ? investorImageUrl
        : undefined
      const updatedFirm = { ...investorFirm, ...values }

      await updateInvestorFirm(updatedFirm)

      toast.success('Investor profile is updated', '')
      setIsUpdating(false)
      if (profile?.isWalkThroughPending) {
        setGrowNetworkTogglePropt(true)
        disableWalkThrough(profile?.db_ref_id)
        setProfile({ ...profile, isWalkThroughPending: false })
      } else {
        navigateTo(-1)
      }

      const profileData = {} as Profile
      if (values) {
        profileData.firstName = values.firstName
        profileData.lastName = values.lastName
        profileData.photoURL = values.profileImageUri
        profileData.title = values.title
        profileData.socialLinks = values.socialLinks

        if (profile?.db_ref_id)
          await updateProfile({
            ...profileData,
            db_ref_id: profile?.db_ref_id,
          })

        setProfile({ ...profile, ...profileData })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo)
    toast.error('Failed to save', 'Please fill the required fields')
  }

  const handleTextChange = () => {
    const editor = quillRef.current?.getEditor()
    if (editor) {
      const contentLength = editor?.getLength() - 1 // by default quilleditor has 1 char, so we are removing in count
      setCharCount(contentLength)
    }
  }

  const modalStyles = {
    mask: {
      backdropFilter: 'blur(10px)',
    },
  }

  return (
    <div className="body_layout">
      <Form
        initialValues={{ ...investorFirm, ...profile }}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="sub_header">
          <div
            className="back_icon"
            role="button"
            tabIndex={0}
            onClick={() => navigateTo(-1)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigateTo(-1)
              }
            }}
          >
            <BackArrow />
            <h2> Back</h2>
          </div>

          <ul className="sub_header_actions">
            <li>
              <Button type="text" htmlType="submit" loading={isUpdating}>
                <SaveIcon /> <h2>Save</h2>
              </Button>
            </li>
          </ul>
        </div>

        <div className="wvp-p-flex-d">
          <div className="">
            <>
              <h3 className="soft_green_title">Investor Profile</h3>
              <div className="firm_profile_form">
                <div className="upload_wrapper">
                  <div className="label">
                    <div className="text-wrapper" style={{ color: '#F04438' }}>
                      *
                    </div>
                    <div
                      className="text-wrapper-2"
                      style={{ color: '#ffffff' }}
                    >
                      Profile Photo
                    </div>
                  </div>
                  <div className="img_wrapper">
                    {investorImageUrl ? (
                      <Avatar
                        shape="circle"
                        alt="Image"
                        icon={<img src={investorImageUrl} alt="" />}
                      />
                    ) : (
                      <>
                        <Avatar
                          shape="circle"
                          alt="Image"
                          icon={<ImagePlus />}
                        />
                      </>
                    )}
                    <div className="upload-container">
                      <p className="upload-box">
                        <FileUploader
                          onUploadComplete={(e) => {
                            setinvestorImageUrl(e?.url || '')
                          }}
                        />
                      </p>
                    </div>
                    <p className="text-wrapper-3">
                      JPG, PNG format (min. 150x150px)
                    </p>
                  </div>
                </div>
                <div className="grid-3">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your first name!',
                      },
                    ]}
                  >
                    <Input placeholder="First name" />
                  </Form.Item>

                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your last name!',
                      },
                    ]}
                  >
                    <Input placeholder="Last name" />
                  </Form.Item>
                  <Form.Item name="title" label="Job Title">
                    <Input placeholder="Job title" />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Firm Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input firm name',
                      },
                    ]}
                  >
                    <Input placeholder="firm name" />
                  </Form.Item>
                  <Form.Item
                    name="investorType"
                    label="Investor Type"
                    rules={[
                      {
                        required: true,
                        message: 'Please select investor type',
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      options={investor_type?.options ?? []}
                    />
                  </Form.Item>

                  <Form.Item
                    name="location"
                    label="Firm Location"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter valid City',
                      },
                    ]}
                  >
                    <Input placeholder="Enter City" name="city" />
                  </Form.Item>
                </div>
              </div>

              <div className="social_editor_view">
                <SocialLinksEditor
                  value={socialLinks}
                  onChange={(v) => {
                    setSocialLinks(v)
                  }}
                />
              </div>
            </>

            <h3 className="Secondary-textcolor">Investment Preferences</h3>
            <p className="small_textcolor">
              Put your selections in order from most important to least so
              companies will accurately match with you.
            </p>
            <div className="grid-4">
              <Form.Item
                name="investmentRound"
                label="Investment Round"
                rules={[
                  {
                    required: true,
                    message: 'Please select Investment Round',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  mode="multiple"
                  options={investment_round?.options ?? []}
                />
              </Form.Item>

              <Form.Item
                name="investmentSize"
                label="Investment Size"
                rules={[
                  {
                    required: true,
                    message: 'Please select Investment Round',
                  },
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  mode="multiple"
                  options={investment_size?.options ?? []}
                />
              </Form.Item>
              <div style={{ position: 'relative' }}>
                <Form.Item name="geographicFocus" label="Geographic Focus">
                  <Input type="text" placeholder="City, State" />
                </Form.Item>
                <span
                  style={{
                    fontSize: '12px',
                    color: '#a1a1a1',
                    position: 'absolute',
                    bottom: '-20px',
                    left: '2px',
                  }}
                >
                  Enter up to five locations (Optional)
                </span>
              </div>
            </div>
            <div className="industries_form">
              <Form.Item
                name="industries"
                label="Industries Focus"
                rules={[
                  {
                    required: true,
                    message: 'Please select industries',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  defaultValue={industries?.length ? [...industries] : []}
                  options={industry_type?.options ?? []}
                />
              </Form.Item>
            </div>
            <h3 className="Secondary-textcolor">Impact Areas of Interest</h3>
            <p className="small_textcolor">
              Enter your top three choices for each category so impact companies
              accurately match with you.
            </p>
            <div className="grid-2">
              <Form.Item name="socialImpact" label="Social Impact">
                <Select
                  maxCount={MAX_COUNT}
                  style={{ width: '100%' }}
                  mode="multiple"
                  options={social_impact?.options ?? []}
                />
              </Form.Item>

              <Form.Item name="environmentImpact" label="Environment Impact">
                <Select
                  maxCount={MAX_COUNT}
                  style={{ width: '100%' }}
                  mode="multiple"
                  options={environmental_impact?.options ?? []}
                />
              </Form.Item>
              <Form.Item name="economicImpact" label="Economic Impact">
                <Select
                  maxCount={MAX_COUNT}
                  style={{ width: '100%' }}
                  mode="multiple"
                  options={economic_impact?.options ?? []}
                />
              </Form.Item>

              <Form.Item
                name="diversityandInclusion"
                label="Diversity and Inclusion"
              >
                <Select
                  maxCount={MAX_COUNT}
                  style={{ width: '100%' }}
                  mode="multiple"
                  options={diversity_and_inclusion?.options ?? []}
                />
              </Form.Item>
            </div>

            <h3 className="Secondary-textcolor">Investment Success</h3>
            <p className="small_textcolor">
              Allow companies to view your successes as an investor.
            </p>

            <div className="grid-3">
              <Form.Item name="exits" label="Number of Exits">
                <Input type="number" placeholder="Number of Exits" />
              </Form.Item>

              <Form.Item name="notableInvestments" label="Notable Investments">
                <Input type="text" placeholder="Notable Investments" />
              </Form.Item>
            </div>

            <Divider></Divider>
            {/* Firm  profile section starts */}
            <h3 className="soft_green_title">Firm Profile</h3>
            <div className="firm_profile_form">
              <div className="upload_wrapper">
                <div className="label">
                  <div className="text-wrapper" style={{ color: '#F04438' }}>
                    *
                  </div>
                  <div className="text-wrapper-2" style={{ color: '#ffffff' }}>
                    Firm Photo
                  </div>
                </div>
                <div className="images_upload">
                  {pictureUrl ? (
                    <Avatar
                      shape="square"
                      icon={<img alt="" src={pictureUrl} />}
                    />
                  ) : (
                    <>
                      <Avatar shape="square" icon={<ImagePlus />} />
                    </>
                  )}

                  <div className="upload-container">
                    <p className="upload-box">
                      <FileUploader
                        onUploadComplete={(e) => {
                          setPictureUrl(e?.url)
                        }}
                      />
                    </p>
                  </div>
                  <p className="text-wrapper-3">
                    JPG, PNG format (min. 600x600px)
                  </p>
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <div className="grid-3">
                  <Form.Item
                    name="name"
                    label="Firm Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input firm name',
                      },
                    ]}
                  >
                    <Input placeholder="firm name" />
                  </Form.Item>

                  <Form.Item
                    name="websiteUri"
                    label="Firm Website"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Firm Website',
                      },
                      { type: 'url' },
                      { type: 'string', min: 6 },
                    ]}
                  >
                    <Input placeholder="Enter firm website" />
                  </Form.Item>

                  <Form.Item
                    name="assetsUnderManagement"
                    label="Assets Under Management"
                  >
                    <Select options={assets_under_management?.options ?? []} />
                  </Form.Item>
                </div>
                <div className="description_editor">
                  <Form.Item
                    name="about"
                    label="Firm Description"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Firm Description',
                      },
                    ]}
                    validateStatus={charCount < 1 ? 'error' : ''}
                    help={charCount < 1 ? 'Please enter Firm Description' : ''}
                  >
                    <ReactQuill
                      theme="snow"
                      ref={quillRef}
                      onChange={handleTextChange}
                    />
                  </Form.Item>

                  <div className="err-message">
                    {charCount > 1000 ? (
                      <p>Characters should not be more than 1000</p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="counter">
                    <span id="charCount">{charCount} </span> / 1000
                  </div>
                </div>
              </div>
            </div>

            {/* Firm  profile section end */}
            <Divider className="show_mobile" />
            <div className="actions_btn">
              <div className="switch_btn">
                <Form.Item
                  name="hideFromCompanies"
                  label="Hide profile from companies?"
                  valuePropName="checked"
                >
                  <Switch checkedChildren="ON" unCheckedChildren="OFF" />
                </Form.Item>
              </div>
              <Form.Item shouldUpdate>
                {() => {
                  // Check if there are any errors in the form fields
                  // const hasErrors = getFieldsError().some(
                  //   (field) => field.errors.length > 0,
                  // );

                  return (
                    <>
                      <Button
                        type="default"
                        style={{ marginRight: '1rem' }}
                        onClick={() => navigateTo(-1)}
                        className="bg-primary"
                      >
                        Back
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="bg-primary"
                        loading={isUpdating}
                      >
                        Save
                      </Button>
                    </>
                  )
                }}
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>

      <ConfigProvider
        modal={{
          styles: modalStyles,
        }}
      >
        <Modal
          className="modal-content  modal_blur"
          onOk={() => {
            setWelcomeModalTogglePropt(false)
          }}
          title="Welcome to WealthVP!"
          centered
          open={welcomeModalTogglePropt}
          width={1000}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => {
                setWelcomeModalTogglePropt(false)
              }}
            >
              Next
            </Button>,
          ]}
        >
          <div className="modal_text">
            <p>
              We’re not just another investment platform—we’re a gateway to
              tailored investment opportunities that fit your unique
              preferences. Let’s fill out your Investor profile so you can get
              the most out of the WealthVP platform!
            </p>
          </div>
        </Modal>
      </ConfigProvider>

      <ConfigProvider
        modal={{
          styles: modalStyles,
        }}
      >
        <Modal
          className="modal-content modal_blur_2"
          title="Grow Your Network"
          centered
          open={growNetworkTogglePropt}
          width={1000}
          footer={[
            <>
              <Button
                key="ok"
                type="primary"
                onClick={() => {
                  setGrowNetworkTogglePropt(false)
                  navigateTo('/investor-invites')
                }}
              >
                Invite Investors
              </Button>
              <Button
                key="cancel"
                type="primary"
                onClick={() => {
                  setGrowNetworkTogglePropt(false)
                  navigateTo('/explore')
                }}
              >
                Start Exploring
              </Button>
            </>,
          ]}
        >
          <div className="modal_text">
            <p>
              As you connect with companies or invite other investors, your
              network will organically grow within WealthVP. Building a strong
              network enhances your opportunities and influence on the platform.
            </p>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}
