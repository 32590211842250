import { Form, Tag, Typography } from 'antd'
import { SocialIcon } from 'react-social-icons'
// import { TypedItemProps } from './types';
import { useCallback, useRef } from 'react'
import { AddPlus, DeleteIcons } from '../common/utilComponents'

const { Paragraph } = Typography

export const SocialLinksEditor: React.FC<{
  value?: string[]
  onChange?: (value: string[]) => void
}> = ({ value: socialLinks, onChange: setSocialLinks }) => {
  const inputEl = useRef<HTMLInputElement>(null)

  const [form] = Form.useForm()
  const handleAddSocialLink = useCallback(() => {
    if (!inputEl.current?.value) return

    form.resetFields()

    let link = inputEl.current.value

    if (link.indexOf('http') !== 0 || link.indexOf('www.') === 0) {
      link = 'http://' + link
    }
    setSocialLinks?.(
      socialLinks && socialLinks.length !== 0 ? [...socialLinks, link] : [link],
    )
    inputEl.current.value = ''
  }, [setSocialLinks, socialLinks, form])

  const handleDeleteSocialLink = useCallback(
    (index: number) => {
      const arr = [...(socialLinks ?? [])]
      arr.splice(index, 1)
      setSocialLinks?.(arr)
    },
    [setSocialLinks, socialLinks],
  )

  return (
    <>
      <Form form={form} layout="vertical" className="sm_social">
        <Form.Item
          name="social"
          label="Social Link"
          rules={[
            {
              required: true,
              type: 'url',
              message: 'link is not a valid url',
            },
          ]}
        >
          <input
            ref={inputEl}
            name="link"
            type="text"
            placeholder="Enter Social Link"
            className="ant_input "
          />
        </Form.Item>

        <Form.Item shouldUpdate label=" ">
          {({ getFieldsError }) => {
            // Check if there are any errors in the form fields
            const hasErrors = getFieldsError().some(
              (field) => field.errors.length > 0,
            )
            return (
              <>
                <AddPlus onClick={handleAddSocialLink} disabled={hasErrors} />
              </>
            )
          }}
        </Form.Item>
      </Form>

      <div className="social_icons">
        {socialLinks &&
          socialLinks?.length !== 0 &&
          socialLinks.map((item: string, i: number) => (
            <Tag key={`${item}-${i}`}>
              <div className="social_info">
                <SocialIcon target="_blank" url={item} />
                <Paragraph ellipsis title={item}>
                  {item}
                </Paragraph>
                <div className="trash_icon">
                  <DeleteIcons onClick={() => handleDeleteSocialLink(i)} />
                </div>
              </div>
            </Tag>
          ))}
      </div>
    </>
  )
}
