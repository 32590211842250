import * as React from 'react'
import { useEffect } from 'react'

import FileViewer from 'react-file-viewer'
export interface IFileLoaderProps {
  fileUrl: string
  zoompercentage: number
  type?: string
}

export function FileLoader({
  fileUrl,
  zoompercentage,
  type,
}: IFileLoaderProps) {
  const [canLoad, setCanLoad] = React.useState(false)

  const getFileInfoByURL = (url: string) => {
    url = decodeURIComponent(url)
    const filePath = url.split('?')[0]
    return {
      filePath,
      fileExt: filePath.split('.').pop(),
      fileName: filePath.split('/').pop()?.split('--').pop(),
    }
  }

  if (!type) {
    const { fileExt } = getFileInfoByURL(fileUrl)
    if (fileExt) type = fileExt
  }
  useEffect(() => {
    setCanLoad(false)

    //TODO work around for this delay
    setTimeout(() => {
      setCanLoad(true)
    }, 1000)
  }, [fileUrl])
  const onError = (e) => {
    console.error(e, 'error in file-viewer')
  }
  return (
    <div className={`file-viewer zoom-${zoompercentage}`}>
      {canLoad && fileUrl && type ? (
        <FileViewer
          fileType={type}
          filePath={fileUrl}
          className={`zoom-${zoompercentage}`}
          onError={onError}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}
