import React, { useRef, useEffect, useState } from 'react'
import { throttle } from 'lodash'

interface ThrottledInputProps {
  value: string
  onChange: (value: string) => void
  throttleDelay?: number // Delay in milliseconds
  placeholder?: string
  className?: string
}

const ThrottledInput: React.FC<ThrottledInputProps> = ({
  value,
  onChange,
  throttleDelay = 300,
  placeholder,
  className,
}) => {
  const [internalValue, setInternalValue] = useState(value)

  const throttledOnChange = useRef(
    throttle((value: string) => {
      onChange(value)
    }, throttleDelay),
  )

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      throttledOnChange.current.cancel()
    }
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setInternalValue(newValue)
    throttledOnChange.current(newValue)
  }

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  return (
    <input
      type="text"
      value={internalValue}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
    />
  )
}

export default ThrottledInput
