import * as React from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { Profile } from '../../domain/types/Profile'
import { Button } from 'antd'
import {
  ConvoParticipant,
  createConversation,
  getConvoByKey,
  useMyNetworkProfiles,
} from '../../data/services/conversation/conversationService'
import { useNavigate } from 'react-router-dom'
import { ConnectionType, ConversationType } from '../../domain/types/Connection'
import { useMyInvitesProfiles } from '../../data/services/Invites/InviteService'

export function MyNetwork() {
  const { loading, networkProfiles } = useMyNetworkProfiles()
  const { isloaded: inviteProfilesLoaded, invitesProfiles } =
    useMyInvitesProfiles()

  let myNetworkProfiles: Profile[] = []

  if (networkProfiles.length > 0) {
    myNetworkProfiles = [...myNetworkProfiles, ...networkProfiles]
  }

  if (inviteProfilesLoaded && invitesProfiles.length > 0) {
    myNetworkProfiles = [...myNetworkProfiles, ...invitesProfiles]
  }

  const { profile } = useProfileStore()

  if (loading) return <div>Loading...</div>

  return (
    <div className="body_layout">
      <div className="explore_header">
        <div className="filter-wrap">
          <div className="switch-wrap">
            <div className="exp_title ms-hide">My Network</div>
          </div>
          <div></div>
        </div>
      </div>
      <TableView tableData={myNetworkProfiles} profile={profile} />
    </div>
  )
}

export interface ITableViewProps {
  tableData: Profile[]
  profile: Profile | null
}
export default function TableView(props: ITableViewProps) {
  // TO DO Kowsalya
  const { profile } = props

  const navigateTo = useNavigate()

  const gotoProfileChat = (selProfile: Profile) => {
    if (profile === null) return

    const connectionKey = [selProfile.uid, profile.uid].sort().join('_')
    getConvoByKey(connectionKey, ConnectionType.USER_USER).then((convos) => {
      if (convos.length > 0) {
        navigateTo(`/chat/${convos[0].db_ref_id}`)
      } else {
        const {
          displayName: createdByName,
          uid: createdByUid,
          orgType: fromOrgType,
          organisationId: fromOrgId,
          organisationName: fromOrgName,
        } = profile || {}

        const {
          organisationId: toOrgId,
          orgType: toOrgType,
          organisationName: toOrgName,
        } = selProfile

        const toUserId = selProfile.uid,
          fromUserId = createdByUid

        const initState = {
          createdByUid,
          createdByName,
          fromOrgId,
          fromOrgType,
          fromOrgName,
          toOrgId,
          toOrgType,
          toOrgName,
          connectionType: ConnectionType.USER_USER,
          type: ConversationType.DIRECT,
          ...(toOrgId ? { [toOrgId]: true } : {}),
          ...(fromOrgId ? { [fromOrgId]: true } : {}),
          ...(toUserId ? { [toUserId]: true } : {}),
          ...(fromUserId ? { [fromUserId]: true } : {}),
          toUserId,
          fromUserId,
          fromUserName: createdByName,
          toUserName: selProfile.displayName,
        }

        const convoParticipant: ConvoParticipant = {
          profileId: profile?.id ?? '',
          profileUid: profile?.uid ?? '',
          profileName: profile?.displayName ?? '',
        }

        createConversation(
          initState,
          null,
          convoParticipant,
          connectionKey,
        ).then((data) => {
          if (data.id) navigateTo(`/chat/${data.id}`)
        })
      }
    })
  }

  return (
    <div className="table-container slim_scrollbar" key={'table-container'}>
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Company/Firm</th>
            <th>Type</th>
            <th>Chat</th>
          </tr>
        </thead>
        <tbody>
          {props.tableData.map((data) => {
            return (
              <tr key={data.id}>
                <td>{data.firstName}</td>
                <td>{data.lastName}</td>
                <td>{data.organisationName}</td>
                <td>{data.orgType}</td>
                <td>
                  {' '}
                  <Button
                    className="message-btn"
                    onClick={() => gotoProfileChat(data)}
                  >
                    Message
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
