import React, { useEffect } from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity'
import { getOpportunityByFirmId } from '../../data/services/realEstate/realEstateOpportunityService'
import { useNavigate, useParams } from 'react-router-dom'
import { ExpandedREOpportunity } from '../components/ExpandedREOppurtunityVIew'
import { BackArrow } from '../common/utilComponents'

export default function REOppMessageView() {
  const { id: ReOpportunityId } = useParams()

  const { profile } = useProfileStore()
  const orgId = ReOpportunityId || profile?.organisationId
  const navigateTo = useNavigate()

  const [ReOpportunity, setReOpportunity] =
    React.useState<RealEstateOpportunity | null>(null)

  useEffect(() => {
    if (orgId)
      getOpportunityByFirmId(orgId)
        .then((data) => {
          setReOpportunity(data)
        })
        .catch((err) => console.error(err))
  }, [orgId])

  return (
    <div className="body_layout">
      <div className="sub_header">
        <div
          className="back_icon"
          role="button"
          tabIndex={0}
          onClick={() => navigateTo(-1)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateTo(-1)
            }
          }}
        >
          <BackArrow />
          <h1>Back</h1>
        </div>
      </div>

      <div className="wvp-p-flex-d">
        <div className="left-top-position">
          <div className="left-info"></div>

          <div className="deck-slideshow">
            <div className="re-company-deck">
              {ReOpportunity ? (
                <ExpandedREOpportunity opportunity={ReOpportunity} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
