import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { RealEstateSponsor } from '../../../domain/types/RealEstate'
import { cleanObject } from '../../../presentation/common/utils'

const reSponserCollectionService =
  new FirestoreCollectionApi<RealEstateSponsor>('reSponser')

// to change
export function createCompany(data: RealEstateSponsor) {
  return reSponserCollectionService.addDoc(data)
}

export function getAllReSponsers(): Promise<RealEstateSponsor[]> {
  return reSponserCollectionService.getAllDocs()
}

export function getReSponsersCount(): Promise<number> {
  return reSponserCollectionService.getDocsCount()
}

export function updateResponsor(data: RealEstateSponsor): Promise<void> {
  const cleanData = { ...cleanObject(data) }
  return reSponserCollectionService.updateDocByKey(data.id, cleanData)
}

export function getReSponsorById(id: string): Promise<RealEstateSponsor> {
  return reSponserCollectionService.getDocByKey(id)
}
