import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { Company, CompanyMember } from '../../../domain/types/Company'
import { cleanObject } from '../../../presentation/common/utils'
import { DocumentData } from 'firebase/firestore'

const companiesCollectionService = new FirestoreCollectionApi<Company>(
  'companies',
)

const aiPitchDeckCollectionService = new FirestoreCollectionApi<Company>(
  'ai_pitch_decks',
)
const company_memberService = (companyId) =>
  companiesCollectionService.getSubcollectionApi<CompanyMember>(
    companyId,
    `members`,
  )
export function createCompany(data: Company): Promise<DocumentData> {
  return companiesCollectionService.addDoc(data)
}

export function updateCompany(data: Company): Promise<void> {
  const cleanData = { ...cleanObject(data) }

  return companiesCollectionService.updateDocByKey(data.id, cleanData)
}

export function getAllCompanies(): Promise<Company[]> {
  return companiesCollectionService.getAllDocs()
}
export function getCompaniesCount(): Promise<number> {
  return companiesCollectionService.getDocsCount()
}

export function getCompanyById(id: string): Promise<Company> {
  return companiesCollectionService.getDocByKey(id)
}

export function getCompanyMembers(companyId): Promise<CompanyMember[]> {
  return company_memberService(companyId).getAllDocs()
}

export function createCompanyMember(
  companyId: string,
  data: Omit<CompanyMember, 'db_ref_id'>,
): Promise<DocumentData> {
  return company_memberService(companyId).addDoc(data)
}

export async function convertPdfToJpg(
  fileUrl: string,
  dirNamesList: string[],
): Promise<string[] | null> {
  const url =
    'https://us-central1-wealthvp-webapp-qa.cloudfunctions.net/pdfApi/pdfToJpg'

  const payload = {
    dirNames: dirNamesList.join(','),
    fileUrl: fileUrl, // Replace with your actual payload data
  }

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`)
    }

    const data: {
      imageFiles: string[] // Ensure consistency in property name
      pdfFile?: string // Make `pdfFile` optional
      savedPath: string
    } = await res.json()

    // Validate response structure and handle potential errors
    if (!Array.isArray(data.imageFiles) || typeof data.savedPath !== 'string') {
      console.error('Invalid response structure from conversion service.')
      return null
    }

    return data.imageFiles.length > 0 ? data.imageFiles : []
  } catch (error) {
    console.error('Error converting PDF to JPG:', error)
    return null
  }
}

export const uploadPdfToApi = async (
  file: File,
  companyId: string,
  environment: 'qa' | 'prod' = 'prod',
) => {
  const apiUrl = 'https://tools.wealthvp.com/api/convert/pdf/to-png'
  const apiKey = '9b726329-386c-4cb1-af3b-3f969086fcaf'

  // Create FormData object and append the necessary fields
  const formData = new FormData()
  formData.append('pdfFile', file)
  formData.append('firmId', companyId)
  formData.append('environment', environment)
  formData.append('extractData', 'true')

  try {
    // Make the POST request
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        apiKey: apiKey,
      },
      body: formData,
    })

    // Handle the response
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`)
    }

    const result = await response.json()
    aiPitchDeckCollectionService.addDoc({
      companyId: companyId,
      extractedData: result.extractedData,
      extractedText: result.extractedText,
    })
    return result
  } catch (error) {
    return null
  }
}
