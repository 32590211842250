import * as React from 'react'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom'
import { FOOTER_LOGO, WVP_LOGO_NEW } from '../common/asset_paths'
import { getMenuLinkText } from '../common/constants'

import { UnorderedListOutlined, UserOutlined } from '@ant-design/icons'
import { Popover, Badge, Avatar, Space, Dropdown, Button } from 'antd'
import useProfileStore from '../../data/appState/profileStore'
import { OrgType, Profile } from '../../domain/types/Profile'
import { signOutUser } from '../../data/services/auth/authService'
import { useLoadSiteData } from '../../data/services/common/siteDataService'
import { onMyConversationsUpdate } from '../../data/services/conversation/conversationService'
import {
  getProfileConversationsByProfileId,
  updateNotificationViewedOn,
} from '../../data/services/profile/profileService'
import NotificationsList from '../components/NotificationList'
import {
  getNotificationsByUserId,
  updateNotificationsReadStatusById,
} from '../../data/services/notifications/notificationService'
import { AppNotification } from '../../domain/types/Notification'
import {
  AccountIcon,
  ArrowMenu,
  ExploreIcon,
  InstagramIcon,
  MenuBellIcon,
  MessagesIcon,
  NetworkIcon,
  SettingIcon,
  SignoutIcon,
  UserIcon,
  XIcon,
} from '../common/utilComponents'
import { DownloadPrompt } from '../components/DownloadPrompt'
import {
  saveFCMTokenToUser,
  UpdateUserPushMessageToken,
} from '../../../api/features/profile'
import { ORG_TYPES } from '../utils/constants'
declare global {
  interface Window {
    MSStream?: unknown
  }
  interface Window {
    opera?: unknown
  }
  interface Window {
    gtag?: (
      command: string,
      eventName: string,
      eventParams?: Record<string, unknown>,
    ) => void
  }
}

export function PortalLayout() {
  const {
    isAuthenticated,
    profile,
    orgConnections,
    unReadConvoIds,
    setOrgConnections,
    setUnReadConvoIds,
  } = useProfileStore()

  const location = useLocation()
  const navigateTo = useNavigate()

  const lsIsWebView = localStorage.getItem('iswebview')

  const isWebView = lsIsWebView?.toString() === 'true'

  const [activemenu, setActivemenu] = useState('explore')
  // eslint-disable-next-line
  const [convoState, setConvoState] = useState<any>(null)
  const [mobileHeaderTitle, setMobileHeaderTitle] = useState('')

  const [showNotification, setShowNotification] = useState(false)
  const [notifications, setNotifications] = useState<AppNotification[]>([])
  const [open, setOpen] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)
  const bottomNavHidden = /^\/chat\/type\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.test(
    location.pathname,
  )

  const profileOrgType = profile?.orgType as OrgType
  const isAdmin = profileOrgType === 'ADMIN'

  //const [isNotified, setIsNotified] = useState(false);

  const hide = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openDropdown &&
        !event.target.closest('.dropdown-content') &&
        !event.target.closest('.dropdown-button')
      ) {
        setOpenDropdown(false)
      }
    }

    if (openDropdown) {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [openDropdown])

  const handleButtonClick = (event) => {
    event.stopPropagation()
    setOpenDropdown((prev) => !prev)
  }

  const onNotificationClick = async (isNotificationsVisible) => {
    setOpen(isNotificationsVisible)
    // setIsNotified(true);
    if (isNotificationsVisible && !profile?.db_ref_id) return
    try {
      if (profile?.db_ref_id) {
        await updateNotificationViewedOn(profile.db_ref_id)
      }

      const unReadNotifs = notifications.filter((n) => n.status === 'UNREAD')

      if (unReadNotifs.length > 0 && profile?.uid) {
        await updateNotificationsReadStatusById(
          profile?.uid,
          unReadNotifs.map((n) => n.db_ref_id),
        )

        setTimeout(() => {
          setNotifications(
            notifications.map((n) => {
              n.status = 'READ'
              return n
            }),
          )
        }, 1800)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadNotifications = React.useCallback(async () => {
    const profileId = profile?.uid

    if (profileId)
      getNotificationsByUserId(profileId).then((res) => {
        if (res.length > 0) {
          res = res
            // eslint-disable-next-line
            .map((notification: any) => {
              notification.timestamp = notification.timestamp
                ? notification.timestamp.toDate()
                : new Date()

              return notification as AppNotification
            })
            .sort((a: AppNotification, b: AppNotification) => {
              return !b.timestamp || !a.timestamp
                ? -1
                : b.timestamp.valueOf() - a.timestamp.valueOf()
            })

          setNotifications(res)
        }
      })
  }, [profile?.uid])

  useEffect(() => {
    setMobileHeaderTitle(getMenuLinkText(location.pathname))
    window.scrollTo(0, 0)
  }, [location])

  const { loaded } = useLoadSiteData()
  useEffect(() => {
    if (isAuthenticated) {
      if (!profile) {
        navigateTo('/login')
      } else {
        if (
          !profile.hasCompletedWalkThrough &&
          profile.orgType === ORG_TYPES.COMPANY
        ) {
          navigateTo('/next-steps')
          return
        }
        loadNotifications()

        if (profile?.organisationId) {
          onMyConversationsUpdate(profile, (res) => {
            setOrgConnections(res)
          })
        }

        if (window.gtag && profile?.uid) {
          window.gtag('js', new Date().toISOString())
          window.gtag('config', 'G-90VEK60GXP', { user_id: profile.uid })
        }
      }
    }
  }, [
    isAuthenticated,
    profile,
    loadNotifications,
    navigateTo,
    setOrgConnections,
  ])

  const setFCMToken = async (uid: string) => {
    const localStorageToken = window['fcmtokem']

    if (localStorageToken) {
      await saveFCMTokenToUser(uid, localStorageToken)
    } else {
      try {
        await UpdateUserPushMessageToken(uid)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    if (profile?.db_ref_id) {
      try {
        getProfileConversationsByProfileId(profile?.db_ref_id).then((s) => {
          setConvoState(s)
        })
      } catch (error) {
        console.warn(error)
      }
    }

    if (profile?.uid) {
      setFCMToken(profile?.uid)
    }
  }, [profile?.db_ref_id, profile?.uid])

  useEffect(() => {
    const unreadConvos: string[] = []

    if (orgConnections?.length) {
      for (const connection of orgConnections) {
        const { lastMessage } = connection
        if (lastMessage?.createdById !== profile?.uid) {
          let thisconvoState: { lastVisited: Date } | null = null
          if (convoState?.find) {
            thisconvoState = convoState?.find(
              (c) => c.db_ref_id === connection.db_ref_id,
            )
          }

          if (
            lastMessage &&
            (!thisconvoState ||
              (thisconvoState &&
                thisconvoState?.lastVisited < lastMessage?.createdOn))
          ) {
            unreadConvos.push(connection.db_ref_id)
          }
        }
      }
    }

    setUnReadConvoIds(unreadConvos)
  }, [convoState, orgConnections, profile?.uid, setUnReadConvoIds])

  const { displayName, orgType, photoURL } = profile || ({} as Profile)

  const navigateToOrg = () => {
    navigateTo(`${orgType?.toLowerCase()}`)
    setOpenDropdown(false)
  }

  const profileIconMenu = (
    <div className="profile-card">
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          setOpenDropdown(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setOpenDropdown(false)
          }
        }}
      >
        <div className="profile-header" key={'profile'}>
          {!photoURL ? (
            <Avatar icon={<UserOutlined />} className="navbar-img" />
          ) : (
            <Avatar
              src={<img src={photoURL} alt="avatar" />}
              className="profile-picture"
            />
          )}
          <div className="profile-info">
            <h2> {displayName}</h2>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="profile-menu">
        <div
          className="menu-item"
          key={'orgnization'}
          role="button"
          tabIndex={0}
          onClick={navigateToOrg}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateToOrg()
            }
          }}
        >
          <div>
            <UserIcon className="icon" />
            <span>
              {orgType?.charAt(0).toUpperCase()}
              {orgType?.slice(1).toLowerCase()} Profile
            </span>
          </div>
        </div>

        <div
          className="menu-item"
          key={'orgnization'}
          role="button"
          tabIndex={0}
          onClick={() => {
            navigateTo('profile')
            setOpenDropdown(false)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateTo('profile')
              setOpenDropdown(false)
            }
          }}
        >
          <div>
            <SettingIcon className="icon" />
            <span>Account Settings</span>
          </div>
        </div>
        {!isAdmin && profile?.orgType?.toLowerCase() === 'investor' ? (
          <div
            className="menu-item"
            key={'investor'}
            role="button"
            tabIndex={0}
            onClick={() => {
              navigateTo('investor-invites')
              setOpenDropdown(false)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigateTo('investor-invites')
                setOpenDropdown(false)
              }
            }}
          >
            <div>
              <UnorderedListOutlined className="icon" />
              <span>Invite investors</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {!isAdmin && profile?.orgType?.toLowerCase() === 'realestate' ? (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigateTo('opportunities')
                setOpenDropdown(false)
              }
            }}
            className="menu-item"
            key={'REALESTATE'}
            onClick={() => {
              navigateTo('opportunities')
              setOpenDropdown(false)
            }}
          >
            <div>
              <UnorderedListOutlined className="icon" />
              <span>My Listings</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="menu-item" key={'logout'}>
          <div
            role="button"
            tabIndex={0}
            onClick={signOutUser}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') signOutUser()
            }}
          >
            <SignoutIcon className="icon" />
            <span>Sign Out</span>
          </div>
        </div>
      </div>
    </div>
  )

  const notificationcontent = () => {
    return (
      <>
        <div className="nav_list_notifications">
          <NotificationsList
            maxNotifications={4}
            notifications={notifications}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            paddingTop: '2rem',
          }}
        >
          {
            <Button
              type="default"
              onClick={() => {
                hide()
                setShowNotification(true)
              }}
            >
              See all
            </Button>
          }
        </div>
      </>
    )
  }

  const unreadNotificationCount = notifications.filter(
    (n) => n.status === 'UNREAD',
  ).length
  const isOnboarding = location.pathname === '/next-steps'
  return (
    <div className="portallayout">
      {!isOnboarding && (
        <div className="navbar">
          <nav className="navbar-header">
            <div className="navbar-container">
              <div className="mobile_header_title">
                <h1>{mobileHeaderTitle}</h1>
              </div>
              <Link to={'/explore'} className="navbar-logo">
                <img src={WVP_LOGO_NEW} alt="Logo" />
              </Link>

              <ul className="navbar-menu">
                {!isAdmin ? (
                  <></>
                ) : (
                  <li className="navbar-item">
                    <Link
                      className={`navbar-link ${location.pathname.indexOf('admin') !== -1 ? 'active' : ''}`}
                      to={'/admin'}
                    >
                      Admin
                    </Link>
                  </li>
                )}

                <li className="navbar-item">
                  <Link
                    className={`navbar-link ${location.pathname.indexOf('explore') !== -1 ? 'active' : ''}`}
                    to={'/explore'}
                  >
                    Explore
                  </Link>
                </li>
                {isAdmin ? (
                  <></>
                ) : (
                  <>
                    <li className="navbar-item">
                      <Link
                        className={`navbar-link ${location.pathname.indexOf('chat') !== -1 ? 'active' : ''}`}
                        to={'/chat/type/primary'}
                      >
                        Messages
                      </Link>{' '}
                      {unReadConvoIds.length ? (
                        <span className="badge_count">
                          {unReadConvoIds.length}
                        </span>
                      ) : null}{' '}
                    </li>
                    <li className="navbar-item">
                      <Link
                        className={`navbar-link ${location.pathname.indexOf('mynetwork') !== -1 ? 'active' : ''}`}
                        to={'/mynetwork'}
                      >
                        My Network
                      </Link>
                    </li>
                  </>
                )}
              </ul>
              <ul className="navbar-profile">
                <li className="navbar-item">
                  <div className="bagre-bell">
                    <Popover
                      content={notificationcontent}
                      trigger="click"
                      open={open}
                      onOpenChange={onNotificationClick}
                      arrow
                    >
                      <Badge overflowCount={999} size="default">
                        <MenuBellIcon />{' '}
                        {unreadNotificationCount ? (
                          <span className="badge_count">
                            {unreadNotificationCount}
                          </span>
                        ) : (
                          <> </>
                        )}
                      </Badge>
                    </Popover>
                  </div>
                </li>
                <li className="navbar-item">
                  <Dropdown
                    overlay={profileIconMenu}
                    placement="bottom"
                    open={openDropdown}
                    // onOpenChange={setOpenDropdown}
                  >
                    <Space onClick={handleButtonClick}>
                      {!photoURL ? (
                        <Avatar
                          size={40}
                          icon={<UserOutlined />}
                          className="navbar-img"
                        />
                      ) : (
                        <Avatar
                          size={40}
                          src={<img src={photoURL} alt="avatar" />}
                          className="navbar-img"
                        />
                      )}
                      <ArrowMenu />
                    </Space>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}

      {loaded ? <Outlet></Outlet> : <></>}

      <footer className="footer">
        <div className="footer-left">
          <div className="footer-logo">
            <img src={FOOTER_LOGO} alt="" />
          </div>
          <div className="footer-links">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.wealthvp.com/privacy-statement"
            >
              Privacy Policy
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://cdn.prod.website-files.com/6639818d2d30ae6a87243cce/665758f0e4e15df709a1fb76_WealthVP%20Terms%20of%20Use.pdf"
            >
              Terms of Use
            </a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-menu">
            <a href="#!">Companies</a>
            <a href="#!">Investors</a>
            <a href="#!">Real Estate</a>
          </div>
          <div className="footer-contact">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://share.hsforms.com/1DlJ6huT7QYuyIc7mrKq2Vw4303u"
            >
              {' '}
              Contact Us
            </a>
            <div className="footer_social_link">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/wealthvp/"
                className="linkedin"
              >
                <InstagramIcon />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://x.com/WealthVP"
                className="x"
              >
                <XIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>

      {bottomNavHidden ? (
        ' '
      ) : (
        <>
          <div className="m-menu">
            <footer className="m-menu_item">
              <li className={activemenu === 'explore' ? 'active' : ''}>
                <Button
                  type="link"
                  onClick={() => {
                    navigateTo('/explore')
                    setActivemenu('explore')
                  }}
                  className="p-0 bg-transparent border-none shadow-none text-inherit hover:underline"
                >
                  <ExploreIcon className="meun_icon" />
                  <div className="menu_title">Explore</div>
                </Button>
              </li>
              <li className={activemenu === 'mynetwork' ? 'active' : ''}>
                <Button
                  type="link"
                  onClick={() => {
                    navigateTo('/mynetwork')
                    setActivemenu('mynetwork')
                  }}
                  className="p-0 bg-transparent border-none shadow-none text-inherit hover:underline"
                >
                  <NetworkIcon className="meun_icon" />
                  <div className="menu_title">Network</div>
                </Button>
              </li>
              <li className={activemenu === 'chats' ? 'active' : ''}>
                <Button
                  type="link"
                  onClick={() => {
                    navigateTo('/chat')
                    setActivemenu('chats')
                  }}
                  className="p-0 bg-transparent border-none shadow-none text-inherit hover:underline"
                >
                  {unReadConvoIds.length ? (
                    <span className="badge_count">{unReadConvoIds.length}</span>
                  ) : (
                    <></>
                  )}
                  <MessagesIcon className="meun_icon" />
                  <div className="menu_title">Messages</div>
                </Button>
              </li>
              <li className={activemenu === 'profile' ? 'active' : ''}>
                <Button
                  type="link"
                  className="p-0 bg-transparent border-none shadow-none text-inherit hover:underline"
                  onClick={() => {
                    navigateTo('/profile')
                    setActivemenu('profile')
                  }}
                >
                  <AccountIcon className="meun_icon" />
                  <div className="menu_title">Account</div>
                </Button>
              </li>
            </footer>
          </div>
        </>
      )}

      {!showNotification ? (
        <> </>
      ) : (
        <div className="right-side-popup">
          <div className="header">
            <h3>Notifications</h3>
            <button
              onClick={() => setShowNotification(false)}
              className="close-btn"
            >
              &times;
            </button>
          </div>

          <div className="notifications-content slim_scrollbar">
            <NotificationsList notifications={notifications} />
          </div>
        </div>
      )}

      {isWebView || profile == null ? (
        <></>
      ) : (
        <DownloadPrompt profile={profile}></DownloadPrompt>
      )}
    </div>
  )
}
