import React from 'react'
import {
  Testimonial,
  TestimonialProps,
} from '../../../v1/presentation/components/Testomonial/Testomonial'
import { useIsMobile } from '../../../v1/presentation/common/useIsMobile'

export const FinancialDetailsSideContent = () => {
  const isMobile = useIsMobile()
  const testimonials: TestimonialProps[] = [
    {
      url: 'https://randomuser.me/api/portraits/men/1.jpg',
      text: 'The exclusivity of the platform is a nice touch, and helps me build trust!',
    },
    {
      url: 'https://randomuser.me/api/portraits/women/2.jpg',
      text: 'Transparency on raised funds and resource use makes it easy to spot strong opportunities.',
    },
    {
      url: 'https://randomuser.me/api/portraits/women/23.jpg',
      text: 'WealthVP’s clear financial data gives me confidence in assessing companies.',
    },
    {
      url: 'https://randomuser.me/api/portraits/men/8.jpg',
      text: 'Accurate finances on WealthVP help me quickly evaluate a startup’s potential and make decisions.',
    },
    {
      url: 'https://randomuser.me/api/portraits/men/20.jpg',
      text: 'I prefer to invest in sustainable products, and WealthVP allows me to find exactly what I need.',
    },
  ]

  return !isMobile ? (
    <div className="flex flex-col gap-12" style={{ margin: '0 auto' }}>
      {testimonials.map((testimonial, index) => (
        <Testimonial
          key={index}
          url={testimonial.url}
          text={testimonial.text}
        />
      ))}
    </div>
  ) : (
    <div
      className="mt-4 flex gap-6 overflow-hidden flex-col p-2"
      style={{
        flexWrap: 'nowrap', // Prevent wrapping
        width: '100vw',
      }}
    >
      <div className="flex flex-row gap-8 ml-4">
        <div
          className="flex-shrink-0"
          style={{
            minWidth: '300px', // Ensure testimonials maintain a consistent width
            maxWidth: '300px', // Prevent stretching
          }}
        >
          <Testimonial url={testimonials[0].url} text={testimonials[0].text} />
        </div>
        <div
          className="flex-shrink-0"
          style={{
            minWidth: '300px', // Ensure testimonials maintain a consistent width
            maxWidth: '300px', // Prevent stretching
          }}
        >
          <Testimonial url={testimonials[0].url} text={testimonials[0].text} />
        </div>
      </div>
      <div className="flex flex-row gap-8 -ml-40">
        <div
          className="flex-shrink-0"
          style={{
            minWidth: '300px', // Ensure testimonials maintain a consistent width
            maxWidth: '300px', // Prevent stretching
          }}
        >
          <Testimonial url={testimonials[0].url} text={testimonials[0].text} />
        </div>
        <div
          className="flex-shrink-0"
          style={{
            minWidth: '300px', // Ensure testimonials maintain a consistent width
            maxWidth: '300px', // Prevent stretching
          }}
        >
          <Testimonial url={testimonials[0].url} text={testimonials[0].text} />
        </div>
      </div>
    </div>
  )
}
