import { Card, Col, Row } from 'antd'
export interface IReportKeyValueProps {
  reportData: { [key: string]: string | number | null }[]
}

export function ReportKeyValue(props: IReportKeyValueProps) {
  if (!props.reportData || props.reportData.length === 0) {
    return <div> No Data </div>
  }

  const ObjView = (ObjectValue) => {
    return (
      <>
        {Object.keys(ObjectValue).map((key, index) => {
          const value = ObjectValue[key] ? ObjectValue[key] : 'No Value'
          const label = key ? key.toString().split('_').join(' ') : 'No Label'

          return (
            <Col span={12} key={`${label}-${index}`}>
              <div className="gap-5">
                <Card size="small" bordered={false}>
                  <div style={{ textAlign: 'center' }}>
                    <h1>{value} </h1>
                    <h4> {label}</h4>
                  </div>
                </Card>
              </div>
            </Col>
          )
        })}
      </>
    )
  }

  return (
    <div>
      <Row>
        {props.reportData.map(
          (data: { [key: string]: string | number | null }) => {
            return ObjView(data)
          },
        )}
      </Row>
    </div>
  )
}
