import { Form, Input } from 'antd'
import React from 'react'
import { UploadAvatar } from '../../v1/presentation/components/UploadAvatar/UploadAvatarNew'
import { FormInstance } from 'antd/es/form/Form'
import { AccountInfo } from './CompanyOnboarding'

interface PersonalDetailsSectionProps {
  form: FormInstance<AccountInfo>
  onChange: (url: string) => void
}

export const PersonalDetailsSection: React.FC<PersonalDetailsSectionProps> = ({
  onChange,
}) => {
  return (
    <div className="flex flex-col items-center px-4 md:px-0">
      <div className="mt-4">
        <div className="flex flex-col items-center">
          <Form.Item
            name="photoURL"
            label="Upload Avatar"
            valuePropName="value"
            rules={[{ required: true, message: 'Please upload a file!' }]}
            labelCol={{ style: { textAlign: 'center', fontSize: '18px' } }}
            preserve={true}
          >
            <UploadAvatar onChange={(url) => url && onChange(url)} />
          </Form.Item>
        </div>
      </div>

      <div className="mt-4 w-full">
        {/* Adjust grid layout for closer alignment */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-4">
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: 'Please input your first name!' },
            ]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: 'Please input your last name!' },
            ]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Please input your title!' }]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Personal Social Link"
            name="personalSocialLink"
            rules={[
              {
                required: true,
                message: 'Please input your personal social link!',
              },
              {
                type: 'url',
                message: 'Please enter a valid URL',
              },
            ]}
            className="w-full"
            preserve={true}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
    </div>
  )
}
