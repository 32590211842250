import { CameraOutlined, PictureOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { fireStorage as storage } from '../../../data/db/FireStoreApi'

interface UploadAvatarProps {
  value?: string | null // Controlled value (URL or null)
  onChange: (value: string | null) => void // Triggered when value changes
}

export const UploadAvatar: React.FC<UploadAvatarProps> = ({
  value,
  onChange,
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleUpload = (file: File) => {
    const storageRef = ref(storage, `/userUploads/${Date.now()}--${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    setIsUploading(true)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(Math.round(percent))
      },
      (error) => {
        console.error('Upload failed:', error)
        setIsUploading(false)
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
          setIsUploading(false)
          setProgress(0)

          if (onChange) {
            onChange(downloadURL) // Pass the uploaded image URL to parent
          }
        } catch (error) {
          console.error('Error getting download URL:', error)
          setIsUploading(false)
        }
      },
    )
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      handleUpload(file)
    }
  }

  return (
    <div className="relative">
      {/* Avatar Preview */}
      <div
        className="h-[10rem] w-[10rem] rounded-full overflow-hidden flex items-center justify-center bg-light-black"
        style={{
          backgroundImage: value ? `url("${value}")` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {!value && !isUploading && (
          <PictureOutlined style={{ fontSize: '48px', color: '#b0b0b0' }} />
        )}
        {isUploading && (
          <div className="text-white">
            <p>Uploading... {progress}%</p>
          </div>
        )}
      </div>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="absolute h-[2.5rem] w-[2.5rem] bg-white rounded-full flex items-center justify-center bottom-0 right-0 cursor-pointer border-2 border-white">
        <CameraOutlined className="text-black" />
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </label>
    </div>
  )
}
