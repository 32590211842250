import * as React from 'react'

import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import { KeyOutlined } from '@ant-design/icons'
import { ProcessState } from '../common/types'
import { useState } from 'react'
import toast from '../common/Toast'
import { useNavigate } from 'react-router-dom'

const auth = getAuth()

export function MimicGate() {
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.Idle,
  )

  const navigateTo = useNavigate()
  const mimicByUkey = async (gateKey: string, ukey: string) => {
    try {
      const response = await fetch(
        `https://us-central1-wealthvp-webapp.cloudfunctions.net/mimicGateApi/getMimicToken/${gateKey}/${ukey}`,
      )
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`)
      }

      const responseText: string = await response.text()

      signInWithCustomToken(auth, responseText)
        .then(() => {
          // Signed in
          setTimeout(() => {
            navigateTo('/explore')
          }, 1000)

          // ...
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message

          console.warn('Error impersonating user:', errorCode, errorMessage)
          // ...
        })
    } catch (error) {
      console.error('Error impersonating user:', error)
    } finally {
      setProcessState(ProcessState.Idle)
    }
  }

  const handleSubmit = async (values) => {
    if (!values.gateKey) {
      toast.error('Please enter valid Gate Key')
      return
    }

    if (!values.uKey) {
      toast.error('Please enter valid U Key')
      return
    }
    setProcessState(ProcessState.Loading)
    if (values.gateKey && values.uKey) {
      mimicByUkey(values.gateKey, values.uKey)
    }
  }
  return (
    <div className="form_input">
      <Space direction="vertical" size={12}>
        <div></div>
      </Space>

      <Row>
        <Col span={8}></Col>
        <Col span={8}>
          <Form onFinish={handleSubmit}>
            <Form.Item
              name="gateKey"
              rules={[
                {
                  required: false,
                  message: 'Please enter valid Gate Key',
                },
              ]}
            >
              <Input
                size="large"
                prefix={<KeyOutlined className="site-form-item-icon" />}
                placeholder="Gate Key"
              />
            </Form.Item>

            <Space direction="vertical" size={12}>
              <div></div>
            </Space>
            <Form.Item
              name="uKey"
              rules={[{ required: false, message: 'Please U Key' }]}
            >
              <Input
                size="large"
                prefix={<KeyOutlined className="site-form-item-icon" />}
                placeholder="U Key"
              />
            </Form.Item>

            <Space direction="vertical" size={12}>
              <div></div>
            </Space>

            <Form.Item>
              <Button
                className="btn_primary"
                type="primary"
                htmlType="submit"
                disabled={processState === ProcessState.Loading}
              >
                Mimic
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}></Col>
      </Row>
    </div>
  )
}
