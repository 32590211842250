import React from 'react'
import { Step } from './Step/Step'

interface StepperProps {
  steps: {
    title: string
    subtitle: string
  }[]
  activeStep?: number
  onStepChange: (stepIndex: number) => void
}
export const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStep,
  onStepChange,
}) => {
  return (
    <div className={`grid grid-cols-4 gap-4`}>
      {steps.map((step, index) => (
        <Step
          key={index}
          title={step.title}
          subtitle={step.subtitle}
          isActive={activeStep === index}
          onClick={() => onStepChange(index)}
          canClick={(activeStep ?? 0) > index}
        />
      ))}
    </div>
  )
}
