import * as React from 'react'
import { TeamMember } from '../../domain/types/Company'
import { BLANK_IMG } from '../common/asset_paths'
import { DeleteIcons } from '../common/utilComponents'
import { SocialIcon } from 'react-social-icons'
import { Button } from 'antd'

export interface ITeamMembersListProps {
  teamMembers: TeamMember[]
  onDeleteMember: (index: number) => void
}

export function TeamMembersList(props: ITeamMembersListProps) {
  const { teamMembers } = props
  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`
    } else {
      return url
    }
  }
  return (
    <div className="team_view">
      {teamMembers && teamMembers?.length !== 0 ? (
        teamMembers?.map((tm: TeamMember, index: number) => {
          return (
            <div className="team_card" key={`${tm.photoUrl}-${index}`}>
              <div className="team_profile_img">
                {tm.photoUrl ? (
                  <img alt="" src={tm.photoUrl} />
                ) : (
                  <img alt="" src={BLANK_IMG} />
                )}
              </div>
              <div>
                <div className="teammember-list">
                  <h2>{tm.displayName}</h2>

                  <h3 className="">{tm.title}</h3>

                  {tm.description ? <p>{tm?.description}</p> : <></>}
                  {tm?.socialLink ? (
                    <div key={index} className="social_info">
                      <SocialIcon
                        target="_blank"
                        url={getUrl(tm?.socialLink)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="fixed_top">
                <Button
                  type="link"
                  className="trash_icon"
                  onClick={() => props.onDeleteMember(index)}
                >
                  <DeleteIcons />
                </Button>
              </div>
            </div>
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}
