import { create } from 'zustand'
import { SiteDataOption } from '../services/common/siteDataService'

interface ExporeState {
  selectedOptions: SiteDataOption[]
  addSelectedOption: (option: SiteDataOption) => void
  removeSelectedOption: (option: SiteDataOption) => void
  clearSelectedOptions: () => void
}

const useExploreStore = create<ExporeState>()((set) => ({
  selectedOptions: [],

  setSelectedOptions: (selectedOptions) => {
    set(() => ({ selectedOptions }))
  },

  addSelectedOption: (selectedOption) => {
    set((state) => ({
      selectedOptions: [...state.selectedOptions, selectedOption],
    }))
  },
  removeSelectedOption: (selectedOption) => {
    set((state) => ({
      selectedOptions: state.selectedOptions.filter(
        (option) => JSON.stringify(option) !== JSON.stringify(selectedOption),
      ),
    }))
  },
  clearSelectedOptions: () => {
    set(() => ({ selectedOptions: [] }))
  },
}))

export default useExploreStore
