import * as React from 'react'
import { getInvites } from '../../../data/services/Invites/InviteService'
import { Invite } from '../../../domain/types/Invite'
import { Button, Input, Tooltip } from 'antd'
import useProfileStore from '../../../data/appState/profileStore'
import { convertTime } from '../../../data/services/explore/converters'
import { copyToClipboard } from '../../common/utils'
import { Timestamp } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import Table, { ColumnsType } from 'antd/es/table'

export function Invitees() {
  const [invites, setInvites] = React.useState<Invite[]>([])
  const [oinvites, setOinvites] = React.useState<Invite[]>([])

  const { profile } = useProfileStore()
  const navigateTo = useNavigate()

  const columns: ColumnsType<Invite> = [
    {
      title: 'Email',
      dataIndex: 'createToEmail',
      key: 'createToEmail',
      sorter: (a, b) => a.createToEmail.localeCompare(b.createToEmail),
    },
    {
      title: 'Invited On',
      dataIndex: 'updatedOn',
      key: 'updatedOn',
      render: (updatedOn) => {
        return <div>{convertTime(updatedOn)}</div>
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdByName',
      key: 'createdByName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return status !== 'accepted' ? (
          status
        ) : (
          <div style={{ color: 'green' }}>
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ color: 'orange' }}>
                  {'Accepted By ' +
                    record.acceptedByName +
                    ' (' +
                    record.acceptedByEmail +
                    ') for the organisation ' +
                    record.acceptedByOrgName}
                </span>
              }
              arrow={true}
            >
              <p style={{ color: 'green' }}>Accepted</p>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() =>
            copyToClipboard(
              window.location.origin + '/invitee/signup/' + record.db_ref_id,
            )
          }
        >
          Copy Link
        </Button>
      ),
    },
  ]

  const loadInvites = React.useCallback(() => {
    if (profile && profile.uid)
      getInvites().then((invites) => {
        invites = invites
          .map((invite) => {
            const updatedOn = invite.updatedOn
            if (updatedOn) {
              //TODO Need to fix this in DB Fetch level
              const udVal: Timestamp = invite.updatedOn as unknown as Timestamp
              invite.updatedOn =
                updatedOn instanceof Date ? updatedOn : udVal.toDate()
            }
            return invite
          })
          .sort((a, b) => {
            //need to revisit this
            if (!a.updatedOn?.getTime || !b.updatedOn.getTime) return 0
            return b.updatedOn.getTime() - a.updatedOn.getTime()
          })
        setInvites(invites)
        setOinvites(invites)
      })
  }, [profile])

  React.useEffect(() => {
    loadInvites()
  }, [loadInvites])

  const onsearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value
    if (currValue.trim()) {
      const filteredData = invites.filter((entry) =>
        entry?.createToEmail?.toLowerCase().includes(currValue.toLowerCase()),
      )
      setInvites(filteredData)
    } else {
      setInvites(oinvites)
    }
  }

  return (
    <div className="body_layout">
      <div className="flex justify-end gap-4 header_view">
        <Input
          placeholder="Search Email"
          className="!w-2/5"
          onChange={(e) => onsearch(e)}
        />
        <Button onClick={() => navigateTo('/admin/createAccount')}>
          New Account
        </Button>
      </div>

      {invites && invites.length !== 0 ? (
        <div className="table-container slim_scrollbar invite_table ">
          <Table
            columns={columns}
            dataSource={invites}
            rowKey="db_ref_id"
            className="ant-pagination"
          />
        </div>
      ) : (
        <p className="text-center">
          {' '}
          <b>No invitees</b>
        </p>
      )}
    </div>
  )
}
