import * as React from 'react'
import { Outlet } from 'react-router-dom'

export function PublicLayout() {
  return (
    <div className="publiclayout">
      <Outlet></Outlet>
    </div>
  )
}
