import { AppNotification } from '../../domain/types/Notification'
import { displayDateFormatter } from '../../data/services/explore/converters'
import { Link } from 'react-router-dom'
import { NotificationsBellIcon } from '../common/utilComponents'
// need to change
export interface INotificationListProps {
  maxNotifications?: number
  notifications?: AppNotification[]
  onClick?: () => void
}

export default function NotificationsList({
  maxNotifications,
  notifications,
}: INotificationListProps) {
  return (
    <div className="notifications-popup">
      {!notifications?.length ? (
        <>
          {' '}
          <div>No Notifications</div>
        </>
      ) : (
        notifications?.map((notification, i) => {
          let targetUrl = '/chat'

          if (notification.primaryAction?.url) {
            targetUrl = notification.primaryAction?.url
          }

          if (targetUrl.includes('deal')) {
            targetUrl = '/chat'
          }

          if (maxNotifications && i >= maxNotifications) return <></>

          return (
            <Link to={targetUrl} key={`${notification.id}-${i}`}>
              <div className="notification">
                <div className="content">
                  <div className="icon">
                    <div className="bell-icon">
                      <NotificationsBellIcon />
                    </div>
                    <strong>{notification.title} </strong>
                    {notification.isNew ? (
                      <span className="green_dot"></span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <p>{notification.message}</p>
                  <small>
                    {displayDateFormatter(
                      notification.timestamp,
                      'MMM DD, YYYY h:mm A',
                    )}
                  </small>
                </div>
              </div>
            </Link>
          )
        })
      )}
    </div>
  )
}
