// src/services/ApiService.ts

export class ApiService {
  private baseUrl: string
  private apiKey: string

  constructor(baseUrl: string, apiKey: string) {
    this.baseUrl = baseUrl
    this.apiKey = apiKey
  }

  private getFullUrl(endpoint: string): string {
    return `${this.baseUrl}/${endpoint}`
  }

  private async request<T>(endpoint: string, options: RequestInit): Promise<T> {
    // Add the apiKey to headers
    const headers = {
      ...options.headers,
      apiKey: this.apiKey, // or use 'x-api-key' depending on your API
    }

    const response = await fetch(this.getFullUrl(endpoint), {
      ...options,
      headers,
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`)
    }
    return response.json()
  }

  public async get<T>(endpoint: string): Promise<T> {
    return this.request<T>(endpoint, { method: 'GET' })
  }

  public async post<T, U>(endpoint: string, body: U): Promise<T> {
    return this.request<T>(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }

  public async put<T, U>(endpoint: string, body: U): Promise<T> {
    return this.request<T>(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }

  public async delete<T>(endpoint: string): Promise<T> {
    return this.request<T>(endpoint, { method: 'DELETE' })
  }
}
