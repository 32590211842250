import { notification } from 'antd'

class Toast {
  info(message: string, description = ''): void {
    notification.info({ message, description })
  }

  error(message: string, description = ''): void {
    notification.error({ message, description })
  }
  success(message: string, description = ''): void {
    notification.success({ message, description })
  }

  warning(message: string, description = ''): void {
    notification.warning({ message, description })
  }
}

const toast = new Toast()

export default toast
