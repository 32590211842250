import { Button, Card, Space } from 'antd'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity'
import { getlabelsByValues } from '../../data/services/explore/converters'
import { realestateSiteProperties } from '../../data/services/common/siteDataService'
import { RealEstateSponsor } from '../../domain/types/RealEstate'
import { useState } from 'react'
import { DEFAULT_FIRM_IMG } from '../common/constants'
// commented build error  for new ui by KD
import ImageGallery from 'react-image-gallery'
import { ArrowLeftOutlined, LinkOutlined } from '@ant-design/icons'
import { convertNumbertoCurrency } from '../common/utils'
import { DEFAULT_IMG } from '../common/asset_paths'

export interface IExpandedREOpportunityProps {
  opportunity: RealEstateOpportunity
  cardStatusAction?: () => JSX.Element
}

export function ExpandedREOpportunity({
  opportunity,
  cardStatusAction,
}: IExpandedREOpportunityProps) {
  const {
    investment_type,
    assets_under_management,
    property_sector,
    anticipated_term,
    return_structure,
    investment_strategy,
    investment_structure,
    tax_advantage,
  } = realestateSiteProperties
  const [sponser] = useState<RealEstateSponsor>()
  const { view: viewVal } = useParams()
  const navigateTo = useNavigate()

  const onNavigate = () => {
    if (viewVal) {
      navigateTo(`/explore/realestates`)
    } else {
      navigateTo('/opportunities')
    }
  }

  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`
    } else {
      return url
    }
  }
  const aboutText = opportunity?.description || ''
  return (
    <>
      <div className=" real-estate-slide-preview">
        <div className="profile_hide">
          <div className="side-view-comapny">
            <div className="cvr_title">
              <h2>{opportunity?.name}</h2>
            </div>
            <h3 className="tab_1">Overview</h3>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: aboutText,
              }}
            />
            <div className="cvr_flex_wrapping">
              <div className="logo-company">
                {/* {selectedOpportunity &&
                selectedOpportunity?.pictureUrls &&
                selectedOpportunity.pictureUrls.length !== 0 ? (
                <>
                  <img src={selectedOpportunity?.pictureUrls[0]} />

                </>
              ) : (
                <>
                  <img src={DEFAULT_IMG} />

                </>
              )} */}
                <img src={DEFAULT_IMG} alt="" />
              </div>

              <div className="colounm_two">
                <h3 className="Secondary-textcolor soft_green">
                  Investment Details
                </h3>
                <div className="list_group">
                  <div className="text-group">
                    <p>Sectors</p>
                    <span className="">
                      {getlabelsByValues(
                        opportunity?.sectors,
                        property_sector.options,
                      ).map((item: string, index: number) => (
                        <span key={item}>{(index ? ',' : '') + item}</span>
                      ))}
                    </span>
                  </div>

                  <div className="text-group">
                    <p>Investment Strategy</p>

                    <span>
                      {!opportunity?.investmentStrategy
                        ? ''
                        : getlabelsByValues(
                            [opportunity?.investmentStrategy],
                            investment_strategy.options,
                          )}
                    </span>
                  </div>

                  <div className="text-group">
                    <p>Investment Structure</p>
                    <span>
                      {!opportunity?.investmentStructure
                        ? ''
                        : getlabelsByValues(
                            [opportunity?.investmentStructure],
                            investment_structure.options,
                          )}
                    </span>
                  </div>
                  <div className="text-group">
                    <p>Return Structure</p>
                    <span>
                      {!opportunity?.returnStructure
                        ? ''
                        : getlabelsByValues(
                            [opportunity?.returnStructure],
                            return_structure.options,
                          )}
                    </span>
                  </div>
                  <div className="text-group">
                    <p>Anticipated Term</p>
                    <span>
                      {!opportunity?.anticipatedTerm
                        ? ''
                        : getlabelsByValues(
                            [opportunity?.anticipatedTerm],
                            anticipated_term.options,
                          )}
                    </span>
                  </div>
                  <div className="text-group">
                    <p>Tax Advantage </p>
                    <span>
                      {!opportunity?.investmentType
                        ? ''
                        : getlabelsByValues(
                            [opportunity?.investmentType],
                            tax_advantage.options,
                          )}
                    </span>
                  </div>
                </div>
                <h3 className="Secondary-textcolor soft_green">
                  Real Estate Sectors
                </h3>
                <div className="list_group">
                  <div className="text-group">
                    {opportunity?.city ? (
                      <>
                        <p>Land Development</p>
                        <span>---</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="text-group">
                    {opportunity?.state ? (
                      <>
                        <p>Other</p>
                        <span>---</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <h3 className="Secondary-textcolor soft_green">
                  Opportunity Details
                </h3>
                <div className="list_group">
                  <div className="text-group">
                    {opportunity?.city ? (
                      <>
                        <p>City</p>
                        <span>{opportunity?.city}</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="text-group">
                    {opportunity?.state ? (
                      <>
                        <p>State</p>
                        <span>{opportunity?.state}</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="text-group">
                    {opportunity?.country ? (
                      <>
                        <p>Country</p>
                        <span>{opportunity?.country}</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <span className="text-group">
                    <p>Real Estate Sponsor</p>
                    <span>
                      <a
                        onClick={() =>
                          navigateTo(
                            `/realestate/${opportunity?.sponserId}/view`,
                          )
                        }
                        href={`/realestate/${opportunity?.sponserId}/view`}
                      >
                        {sponser?.name}
                      </a>
                    </span>
                  </span>
                </div>

                <div className="org-status-action">
                  {cardStatusAction ? cardStatusAction() : <></>}
                </div>
              </div>
            </div>

            <ImageGallery
              showBullets={true}
              autoPlay={true}
              showFullscreenButton={false}
              items={
                !opportunity?.pictureUrls
                  ? []
                  : opportunity.pictureUrls?.map((pic) => {
                      return { original: pic, thumbnail: pic }
                    })
              }
            />

            <div className="Tabs-slideshow deck-slideshow">
              <div>
                <div className="opp_unity_details">
                  <h3 className="Secondary-textcolor soft_green">
                    Deal Information
                  </h3>
                  <div className="div_width">
                    <div className="list_group">
                      <span className="text-group">
                        <p>Investment Type</p>
                        <span>
                          {getlabelsByValues(
                            [opportunity?.investmentType],
                            investment_type.options,
                          )}
                        </span>
                      </span>

                      <span className="text-group">
                        <p>Total Deal Size</p>
                        <span>
                          {convertNumbertoCurrency(opportunity?.totalDealSize)}
                        </span>
                      </span>

                      <span className="text-group">
                        <p>Minimum Investment</p>
                        <span>
                          {convertNumbertoCurrency(
                            opportunity?.minimumInvestment,
                          )}
                        </span>
                      </span>

                      <span className="text-group">
                        <p>Anticipated Term</p>
                        <span>
                          {getlabelsByValues(
                            [opportunity?.anticipatedTerm],
                            anticipated_term.options,
                          )}
                        </span>
                      </span>

                      <span className="text-group">
                        <p>Investment Strategy</p>
                        <span>
                          {getlabelsByValues(
                            [opportunity?.investmentStrategy],
                            investment_strategy.options,
                          )}
                        </span>
                      </span>

                      <span className="text-group">
                        <p>Investment Structure</p>
                        <span>
                          {getlabelsByValues(
                            [opportunity?.investmentStructure],
                            investment_structure.options,
                          )}
                        </span>
                      </span>

                      <span className="text-group">
                        <p>Return Structure</p>
                        <span>
                          {getlabelsByValues(
                            [opportunity?.returnStructure],
                            return_structure.options,
                          )}
                        </span>
                      </span>

                      <span className="text-group">
                        <p>Tax Advantage</p>
                        <span>
                          {getlabelsByValues(
                            [opportunity?.taxAdvantage],
                            tax_advantage.options,
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="oppo-view user_profile">
            <div className="wvp-flex-d">
              <ul className="">
                <Space>
                  <ArrowLeftOutlined
                    onClick={() => onNavigate()}
                    style={{ color: '#fff', cursor: 'pointer' }}
                  />
                  <h1 className="cn_title big-title">{opportunity?.name}</h1>
                </Space>
                <ul className="right-navbar">
                  {/* <a className="info_link" target="_blank" >{sponser?.websiteUri}</a> */}
                  <a
                    target="_blank"
                    href={getUrl(sponser?.websiteUri)}
                    rel="noreferrer"
                  >
                    <LinkOutlined />
                  </a>
                  <li>
                    <Button type="primary"> Send Message </Button>
                  </li>
                </ul>
              </ul>
            </div>

            <div className="left-top-position">
              <div className="sponsor-info left-info">
                <Card bordered={false} size="small" style={{ height: '100%' }}>
                  <div className="header-image">
                    <img
                      src={
                        sponser?.logoUri ? sponser?.logoUri : DEFAULT_FIRM_IMG
                      }
                      alt=""
                    />
                    <Button
                      type="text"
                      onClick={() =>
                        navigateTo(`/realestate/${opportunity?.sponserId}/view`)
                      }
                    >
                      <h1>{sponser?.name}</h1>
                    </Button>
                  </div>

                  <div className="text-group">
                    <p>Assets Under Management</p>
                    <span>
                      <span>
                        {!sponser?.assetsUnderManagement
                          ? ''
                          : getlabelsByValues(
                              [sponser?.assetsUnderManagement],
                              assets_under_management.options,
                            )}
                      </span>
                    </span>
                  </div>

                  <div className="text-group">
                    <p>Number of Deals Completed</p>
                    <span>{sponser?.numberOfDealsCompleted}</span>
                  </div>

                  <div className="text-group">
                    <p>Years in Business</p>
                    <span>{sponser?.yearsInIndustry}</span>
                  </div>

                  <div className="text-group">
                    <p>Sectors</p>
                    <span>
                      {getlabelsByValues(
                        sponser?.sectors,
                        property_sector.options,
                      ).join(', ')}
                    </span>
                  </div>

                  <Button
                    style={{ float: 'right', marginTop: '.75rem' }}
                    type="primary"
                    onClick={() =>
                      navigateTo(`/realestate/${sponser?.id}/view`)
                    }
                  >
                    see more
                  </Button>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
