import React from 'react'
import { PitchDeckSideContent } from './PitchDeckSideContent'
import { PersonalDetailsSideContent } from './PersonalDetailsSideContent'
import { CompanyDetailsSideContent } from './CompanyDetailsSideContent'
import { FinancialDetailsSideContent } from './FinancialDetailsSideContent'

interface CompanyOnboardingSideContentProps {
  activeStep: number
}

export const CompanyOnboardingSideContent: React.FC<
  CompanyOnboardingSideContentProps
> = ({ activeStep }) => {
  return (
    <div>
      {activeStep === 0 && <PitchDeckSideContent />}
      {activeStep === 1 && <PersonalDetailsSideContent />}
      {activeStep === 2 && <CompanyDetailsSideContent />}
      {activeStep === 3 && <PitchDeckSideContent />}
      {activeStep === 4 && <FinancialDetailsSideContent />}
    </div>
  )
}
