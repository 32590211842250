import * as React from 'react'
import { Divider, Avatar } from 'antd'
import { InvestmentFirm } from '../../domain/types/Investor'
import { getlabelsByValues } from '../../data/services/explore/converters'
import { investorSiteProperties } from '../../data/services/common/siteDataService'
import { DEFAULT_FIRM_IMG } from '../common/constants'
import SocialIcons from './SocialIconsView'
import useProfileStore from '../../data/appState/profileStore'
import { Profile } from '../../domain/types/Profile'
import { useEffect } from 'react'
import { getProfilesByOrgId } from '../../data/services/profile/profileService'

export interface IExpandedInvestmentFirmViewProps {
  investmentFirm: InvestmentFirm
  cardStatusAction?: () => JSX.Element
  showSocialIcons?: boolean
}

export function ExpandedInvestmentFirmView(
  props: IExpandedInvestmentFirmViewProps,
) {
  const [firmProfile, setFirmProfile] = React.useState<Profile | null>(null)

  const { profile: currentProfile } = useProfileStore()

  const { investmentFirm } = props

  const photoURL = firmProfile?.photoURL ?? ''

  useEffect(() => {
    if (currentProfile?.orgType === 'INVESTOR') {
      setFirmProfile(currentProfile)
    } else if (investmentFirm) {
      const orgId = investmentFirm?.id || investmentFirm?.db_ref_id
      if (orgId)
        getProfilesByOrgId(orgId).then((response) => {
          if (response && response.length > 0) {
            setFirmProfile(response[0])
          }
        })
    }
  }, [currentProfile, investmentFirm])
  const {
    industry_type,
    social_impact,
    investment_round,
    diversity_and_inclusion,
    economic_impact,
    investment_size,
    environmental_impact,
  } = investorSiteProperties
  const { assets_under_management, investor_type } = investorSiteProperties

  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`
    } else {
      return url
    }
  }

  const aboutText = investmentFirm?.about || ''

  return (
    <>
      <div className="side-view-comapny">
        <div className="account-profile">
          <div className="frame">
            <div className="div_content">
              <div className="frame-2">
                <div className="div-wrapper">
                  {photoURL ? (
                    <Avatar
                      shape="circle"
                      alt="Image"
                      icon={<img src={photoURL} alt="" />}
                    />
                  ) : (
                    <>
                      {' '}
                      <Avatar shape="circle">
                        {firmProfile?.firstName?.charAt(0).toUpperCase()}
                        {firmProfile?.lastName?.charAt(0).toUpperCase()}
                      </Avatar>{' '}
                    </>
                  )}
                </div>
                <div className="frame-3">
                  <div className="text-wrapper-2">
                    {' '}
                    {firmProfile?.firstName} {firmProfile?.lastName}
                  </div>
                  <div className="frame-4">
                    <p className="JS-investments">
                      <span className="span">{firmProfile?.title} </span>
                      <span className="span">
                        {firmProfile?.title && investmentFirm?.name
                          ? 'at '
                          : ''}{' '}
                        {investmentFirm?.name}
                      </span>

                      {!investmentFirm?.investorType ? (
                        <></>
                      ) : (
                        getlabelsByValues(
                          [investmentFirm?.investorType],
                          investor_type.options,
                        ).map((label: string, index: number) => (
                          <span className="span" key={`${label}-${index}`}>
                            {' '}
                            | {label}
                          </span>
                        ))
                      )}
                    </p>
                    <div className="text-wrapper-4">
                      {[
                        firmProfile?.city,
                        firmProfile?.state,
                        firmProfile?.country,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-5">
                {firmProfile?.socialLinks?.length !== 0 ? (
                  <SocialIcons iconsList={firmProfile?.socialLinks || []} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="main-container">
          <div className="grid_wrapper">
            {investmentFirm?.investmentRound ? (
              <div className="wrapper">
                <span className="text">Investment Round</span>
                <div className="group">
                  {getlabelsByValues(
                    investmentFirm?.investmentRound,
                    investment_round.options,
                  ).map((label: string, index: number) => (
                    <div key={index} className="box">
                      <span className="text-2"> {label}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
            {investmentFirm?.investmentSize ? (
              <div className="wrapper">
                <span className="text">Investment Size</span>
                <div className="group">
                  {getlabelsByValues(
                    investmentFirm?.investmentSize,
                    investment_size.options,
                  ).map((label: string, index: number) => (
                    <div key={index} className="box">
                      <span className="text-2"> {label}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {investmentFirm?.geographicFocus ? (
              <div className="wrapper">
                <span className="text">Geographic Focus</span>
                <div className="group">
                  <div className="box">
                    <span className="text-2">
                      {investmentFirm?.geographicFocus}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {investmentFirm?.industries ? (
            <div className="box-9">
              <span className="text">Industry Focus</span>
              <div className="group">
                {getlabelsByValues(
                  investmentFirm?.industries,
                  industry_type.options,
                ).map((label: string, index: number) => (
                  <div key={index} className="box">
                    <span className="text-2"> {label}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="container">
            {!investmentFirm?.socialImpact &&
            !investmentFirm?.socialImpact &&
            !investmentFirm?.economicImpact &&
            !investmentFirm?.diversityandInclusion ? (
              ''
            ) : (
              <div className="impact-areas">
                <h3>Impact Areas of Interest</h3>
                <ul>
                  {investmentFirm?.socialImpact ? (
                    <li>
                      <strong>Social Impact:</strong>
                      <>
                        {getlabelsByValues(
                          investmentFirm?.socialImpact,
                          social_impact.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}
                  {investmentFirm?.environmentImpact ? (
                    <li>
                      <strong>Environmental Impact:</strong>{' '}
                      <>
                        {getlabelsByValues(
                          investmentFirm?.environmentImpact,
                          environmental_impact.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}

                  {investmentFirm?.economicImpact ? (
                    <li>
                      <strong>Economic Impact:</strong>{' '}
                      <>
                        {getlabelsByValues(
                          investmentFirm?.economicImpact,
                          economic_impact.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}

                  {investmentFirm?.diversityandInclusion ? (
                    <li>
                      <strong>Diversity and Inclusion:</strong>{' '}
                      <>
                        {getlabelsByValues(
                          investmentFirm?.diversityandInclusion,
                          diversity_and_inclusion.options,
                        ).map((label: string, index: number) => (
                          <div key={index} className="box">
                            <span className="text-2"> {label}</span>
                          </div>
                        ))}
                      </>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            )}
            {!investmentFirm?.exits && !investmentFirm?.notableInvestments ? (
              ''
            ) : (
              <div className="investment-successes">
                <h3>Investment Successes</h3>
                <ul>
                  {investmentFirm?.exits ? (
                    <li>
                      <strong>Number of Exits:</strong>{' '}
                      <span>{investmentFirm?.exits}</span>
                    </li>
                  ) : (
                    ''
                  )}
                  {investmentFirm?.notableInvestments ? (
                    <li>
                      <strong>Notable Investments:</strong>{' '}
                      <span>{investmentFirm?.notableInvestments}</span>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            )}
          </div>

          <Divider style={{ margin: '0px 0px' }} />

          <div style={{ width: '100%' }}>
            <h3 className="soft_green_title"> Firm Information</h3>

            <div className="firm-info">
              <div className="logo-section">
                <img
                  src={
                    investmentFirm?.logoUri
                      ? investmentFirm?.logoUri
                      : DEFAULT_FIRM_IMG
                  }
                  alt="investmentFirm logo"
                />
              </div>
              <div className="details-section">
                <div className="firm_title">
                  <h3>{investmentFirm?.name ?? ''}</h3>
                  <div className="website-section">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={getUrl(
                        investmentFirm?.websiteUri === 'https://null'
                          ? ''
                          : investmentFirm?.websiteUri,
                      )}
                    >
                      {investmentFirm?.websiteUri === 'https://null'
                        ? ''
                        : 'Visit Website'}
                    </a>
                  </div>
                </div>

                <ul>
                  <li>
                    {investmentFirm?.assetsUnderManagement ? (
                      <div className="text-group">
                        <strong>Assets Under Management:</strong>{' '}
                        {getlabelsByValues(
                          [investmentFirm?.assetsUnderManagement],
                          assets_under_management.options,
                        ).map((label: string, index: number) => (
                          <span key={`${label}-${index}`}>{label}</span>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </li>
                </ul>

                <div className="about_info description">
                  {aboutText && (
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: aboutText,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {investmentFirm?.id !== currentProfile?.organisationId && (
        <div className="message-box">
          <h2>Send a message?</h2>
          <p>Once accepted, they will join your network.</p>
          <div className="org-status-action">
            {props.cardStatusAction ? props.cardStatusAction() : <></>}
          </div>
        </div>
      )}
    </>
  )
}
