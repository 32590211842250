import React from 'react'
import { TeamMember } from '../../components/TeamMember/TeamMember'
import { TeamMember as TeamMemberType } from '../../../domain/types/Company'

interface TeamOverviewSectionProps {
  teamMembers: TeamMemberType[] | undefined
}
export const TeamOverviewSection: React.FC<TeamOverviewSectionProps> = ({
  teamMembers,
}) => {
  return (
    <div>
      <p className="font-semibold text-xl text-celadon-green mb-4">
        Team Overview
      </p>
      {teamMembers && teamMembers.length !== 0 && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {teamMembers.map((teamMember, index) => (
            <div key={index}>
              <TeamMember teamMember={teamMember} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
