import React from 'react'
import PitchDeck from '../../../assets/AppPitchDeck.png'
import BlackPitchDeck from '../../../assets/BlackPitchDeck.png'
import BusinessPlanPitchDeck from '../../../assets/BusinessPlanPitchDeck.png'
import StartupPitchDeck from '../../../assets/StartupPitchDeck.png'
import TechPitchDeck from '../../../assets/TechPitchDeck.png'
import { useIsMobile } from '../../../v1/presentation/common/useIsMobile'

export const PitchDeckSideContent = () => {
  const images = [
    PitchDeck,
    BlackPitchDeck,
    BusinessPlanPitchDeck,
    StartupPitchDeck,
    TechPitchDeck,
  ]
  const isMobile = useIsMobile()
  return !isMobile ? (
    <div className="flex flex-col gap-6 px-4 h-screen overflow-y-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`relative w-full h-[150px] overflow-hidden rounded-lg shadow-lg ${
            index % 2 === 0 ? 'ml-[-80px]' : 'mr-[-80px]'
          } ${index === images.length - 1 ? 'mb-[-40px]' : ''}`}
        >
          <div className="absolute inset-0 z-10 bg-gradient-to-r from-black/95 via-transparent to-black/95 pointer-events-none"></div>
          <img
            src={image}
            alt={`Pitch Deck ${index + 1}`}
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        </div>
      ))}
    </div>
  ) : (
    <div className="relative h-[200px] w-screen overflow-hidden flex items-center justify-center">
      {images.map((image, index) => (
        <div
          key={index}
          className="absolute w-[120px] h-[80px] rounded-lg shadow-lg"
          style={{
            left: `${index * 100}px`,
            zIndex: images.length - index,
            transform:
              index % 2 === 0 ? 'translateY(-20px)' : 'translateY(20px)',
          }}
        >
          <img
            src={image}
            alt={`Pitch Deck ${index + 1}`}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      ))}
    </div>
  )
}
