import * as React from 'react'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom'
import { FOOTER_LOGO, WVP_LOGO_NEW } from '../common/asset_paths'
import { getMenuLinkText } from '../common/constants'

import { UnorderedListOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Space, Dropdown, Button } from 'antd'
import useProfileStore from '../../data/appState/profileStore'
import { OrgType, Profile } from '../../domain/types/Profile'
import { signOutUser } from '../../data/services/auth/authService'
import { useLoadSiteData } from '../../data/services/common/siteDataService'

import {
  AccountIcon,
  ArrowMenu,
  ExploreIcon,
  InstagramIcon,
  MessagesIcon,
  NetworkIcon,
  SignoutIcon,
  UserIcon,
  XIcon,
} from '../common/utilComponents'
declare global {
  interface Window {
    MSStream?: unknown
  }
  interface Window {
    opera?: unknown
  }
  interface Window {
    gtag?: (
      command: string,
      eventName: string,
      eventParams?: Record<string, unknown>,
    ) => void
  }
}

export function AdminLayout() {
  const { isAuthenticated, profile, unReadConvoIds } = useProfileStore()

  const location = useLocation()
  const navigateTo = useNavigate()

  // const lsIsWebView = localStorage.getItem('iswebview');

  //const isWebView = lsIsWebView?.toString() === 'true';

  const [activemenu, setActivemenu] = useState('explore')
  const [mobileHeaderTitle, setMobileHeaderTitle] = useState('')
  const [openDropdown, setOpenDropdown] = useState(false)

  const bottomNavHidden = /^\/chat\/[a-zA-Z0-9]+$/.test(location.pathname)

  const profileOrgType = profile?.orgType as OrgType
  const isAdmin = profileOrgType === 'ADMIN'

  //const [isNotified, setIsNotified] = useState(false);

  useEffect(() => {
    setMobileHeaderTitle(getMenuLinkText(location.pathname))
    window.scrollTo(0, 0)
  }, [location])

  const { loaded } = useLoadSiteData()

  useEffect(() => {
    if (isAuthenticated) {
      if (!profile) {
        navigateTo('/login')
      } else {
        if (window.gtag && profile?.uid) {
          window.gtag('js', new Date().toISOString())
          window.gtag('config', 'G-90VEK60GXP', { user_id: profile.uid })
        }
      }
    }
  }, [isAuthenticated, profile, navigateTo])

  const { displayName, email, orgType, photoURL } = profile || ({} as Profile)

  const navigateToOrg = () => {
    navigateTo(`${orgType?.toLowerCase()}`)
    setOpenDropdown(false)
  }

  const profileIconMenu = (
    <div className="profile-card">
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          navigateTo('profile')
          setOpenDropdown(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            navigateTo('profile')
            setOpenDropdown(false)
          }
        }}
      >
        <div className="profile-header" key={'profile'}>
          {!photoURL ? (
            <Avatar icon={<UserOutlined />} className="navbar-img" />
          ) : (
            <Avatar
              src={<img src={photoURL} alt="avatar" />}
              className="profile-picture"
            />
          )}
          <div className="profile-info">
            <h2> {displayName}</h2>
            <p>{email}</p>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="profile-menu">
        <div className="menu-item" key={'orgnization'}>
          <div
            role="button"
            tabIndex={0}
            onClick={navigateToOrg}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') navigateToOrg()
            }}
          >
            <UserIcon className="icon" />
            <span>My {orgType?.toLowerCase()} profile</span>
          </div>
        </div>

        <div className="menu-item" key={'orgnization'}>
          <div
            role="button"
            tabIndex={0}
            onClick={() => navigateTo(`/admin/accounts/pending`)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ')
                navigateTo(`/admin/accounts/pending`)
            }}
          >
            <UnorderedListOutlined className="icon" />
            <span>Invites...</span>
          </div>
        </div>

        <div className="menu-item" key={'logout'}>
          <div
            role="button"
            tabIndex={0}
            onClick={signOutUser}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') signOutUser()
            }}
          >
            <SignoutIcon className="icon" />
            <span>Sign Out</span>
          </div>
        </div>
      </div>
    </div>
  )

  const unreadCount = unReadConvoIds.length ? (
    <span className="badge_count">{unReadConvoIds.length}</span>
  ) : null

  if (profile && !isAdmin) {
    return (
      <div>
        <h1> Access Restricted.</h1>
      </div>
    )
  }

  if (!profile) return <div className="portallayout admin-view"></div>

  return (
    <div className="portallayout admin-view">
      <div className="navbar">
        <nav className="navbar-header">
          <div className="navbar-container">
            <div className="mobile_header_title">
              <h1>{mobileHeaderTitle}</h1>
            </div>
            <Link to={'/admin/explore'} className="navbar-logo">
              <img src={WVP_LOGO_NEW} alt="Logo" />
            </Link>

            <ul className="navbar-menu">
              {!isAdmin ? (
                <></>
              ) : (
                <li className="navbar-item">
                  <Link
                    className={`navbar-link ${location.pathname === '/admin' ? 'active' : ''}`}
                    to={'/admin'}
                  >
                    Admin
                  </Link>
                </li>
              )}

              <li className="navbar-item">
                <Link
                  className={`navbar-link ${location.pathname.indexOf('explore') !== -1 ? 'active' : ''}`}
                  to={'/admin/explore'}
                >
                  Explore
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  className={`navbar-link ${location.pathname.indexOf('accounts') !== -1 ? 'active' : ''}`}
                  to={'/admin/accounts'}
                >
                  Accounts
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  className={`navbar-link ${location.pathname.indexOf('analytics') !== -1 ? 'active' : ''}`}
                  to={'/admin/analytics'}
                >
                  Analytics
                </Link>
              </li>

              <li className="navbar-item">
                <Dropdown
                  overlay={profileIconMenu}
                  arrow={{ pointAtCenter: false }}
                  placement="bottom"
                  open={openDropdown}
                >
                  <Space
                    onClick={() => {
                      setOpenDropdown(!openDropdown)
                    }}
                  >
                    {!photoURL ? (
                      <Avatar
                        size={40}
                        icon={<UserOutlined />}
                        className="navbar-img"
                      />
                    ) : (
                      <Avatar
                        size={40}
                        src={<img src={photoURL} alt="avatar" />}
                        className="navbar-img"
                      />
                    )}
                    <ArrowMenu />
                  </Space>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {isAdmin && loaded ? <Outlet></Outlet> : <></>}

      <footer className="footer">
        <div className="footer-left">
          <div className="footer-logo">
            <img src={FOOTER_LOGO} alt="" />
          </div>
          <div className="footer-links">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.wealthvp.com/privacy-statement"
            >
              Privacy Policy
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://cdn.prod.website-files.com/6639818d2d30ae6a87243cce/665758f0e4e15df709a1fb76_WealthVP%20Terms%20of%20Use.pdf"
            >
              Terms of Use
            </a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-menu">
            <a href="#!">Companies</a>
            <a href="#!">Investors</a>
            <a href="#!">Real Estate</a>
          </div>
          <div className="footer-contact">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://share.hsforms.com/1DlJ6huT7QYuyIc7mrKq2Vw4303u"
            >
              {' '}
              Contact Us
            </a>
            <div className="footer_social_link">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/wealthvp/"
                className="linkedin"
              >
                <InstagramIcon />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://x.com/WealthVP"
                className="x"
              >
                <XIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>

      {bottomNavHidden ? (
        ' '
      ) : (
        <>
          <div className="m-menu">
            <footer className="m-menu_item">
              <li className={activemenu === 'explore' ? 'active' : ''}>
                <Button
                  type="link"
                  onClick={() => {
                    navigateTo('/admin/explore')
                    setActivemenu('explore')
                  }}
                >
                  <ExploreIcon className="meun_icon" />
                </Button>
                <div className="menu_title">Explore</div>
              </li>
              <li className={activemenu === 'mynetwork' ? 'active' : ''}>
                <Button
                  type="link"
                  onClick={() => {
                    navigateTo('/mynetwork')
                    setActivemenu('mynetwork')
                  }}
                >
                  <NetworkIcon className="meun_icon" />
                </Button>
                <div className="menu_title">Network</div>
              </li>
              <li className={activemenu === 'chats' ? 'active' : ''}>
                <Button
                  type="link"
                  onClick={() => {
                    navigateTo('/chat')
                    setActivemenu('chats')
                  }}
                >
                  {unreadCount}
                  <MessagesIcon className="meun_icon" />
                </Button>
                <div className="menu_title">Messages</div>
              </li>
              <li className={activemenu === 'profile' ? 'active' : ''}>
                <Button
                  type="link"
                  onClick={() => {
                    navigateTo('/profile')
                    setActivemenu('profile')
                  }}
                >
                  <AccountIcon className="meun_icon" />
                </Button>
                <div className="menu_title">Account</div>
              </li>
            </footer>
          </div>
        </>
      )}
    </div>
  )
}
