import React, { useEffect, useState } from 'react'
import { getExploreData } from '../../../v1/data/services/explore/exploreService'
import { ExploreCardData } from '../../../v1/data/services/explore/ExploreCardData'
import { CompanyCard } from '../../../v1/presentation/components/CompanyCard/CompanyCard'
import { Tag } from '../../../v1/presentation/components/Tag/Tag'
import { useIsMobile } from '../../../v1/presentation/common/useIsMobile'

export const CompanyDetailsSideContent = () => {
  const [exploreData, setExploreData] = useState<ExploreCardData[]>([])
  const isMobile = useIsMobile()
  const loadExploreData = (expType) => {
    getExploreData(expType)
      .then((data) => {
        setExploreData(data)
      })
      .catch((e) => {
        console.error('Error in loading explore data', e)
      })
  }

  useEffect(() => {
    loadExploreData('companies')
  }, [])

  return !isMobile ? (
    <div className="flex flex-col gap-0">
      {exploreData.slice(0, 3).map((company, index) => (
        <div
          key={index}
          className="transform scale-[0.75] origin-top w-full mb-[-80px]"
        >
          <CompanyCard
            cardData={company}
            disabled={true}
            hideMessageButton={true}
          />
        </div>
      ))}
    </div>
  ) : (
    <div
      className="w-[320px] mx-auto overflow-hidden relative"
      style={{ maxWidth: '100%' }}
    >
      <div className="flex gap-6 overflow-hidden -ml-6 relative">
        <div
          className="absolute left-0 top-0 h-full w-10 pointer-events-none"
          style={{
            background:
              'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            zIndex: 1,
          }}
        />
        <div className="flex-shrink-0">
          <Tag label="Artificial Intelligence" index={String(Math.random())} />
        </div>
        <div className="flex-shrink-0">
          <Tag label="E-Commerce" index={String(Math.random())} />
        </div>
        <div className="flex-shrink-0 -ml-4">
          <Tag label="Robotics" index={String(Math.random())} />
        </div>

        <div
          className="absolute right-0 top-0 h-full w-10 pointer-events-none"
          style={{
            background:
              'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            zIndex: 1,
          }}
        />
      </div>

      <div className="flex gap-6 mt-4 overflow-hidden -ml-10 relative">
        <div
          className="absolute left-0 top-0 h-full w-10 pointer-events-none"
          style={{
            background:
              'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            zIndex: 1,
          }}
        />
        <div className="flex-shrink-0">
          <Tag label="Health & Wellness" index={String(Math.random())} />
        </div>
        <div className="flex-shrink-0">
          <Tag label="BlockChain / DeFi / Web3" index={String(Math.random())} />
        </div>
        <div className="flex-shrink-0">
          <Tag label="CleanTech" index={String(Math.random())} />
        </div>
        <div
          className="absolute right-0 top-0 h-full w-10 pointer-events-none"
          style={{
            background:
              'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            zIndex: 1,
          }}
        />
      </div>

      <div className="flex gap-6 mt-4 overflow-hidden -ml-6 relative">
        <div
          className="absolute left-0 top-0 h-full w-10 pointer-events-none"
          style={{
            background:
              'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            zIndex: 1,
          }}
        />
        <div className="flex-shrink-0">
          <Tag label="Artificial Intelligence" index={String(Math.random())} />
        </div>
        <div className="flex-shrink-0">
          <Tag label="ClimateTech" index={String(Math.random())} />
        </div>
        <div className="flex-shrink-0 -ml-4">
          <Tag label="Industrial Machines" index={String(Math.random())} />
        </div>
        <div
          className="absolute right-0 top-0 h-full w-10 pointer-events-none"
          style={{
            background:
              'linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
            zIndex: 1,
          }}
        />
      </div>
    </div>
  )
}
