import { Modal } from 'antd'
import * as React from 'react'
import { Profile } from '../../domain/types/Profile'
import { setAppDownloadPromptPreference } from '../../data/services/profile/profileService'
import {
  APP_STORE_BADGE,
  GOOGLE_PLAY_BADGE,
  MOBILE_ONE,
  QR_CODE_IMG,
} from '../common/asset_paths'

export interface IDownloadPromptProps {
  profile: Profile
}

type deviceType = 'android' | 'ios' | 'ipad' | 'other'

function detectDevice(): deviceType {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (/android/i.test(userAgent as string)) {
    return 'android'
  } else if (
    /iPad|Macintosh/i.test(userAgent as string) &&
    'ontouchend' in document
  ) {
    return 'ipad'
  } else if (
    /iPad|iPhone|iPod/i.test(userAgent as string) &&
    !window.MSStream
  ) {
    return 'ios'
  } else {
    return 'other'
  }
}

export function DownloadPrompt({ profile }: IDownloadPromptProps) {
  const { disableAppDownloadPrompt, isWalkThroughPending } = profile

  const [toggolePrompt, setToggolePrompt] = React.useState<boolean>(
    !isWalkThroughPending && !disableAppDownloadPrompt,
  )

  const NeverAskAgain = async () => {
    if (profile?.db_ref_id) {
      try {
        await setAppDownloadPromptPreference(profile?.db_ref_id, true)
      } catch (error) {
        console.error(error)
      } finally {
        setToggolePrompt(false)
      }
    }
  }

  const device: deviceType = detectDevice()

  const webLinkView = (
    <div className="qr-div">
      <img
        src={QR_CODE_IMG}
        alt="QR Code"
        id="qr-code"
        style={{ width: '150px', marginTop: '1rem' }}
      />
    </div>
  )

  const iosLinkView = (
    <a href="https://apps.apple.com/in/app/wealthvp/id6479521386" id="ios-link">
      <img
        src={APP_STORE_BADGE}
        alt="Download on the App Store"
        className="store-badge"
      />
    </a>
  )

  const aosLinkView = (
    <a
      href="https://play.google.com/store/apps/details?id=com.wealthvp.wealthvp"
      id="android-link"
    >
      <img
        src={GOOGLE_PLAY_BADGE}
        alt="Get it on Google Play"
        className="store-badge"
      />
    </a>
  )

  let rightSection = <></>

  switch (device) {
    case 'other':
      rightSection = webLinkView
      break

    case 'ios':
      rightSection = iosLinkView
      break

    case 'android':
      rightSection = aosLinkView
      break
    default:
      rightSection = <></>
      break
  }

  return (
    <div>
      <Modal
        className="modal-content"
        okText="Don't show this again"
        onOk={() => {
          NeverAskAgain()
        }}
        onCancel={() => setToggolePrompt(false)}
        centered
        open={toggolePrompt}
        width={1000}
      >
        <div className="modal_body">
          <div className="floating_img">
            <img className="mobile_img" src={MOBILE_ONE} alt="" />
          </div>

          <div className="main_content">
            <p>Never miss an opportunity!</p>
            <p>Download our mobile app today to access deals on-the-go</p>
          </div>

          {rightSection}
        </div>
      </Modal>
    </div>
  )
}
