import React from 'react'
import { ExploreCardData } from '../../../data/services/explore/ExploreCardData'
import TagContainer from '../Tag/TagContainer'
import { DetailsListFields } from '../../utils/constants'
import { getReadableFundingRound } from '../../utils/formatters'
import { convertNumbertoCurrency } from '../../common/utils'
import { Button } from 'antd'
import CardTitle from '../CardTitle/CardTitle'
import Thumbnail from '../Thumbnail/Thumbnail'
import { ConnectionStatus } from '../../../domain/types/Connection'

interface CompanyCardProps {
  cardData: ExploreCardData
  connectionStatus?: ConnectionStatus
  sendRequestToConnect?: () => void
  gotoConversation?: () => void
  onCardSelect?: (cardData: ExploreCardData) => void
  disabled?: boolean
  hideMessageButton?: boolean
}
export const CompanyCard: React.FC<CompanyCardProps> = ({
  cardData,
  connectionStatus,
  sendRequestToConnect,
  gotoConversation,
  onCardSelect,
  disabled,
  hideMessageButton,
}) => {
  const industryFields = cardData?.detailsList?.find(
    (item) => item?.label === DetailsListFields.INDUSTRIES,
  )

  const valuation = cardData?.detailsList?.find(
    (item) => item?.label === DetailsListFields.VALUATION,
  )

  const offering = cardData?.detailsList?.find(
    (item) => item?.label === DetailsListFields.OFFERING,
  )

  const hasFundingRound =
    'fundingRound' in cardData.orgData && cardData.orgData.fundingRound

  const shouldRenderCard =
    industryFields &&
    valuation &&
    offering &&
    hasFundingRound &&
    cardData.orgData.minimumInvestment

  const isNotConnected =
    !connectionStatus || connectionStatus === ConnectionStatus.NOT_CONNECTED

  const readableFundingRound =
    'fundingRound' in cardData.orgData
      ? getReadableFundingRound(cardData?.orgData?.fundingRound || '')
      : null

  const companyIntro =
    'companyFiles' in cardData.orgData
      ? cardData.orgData.companyFiles?.find(
          (cf) => cf.category === 'companyIntro',
        )
      : null

  const firebaseUrl = 'firebasestorage.googleapis.com'

  return shouldRenderCard ? (
    <div
      key={cardData?.id}
      className="relative min-h-auto rounded-lg bg-[#191b1e] capitalize h-full"
      id={cardData.id}
    >
      <div>
        <div
          className="w-full h-[175px] rounded-t-lg border-none"
          role="button"
          tabIndex={0}
          onClick={() => onCardSelect && onCardSelect(cardData)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onCardSelect && onCardSelect(cardData)
            }
          }}
        >
          <Thumbnail
            imageUrl={cardData.imageUrl}
            title={cardData.title}
            videoUrl={
              companyIntro?.url?.includes(firebaseUrl)
                ? undefined
                : companyIntro?.url
            }
          />
        </div>
      </div>
      <div
        className="p-4 bg-[#191b1e] rounded-b-lg cursor-pointer"
        role="button"
        tabIndex={0}
        onClick={() => onCardSelect && onCardSelect(cardData)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onCardSelect && onCardSelect(cardData)
          }
        }}
      >
        <CardTitle title={cardData.title} />
        <div className="text-white mt-2">
          <div className="flex text-left flex-col justify-start min-h-[275px] max-h-[275px] overflow-hidden capitalize mb-6">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="grid-item text-left">
                <div className="text-gray font-semibold text-[14px]">Round</div>
                <div className="font-medium text-[14px] capitalize">
                  {readableFundingRound
                    ? readableFundingRound.charAt(0) +
                      readableFundingRound.slice(1).toLowerCase()
                    : null}
                </div>
              </div>
              <div className="grid-item text-left">
                <div className="text-gray font-semibold text-[14px]">
                  Offering
                </div>
                <div className="font-medium text-[14px]">
                  {offering && Array.isArray(offering?.value)
                    ? `${offering.value[0]}${offering.value.length > 1 ? ` +${offering.value.length - 1}` : ''}`
                    : null}
                </div>
              </div>
              <div className="grid-item text-left">
                <div className="text-gray font-semibold text-[14px]">
                  Seeking
                </div>
                <div className="font-medium text-[14px]">
                  {convertNumbertoCurrency(cardData.orgData.minimumInvestment)}
                </div>
              </div>
              <div className="grid-item text-left">
                <div className="text-gray font-semibold text-[14px]">
                  Valuation
                </div>
                <div className="font-medium text-[14px]">
                  {valuation && Array.isArray(valuation?.value)
                    ? valuation.value[0]
                    : null}
                </div>
              </div>
            </div>
            <div
              className={`${
                cardData?.detailsList?.length
                  ? 'frame tag-sections-' + cardData?.detailsList?.length
                  : 'frame no-tag-sections'
              }`}
            >
              <TagContainer
                tagListTitle={'Industry Focus'}
                tags={industryFields?.value}
              />
            </div>
          </div>
        </div>
      </div>
      {!hideMessageButton && (
        <div className="absolute bottom-4 left-0 w-full text-center px-4">
          {isNotConnected ? (
            <Button
              className="w-full py-2 font-medium text-base leading-7 text-black bg-yellow-400 hover:bg-yellow-500"
              onClick={() => sendRequestToConnect && sendRequestToConnect()}
              disabled={disabled}
            >
              Message
            </Button>
          ) : (
            <div
              className="cursor-pointer"
              role="button"
              tabIndex={0}
              onClick={gotoConversation}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  gotoConversation && gotoConversation()
                }
              }}
            >
              <span className="text-pure-white">{connectionStatus}</span>
            </div>
          )}
        </div>
      )}
    </div>
  ) : null
}
