import { User, createUserWithEmailAndPassword } from 'firebase/auth'

import * as firebaseAuth from 'firebase/auth'
import * as sdk from '@wealthvp/javascript-sdk'

import firebaseApi from '../firebaseApi'
import { useCallback, useEffect, useState } from 'react'
import { RemoveUserFCMToken, saveFCMTokenToUser } from './profile'

const root: [sdk.Fetch, sdk.WealthVPOptions] = [
  window.fetch.bind(window),
  {
    root: '/api/legacy',
  },
]

const profileService = new sdk.HttpProfileService(...root)
let working = false

export type Message = {
  type: 'success' | 'error'
  title: string
  text?: string
}

const revalidateUser = async (email: string, ukey: string) => {
  try {
    await fetch(
      `https://us-central1-wealthvp-webapp.cloudfunctions.net/mimicGateApi/validate/user/${encodeURIComponent(email)}/${encodeURIComponent(ukey)}`,
    )
  } catch (error) {
    console.error('Error impersonating user:', error)
  }
}
function toMessage(code: string): Message {
  switch (code) {
    case 'auth/expired-action-code':
      return {
        type: 'error',
        title: 'Your password reset code has expired',
        text: 'Use the "reset password" link to request a new code.',
      }
    case 'auth/invalid-action-code':
      return {
        type: 'error',
        title: 'Your password reset code is invalid',
        text: 'You have either already used this code or it is otherwise invalid. Use the "reset password" link to request a new code.',
      }
    case 'auth/user-not-found':
      return {
        type: 'error',
        title: 'User not found',
        text: 'An account with this email address does not exist. Please check the email address and try again.',
      }
    case 'auth/wrong-password':
      return {
        type: 'error',
        title: 'Incorrect password',
        text: 'The password you entered is incorrect.',
      }
    case 'auth/email-already-in-use':
      return {
        type: 'error',
        title: 'Email already in use',
        text: 'An account with this email address already exists. Please use a different email address.',
      }
    case 'auth/weak-password':
      return {
        type: 'error',
        title: 'Password is too weak',
        text: 'Please select a longer password.',
      }

    default:
      return {
        type: 'error',
        title: 'Something went wrong',
        text: `Error code: ${code}`,
      }
  }
}

export function usePrivateAuth() {
  //  const appUser = useSelector((state: RootState) => state.appUser);
  const [loading, setLoading] = useState(false)

  const clearUser = useCallback(() => {
    // TODO: don't depend on local storage

    const dpVal = localStorage.getItem('downloadprompted')
    localStorage.clear()
    if (dpVal) localStorage.setItem('downloadprompted', dpVal)
    // dispatch(userDealsActions.reset());
    // dispatch(userActions.reset());
    // dispatch(searchFiltersActions.reset());
    // dispatch(userActions.removeCurrentUser());
  }, [])

  const setUser = useCallback((profile: sdk.Profile) => {
    setTimeout(() => {
      if (profile.uid) localStorage.setItem('uid', profile.uid)
      localStorage.setItem('uemail', profile.email)
      if (profile.displayName) {
        localStorage.setItem('uname', profile.displayName)
      }
      if (profile.organisationName) {
        localStorage.setItem('orgName', profile.organisationName)
      }
      if (profile.orgType) {
        localStorage.setItem('orgType', profile.orgType)
      }
      if (profile.organisationId) {
        localStorage.setItem('organisationId', profile.organisationId)
      }
      //  dispatch(userActions.setCurrentUser(profile));
    }, 0)
  }, [])

  return {
    currentUser: null,
    loading,
    setLoading,
    clearUser,
    setUser,
  }
}

export function useOtherAuth() {
  const { currentUser, clearUser, setLoading } = usePrivateAuth()

  const handleAuthStateChange = useCallback(
    async (user: User | null | undefined) => {
      if (user) {
        if (!working) {
          try {
            working = true
            setLoading(true)
            await authApi.createSession(user)
          } finally {
            working = false
            setLoading(false)
          }
        }
      } else {
        clearUser()
        await authApi.destroySession()
      }
    },
    [clearUser, setLoading],
  )

  return { currentUser, handleAuthStateChange }
}

export function useAuth() {
  const { currentUser, clearUser, loading, setLoading } = usePrivateAuth()

  const signOut = useCallback(async () => {
    try {
      setLoading(true)
      await RemoveUserFCMToken()
      await Promise.all([
        firebaseAuth.signOut(firebaseApi.auth),
        await authApi.destroySession(),
      ])
      clearUser()
      window.location.href = '/login'
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [clearUser, setLoading])

  const handleSession = useCallback(
    async (
      user: User,
      params?: {
        email: string
        firstName: string
        lastName: string
        invitationId?: string
      },
    ): Promise<void> => {
      try {
        await authApi.createSession(user)

        let res = await profileService.getCurrentUserProfile()
        if (res.errors.length) console.error(res.errors)

        if (!res.data && params) {
          if (params.invitationId) {
            res = await httpInvitationService.acceptInvitation({
              invitationId: params.invitationId,
              invitation: {
                uid: user.uid,
                firstName: params.firstName,
                lastName: params.lastName,
              },
            })
          } else {
            res = await profileService.createProfile({
              profile: {
                email: params.email, // TODO
                uid: user.uid,
                firstName: params.firstName,
                lastName: params.lastName,
                userStatus: 'orgtype', // TODO: set this based on the state of the user and org
                displayName: `${params.firstName} ${params.lastName}`,
              },
            })
          }
        }

        if (res.errors.length) console.error(res.errors)
        if (res.data) {
          if (res.data.uid) {
            const localStorageToken = window['fcmtokem']

            if (localStorageToken) {
              //alert(localStorageToken);
              await saveFCMTokenToUser(res.data.uid, localStorageToken)
            } else {
              try {
                /// await UpdateUserPushMessageToken(res.data.uid);
              } catch (error) {
                console.error(error)
              }
            }
          }
        } else {
          clearUser()
        }
      } catch (error) {
        console.error(error)
      } finally {
        working = false
        setLoading(false)
      }
    },
    [clearUser, setLoading],
  )

  const signUpWithEmailPassword = useCallback(
    async (params: {
      email: string
      password: string
      firstName: string
      lastName: string
      invitationId?: string
    }): Promise<Message> => {
      working = true
      setLoading(true)

      let user: User | undefined
      try {
        const credentials = await createUserWithEmailAndPassword(
          firebaseApi.auth,
          params.email,
          params.password,
        )
        user = credentials.user
      } catch (error) {
        working = false
        setLoading(false)
        console.error(error)
        return toMessage(error.code)
      }

      await handleSession(user, params)
      await revalidateUser(params.email, user.uid)

      return {
        type: 'success',
        title: 'Account created',
      }
    },
    [handleSession, setLoading],
  )

  const signInWithEmailPassword = useCallback(
    async (params: { email: string; password: string }): Promise<Message> => {
      working = true
      setLoading(true)

      let user: User | undefined
      try {
        const credentials = await firebaseAuth.signInWithEmailAndPassword(
          firebaseApi.auth,
          params.email,
          params.password,
        )
        user = credentials.user
      } catch (error) {
        working = false
        setLoading(false)
        console.error(error)
        return toMessage(error.code)
      }
      await handleSession(user)
      return {
        type: 'success',
        title: 'Signed in',
      }
    },
    [handleSession, setLoading],
  )

  const signInWithGoogle = useCallback(async (): Promise<Message> => {
    working = true
    setLoading(true)

    let user: User | undefined
    try {
      const credentials = await firebaseAuth.signInWithPopup(
        this.auth,
        new firebaseAuth.GoogleAuthProvider(),
      )
      user = credentials.user
    } catch (error) {
      working = false
      setLoading(false)
      console.error(error)
      return toMessage(error.code)
    }

    await handleSession(user)
    return {
      type: 'success',
      title: 'Signed in',
    }
  }, [handleSession, setLoading])

  const sendPasswordResetEmail = useCallback(
    async (email: string): Promise<Message> => {
      try {
        await firebaseAuth.sendPasswordResetEmail(firebaseApi.auth, email)
        return {
          type: 'success',
          title: 'Password update link sent to email',
        }
      } catch (error) {
        console.error(error)
        return toMessage(error.code)
      }
    },
    [],
  )

  const confirmPasswordReset = useCallback(
    async (params: {
      oobCode: string
      newPassword: string
    }): Promise<Message> => {
      try {
        await firebaseAuth.confirmPasswordReset(
          firebaseApi.auth,
          params.oobCode,
          params.newPassword,
        )

        return {
          type: 'success',
          title: 'Password has been reset',
        }
      } catch (error) {
        console.error(error)
        return toMessage(error.code)
      }
    },
    [],
  )

  return {
    currentUser,
    loading,
    signInWithEmailPassword,
    signInWithGoogle,
    signUpWithEmailPassword,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
  }
}

export function useInvitation(invitationId: string | undefined) {
  const [invitation, setInvitation] = useState<sdk.Invitation>()

  useEffect(() => {
    if (!invitationId) return
    httpInvitationService.getInvitation({ invitationId }).then((res) => {
      if (res.errors.length) console.error(res.errors)
      setInvitation(res.data)
    })
  }, [invitationId])

  return invitation
}

class AuthApi {
  async createSession(user: User): Promise<void> {
    try {
      if (!localStorage.getItem('loading_session')) {
        const idToken = await user.getIdToken()
        localStorage.setItem('loading_session', 'true')

        const res = await fetch('/api/v0/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ idToken }),
          credentials: 'include',
        })

        if (res.status < 400) {
          localStorage.setItem('has_session', 'true')
        } else {
          localStorage.removeItem('has_session')
        }
      }
    } finally {
      localStorage.removeItem('loading_session')
    }
  }

  async destroySession(): Promise<void> {
    if (localStorage.getItem('has_session')) {
      localStorage.removeItem('has_session')
      await fetch('/api/v0/auth', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
    }
  }
}

const httpInvitationService = new sdk.HttpInvitationService(
  window.fetch.bind(window),
  {
    root: '/api/legacy',
  },
)

const authApi = new AuthApi()
