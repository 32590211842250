import { WVP_LOGO_NEW } from '../../v1/presentation/common/constants'
import { Button, Form, Input } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { useAuth, useInvitation } from '../../api/features/auth'
import toast from '../../v1/presentation/common/Toast'
import { useState } from 'react'
import useSiteStore from '../../v1/data/appState/siteStore'

export default function OnBoarding() {
  const location = useLocation()
  const token = new URLSearchParams(location.search).get('token') ?? undefined
  const invitation = useInvitation(token)
  const { signUpWithEmailPassword } = useAuth()
  const [form] = Form.useForm()
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const { setfullSiteLoader } = useSiteStore()

  const onFinish = async (values) => {
    setIsUpdating(true)
    setfullSiteLoader(true)
    if (invitation) {
      try {
        const msg = await signUpWithEmailPassword({
          email: invitation.email,
          password: values.password,
          firstName: invitation.firstName,
          lastName: invitation.lastName,
          invitationId: invitation.id,
        })
        if (msg.text) {
          toast.info(msg.title, msg.text)
        }
        if (invitation.orgType === 'COMPANY') {
          window.location.href = window.location.origin + '/next-steps'
        } else {
          window.location.href = window.location.origin + '/login'
        }
      } catch (error) {
        console.error('Error during sign up:', error)
        toast.error('Failed to complete sign up. Please try again.')
      } finally {
        setIsUpdating(false)
        setfullSiteLoader(false)
      }
    }
  }

  if (!invitation) {
    return (
      <div key={1} className="login_page">
        <div className="header_text">
          <img className="logo_asset" src={WVP_LOGO_NEW} alt="WealthVP" />
          <h3>Invalid invitation link. Please contact your administrator.</h3>
        </div>
      </div>
    )
  }

  return (
    <div key={1} className="login_page">
      <div className="header_text">
        <img className="logo_asset" src={WVP_LOGO_NEW} alt="WealthVP" />
        <div>
          <h3>
            You have been invited to
            <span className="">{invitation.orgName}</span>
          </h3>
        </div>
        <h2>New account</h2>
      </div>
      <div className="form_input">
        <Form
          form={form}
          name="signup-form"
          className="signup-form"
          onFinish={onFinish}
        >
          <Form.Item name="email">
            <Input
              size="large"
              defaultValue={invitation.email}
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Enter your email address..."
              autoComplete="false"
              disabled
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Password mandatory' }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Enter a password..."
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdating}>
              SIGN UP
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
