import { useIsMobile } from '../../../v1/presentation/common/useIsMobile'
import { TeamMember } from '../../../v1/presentation/components/TeamMember/TeamMember'

export const PersonalDetailsSideContent = () => {
  const isMobile = useIsMobile()
  const teamMembers = [
    {
      displayName: 'Jessica Galestrom',
      photoUrl: 'https://randomuser.me/api/portraits/women/68.jpg',
      socialLink: 'https://www.linkedin.com',
      title: 'Chief Executive Officer | Founder',
      description:
        'With 20 years in the health science industry, my talents are perfect for leading our company into the future.',
    },
    {
      displayName: 'Jefferson Monocle',
      photoUrl: 'https://randomuser.me/api/portraits/men/45.jpg',
      socialLink: 'https://www.linkedin.com',
      title: 'Chief Financial Officer | Founder',
      description:
        '10 years in financial management, 5 years leading healthcare teams. Excited to drive growth.',
    },
    {
      displayName: 'Jess Depeche',
      photoUrl: 'https://randomuser.me/api/portraits/women/23.jpg',
      socialLink: 'https://www.linkedin.com',
      title: 'Founder',
      description:
        'My passion for CleanTech drives my commitment to a sustainable future for all around the world.',
    },
    {
      displayName: 'Sondra Johnstone',
      photoUrl: 'https://randomuser.me/api/portraits/women/65.jpg',
      socialLink: 'https://www.linkedin.com',
      title: 'Chief Marketing Officer | Founder',
      description: 'Leading our creative marketing team to achieve success.',
    },
  ]

  return !isMobile ? (
    <div
      className="relative flex flex-col gap-6 px-4 overflow-hidden"
      style={{
        maxHeight: '125vh', // Restrict the height of the container
        marginTop: '-220px', // Pull the first card partially out of view
        paddingTop: '50px', // Adjust inner spacing for alignment
      }}
    >
      {teamMembers.map((teamMember, index) => (
        <div
          key={index}
          className="relative bg-cool-black p-6 rounded-lg shadow-custom-inset"
          style={{
            clipPath: 'inset(0px 0px -20px 0px)', // Glow at the bottom
            height: '50%', // Ensure cards occupy fixed height
          }}
        >
          <TeamMember teamMember={teamMember} />
        </div>
      ))}
    </div>
  ) : (
    <div className="flex justify-center">
      <div
        className="relative bg-cool-black p-3 rounded-lg shadow-custom-inset"
        style={{
          clipPath: 'inset(0px 0px -20px 0px)', // Glow at the bottom
          height: '50%', // Ensure cards occupy fixed height
          width: '80%',
          borderRadius: '20px',
        }}
      >
        <TeamMember
          teamMember={teamMembers[0]}
          condensed={true}
          hideSocialIcons={true}
          titleColor={'text-white'}
        />
      </div>
    </div>
  )
}
