import * as React from 'react'
import {
  BiFilter,
  CheckBoxIcon,
  CheckedBoxIcon,
} from '../../common/utilComponents'
import useConversationsStore from '../../../data/appState/conversationsStore'
import ThrottledInput from '../../common/ThrottledInput'

type ChatFilter = {
  label: string
  value: string
  selected: boolean
}
export function ChatFilters() {
  const chatFilters: ChatFilter[] = [
    { label: 'Unread', value: 'isUnread', selected: false },
    { label: 'Archived', value: 'isArchived', selected: false },
    { label: 'Group', value: 'isGroup', selected: false },
    { label: 'Direct', value: 'isDirect', selected: false },
  ]

  const {
    filters,
    showFilters,
    searchKey,
    setSearchKey,
    toggleFilters,
    resetFilters,
    updateFilter,
  } = useConversationsStore()

  const handleInputChange = (value: string) => {
    console.log('Throttled Input:', value)
    setSearchKey(value.trim())
  }

  const containerClass = `absolute top-[36px] right-0 ${
    showFilters ? '' : 'hidden'
  } w-[19rem] mt-2 text-white bg-[#191B1E] border border-[#515151] rounded shadow-lg ring-1 ring-black ring-opacity-5 z-10`

  return (
    <div className="relative flex w-full">
      <ThrottledInput
        value={searchKey}
        onChange={handleInputChange}
        throttleDelay={600} // Throttling delay in ms
        placeholder="Search"
        className="w-full border-0 h-11 forced-colors:#303536"
      />
      <div
        className="relative inline-block text-left"
        role="button"
        tabIndex={0}
        onClick={toggleFilters}
        onKeyDown={toggleFilters}
      >
        <summary className="flex items-center justify-between absolute top-[10px] right-[35px] w-full cursor-pointer  focus:outline-none">
          <BiFilter />
        </summary>
        <div className={containerClass}>
          <div className="flex items-center justify-between px-6 pd-0">
            <h3 className="text-xl font-semibold">Filters</h3>
            <button className="text-[#a1a1a1] hover:text-gray-200 text-[32px]">
              &times;
            </button>
          </div>
          <div className="p-6 space-y-2">
            <p className="text-sm text-[#a1a1a1]">Type</p>
            {chatFilters.map((filter: ChatFilter) => {
              return (
                <div
                  key={filter.value}
                  role="button"
                  tabIndex={-1}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    updateFilter(filter.value, !filters[filter.value])
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault()
                      e.stopPropagation()
                      updateFilter(filter.value, !filters[filter.value])
                    }
                  }}
                >
                  <label className="flex items-center gap-2 space-x-2 cursor-pointer">
                    {filters[filter.value] ? (
                      <CheckedBoxIcon />
                    ) : (
                      <CheckBoxIcon />
                    )}
                    <span className="px-[10px] py-[2px] text-sm font-medium">
                      {filter.label}
                    </span>
                  </label>
                </div>
              )
            })}
          </div>

          <div className="flex justify-between px-6 py-4 border-t rounded-br-[4px] rounded-bl-[4px] bg-cool-black border-[#515151]">
            <button
              className="px-4 py-2 font-medium rounded text-yellow-green text-[16px]"
              onClick={resetFilters}
            >
              Reset
            </button>
            <button className="px-4 py-2 font-medium rounded text-[16px] text-cool-black bg-yellow-green ">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
