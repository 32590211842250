/* eslint-disable */
import { Company } from '../../../domain/types/Company'
import { InvestmentFirm } from '../../../domain/types/Investor'
import { RealEstateSponsor } from '../../../domain/types/RealEstate'
import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import { updateCompany } from '../company/companyService'
import { updateInvestorFirm } from '../investor/investorService'
import { updateRealEstateSponsor } from '../realEstate/realEstateSponsorService'

export function getAllAccountsByOrg(type: string): Promise<any[]> {
  const accountsService = new FirestoreCollectionApi<any>(type)

  return accountsService.getAllDocs()
}

export function updateActiveStateOrgByIdandType(
  orgId: string,
  orgType: string,
  isDisabled: boolean,
): Promise<void> {
  const data = { id: orgId, isDisabled } as unknown
  // Disable respective organisation..
  if (orgType === 'COMPANY') {
    return updateCompany(data as Company)
  } else if (orgType === 'INVESTOR') {
    return updateInvestorFirm(data as InvestmentFirm)
  } else if (orgType === 'REALESTATE') {
    return updateRealEstateSponsor(orgId, data as RealEstateSponsor)
    //need to update opportunities alsoooo
  } else {
    throw new Error('Unsupported ORG Type')
  }
}
