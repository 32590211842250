import './App.css'
import { RouterProvider } from 'react-router-dom'
import AppRouter from './app.router'
import { ConfigProvider, ThemeConfig } from 'antd'

import { BaseLayout } from './v1/presentation/layouts/BaseLayout'

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#E7FF87',
    colorSuccess: '#42aa0e',
    lineWidth: 1,
    fontFamily: '"Work Sans", sans-serif;',
    colorWarning: '#faad14',
    colorTextBase: '#0F1217',
    colorBgBase: '#ffffff',
    colorBgContainer: '#ffffff',
    borderRadius: 4,
    colorPrimaryTextActive: '#D2D5FC',
    fontSizeHeading1: 24,
    fontSizeHeading2: 20,
    fontSizeHeading3: 18,
    fontSizeHeading4: 16,
  },
  components: {
    Button: {
      lineWidth: 1,
      colorPrimary: '#E7FF87',
      fontWeightStrong: 600,
      colorFill: '#0F1217',
      defaultHoverColor: '#0F1217',
    },
    Radio: {
      colorBgContainer: '#FFF',
      colorBorder: '#d9d9d9',
      colorPrimary: '#E7FF87',
      borderRadius: 0,
    },
    DatePicker: {},
    Dropdown: {
      colorFill: '#fff',
      colorPrimary: '#ffffff',
    },
    Input: {
      colorTextLabel: '#ffffff',
      colorTextPlaceholder: '#737c88',
    },
    List: {
      fontFamily: '"Work Sans", sans-serif;',
    },
    Card: {
      fontFamily: '"Work Sans", sans-serif;',
      colorBorder: '#6d7278',
    },
    Select: {
      colorTextPlaceholder: '#737c88',
    },
    InputNumber: {
      colorTextPlaceholder: '#737c88',
    },
    Tabs: {
      colorBorder: '#B2EAB6',
      colorPrimary: '#B2EAB6',
      itemHoverColor: '#B2EAB6',
      colorText: '#FFFFFF',
      colorPrimaryActive: '#B2EAB6',
    },
    Form: {
      labelColor: '#ffffff',
    },
    Table: {
      borderRadius: 0,
    },
  },
}

function App() {
  //const { loaded } = useSiteData();

  // const { handleAuthStateChange } = useOtherAuth();

  // useEffect(() => {
  //   const unsubscribe = firebaseApi.auth.onAuthStateChanged((user: User) => {
  //     handleAuthStateChange(user);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, [handleAuthStateChange]);

  return (
    <ConfigProvider theme={theme}>
      <BaseLayout>
        <RouterProvider router={AppRouter} />
      </BaseLayout>
    </ConfigProvider>
  )
}

export default App
