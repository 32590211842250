import * as React from 'react'
import { Row, Col, Input, Form, Button, Select, Avatar } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import useProfileStore from '../../data/appState/profileStore'
import { updateProfile } from '../../data/services/profile/profileService'
import { ShimmerSocialPost } from 'react-shimmer-effects'
import FileUploader from '../common/FileUploader'
import { SocialLinksEditor } from '../components/social-links-form-item'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import toast from '../common/Toast'
import { BackArrow, ImagePlus } from '../common/utilComponents'

export function CompanyProfileEdit() {
  const navigateTo = useNavigate()
  const [form] = Form.useForm()

  const { profile, setProfile } = useProfileStore()

  const quillRef = useRef<ReactQuill | null>(null)
  const [pictureUrl, setPictureUrl] = useState<string | null>(
    profile?.photoURL ?? '',
  )
  const [socialLinks, setSocialLinks] = useState(profile?.socialLinks ?? [])

  const [isUpdating, setIsUpdating] = useState(false)
  const [selectedGoal, setSelectedGoal] = useState<string>()
  const [charCount, setCharCount] = useState(0)

  const handleGoalChange = (value) => {
    setSelectedGoal(value)
  }

  const onFinish = async (values) => {
    if (!pictureUrl) {
      toast.error('Please upload profile picture', '')
      return
    }

    if (charCount < 1) {
      toast.error('Description', 'Please enter Description')
      setIsUpdating(false)
      return
    }
    if (charCount > 150) {
      toast.error('Description', 'should be less than 150 characters')
      setIsUpdating(false)
      return
    }
    values['socialLinks'] = socialLinks.length !== 0 ? [...socialLinks] : []
    if (!values?.goals?.includes('Other')) {
      values.otherGoal = ''
    }
    const profileRes = { ...profile, ...values }
    try {
      await updateProfile(profileRes).then(() => {
        toast.success('Your profile is updated', '')

        setProfile(profileRes)
      })
    } catch (error) {
      console.error(error)
      toast.warning('Failed to save', '')
    } finally {
      setIsUpdating(false)
    }
    navigateTo(-1)
  }
  useEffect(() => {
    if (profile) {
      form.setFieldsValue(profile)
      setSelectedGoal(profile.goals)
    }

    setTimeout(() => {
      handleTextChange()
    }, 1000)
  }, [profile, form])

  if (!profile) return <ShimmerSocialPost type="both" />

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo)
    toast.error('Failed to save', 'Please fill the required fields')
  }
  const handleTextChange = () => {
    const editor = quillRef.current?.getEditor()
    if (editor) {
      const contentLength = editor?.getLength() - 1 // by default quilleditor has 1 char, so we are removing in count
      setCharCount(contentLength)
    }
  }

  return (
    <div className="body_layout">
      <Row>
        <Col span={24}>
          <div className="profile_edit">
            <div>
              <Form
                form={form}
                name="profile-edit-form"
                onFinish={onFinish}
                className="grid-3"
                layout="vertical"
                onFinishFailed={onFinishFailed}
              >
                <div className="sub_header">
                  <div
                    className="back_icon"
                    role="button"
                    tabIndex={0}
                    onClick={() => navigateTo(-1)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        navigateTo(-1)
                      }
                    }}
                  >
                    <BackArrow />
                    <h3>Back</h3>
                  </div>
                </div>
                <div className="wvp-p-flex-d profile_edit_content">
                  <>
                    <h3 className="soft_green_title">Personal Profile</h3>
                    <div className="firm_profile_form">
                      <div className="upload_wrapper">
                        <div className="label">
                          <div
                            className="text-wrapper"
                            style={{ color: '#F04438' }}
                          >
                            *
                          </div>
                          <div
                            className="text-wrapper-2"
                            style={{ color: '#ffffff' }}
                          >
                            Profile Photo
                          </div>
                        </div>
                        <div className="img_wrapper">
                          {pictureUrl ? (
                            <Avatar
                              shape="circle"
                              alt="Image"
                              icon={<img src={pictureUrl} alt="" />}
                            />
                          ) : (
                            <>
                              <Avatar
                                shape="circle"
                                alt="Image"
                                icon={<ImagePlus />}
                              />
                            </>
                          )}
                          <div className="upload-container">
                            <p className="upload-box">
                              <FileUploader
                                onUploadComplete={(e) => {
                                  setPictureUrl(e?.url || null)
                                }}
                              />
                            </p>
                          </div>
                          <p className="text-wrapper-3">
                            JPG, PNG format (min. 150x150px)
                          </p>
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="grid-3">
                          <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your first name!',
                              },
                            ]}
                          >
                            <Input placeholder="First name" />
                          </Form.Item>

                          <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your last name!',
                              },
                            ]}
                          >
                            <Input placeholder="Last name" />
                          </Form.Item>
                          <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your title',
                              },
                            ]}
                          >
                            <Input placeholder="Job title" />
                          </Form.Item>
                        </div>

                        <div className="description_editor">
                          <Form.Item
                            name="about"
                            label="Description"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Description',
                              },
                            ]}
                            validateStatus={charCount < 1 ? 'error' : ''}
                            help={
                              charCount < 1 ? 'Please enter Description' : ''
                            }
                          >
                            {/* <Input.TextArea rows={10} /> */}
                            <ReactQuill
                              theme="snow"
                              ref={quillRef}
                              onChange={handleTextChange}
                            />
                          </Form.Item>

                          <div className="err-message">
                            {charCount > 150 ? (
                              <p>Characters should not be more than 150</p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="counter">
                            <span id="charCount">{charCount} </span> / 150
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                  <div className="social_editor_view">
                    <SocialLinksEditor
                      value={socialLinks}
                      onChange={(v) => {
                        setSocialLinks(v)
                      }}
                    />
                  </div>
                  <h3 className="Secondary-textcolor"> Personal Information</h3>
                  <p className="small_textcolor">
                    Update your private account contact information and
                    password.
                  </p>

                  <div className="grid-3">
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Please enter valid Email',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Email" disabled />
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="Phone Number">
                      <Input placeholder="Enter Phone" type="number" />
                    </Form.Item>
                  </div>

                  {/* option settings start */}
                  <h3
                    className="Secondary-textcolor"
                    style={{ marginBottom: '1rem' }}
                  >
                    Optional Settings
                  </h3>
                  <div className="grid-3">
                    <Form.Item name="goals" label="What are your goals?">
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select your goal"
                        onChange={handleGoalChange}
                        options={[
                          { value: 'Investing', label: 'Investing' },
                          { value: 'Advising', label: 'Advising' },
                          {
                            value: 'Strategic Guidance',
                            label: 'Strategic Guidance',
                          },
                          {
                            value: 'Operational Support',
                            label: 'Operational Support',
                          },
                          { value: 'Network Access', label: 'Network Access' },
                          { value: 'Other', label: 'Other' },
                          { value: 'None', label: 'None' },
                        ]}
                      />
                    </Form.Item>

                    {/* Conditionally render the text box if "Other" is selected */}
                    {selectedGoal?.includes('Other') && (
                      <Form.Item
                        name="otherGoal"
                        label="Please specify your goal"
                        rules={[
                          {
                            required: true,
                            message: 'Please specify your goal',
                          },
                        ]}
                      >
                        <Input placeholder="Specify your goal" />
                      </Form.Item>
                    )}
                  </div>
                  {/* option settings end */}
                  <div className="actions_btn">
                    <Form.Item>
                      <div className="del_account">Delete Account</div>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="default"
                        className="mr-4"
                        onClick={() => navigateTo(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        loading={isUpdating}
                        type="primary"
                        htmlType="submit"
                        className="bg-primary"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
