import { Row, Col, Input, Form, Button, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useProfileStore from '../../data/appState/profileStore'
import { updateProfile } from '../../data/services/profile/profileService'
import { ShimmerSocialPost } from 'react-shimmer-effects'

import toast from '../common/Toast'
import { BackArrow } from '../common/utilComponents'

export function ProfileEdit() {
  const navigateTo = useNavigate()
  const [form] = Form.useForm()

  const { profile, setProfile } = useProfileStore()

  const [isUpdating, setIsUpdating] = useState(false)
  const [selectedGoal, setSelectedGoal] = useState<string>()

  const handleGoalChange = (value) => {
    setSelectedGoal(value)
  }

  const onFinish = async (values) => {
    if (!values?.goals?.includes('Other')) {
      values.otherGoal = ''
    }
    const profileRes = { ...profile, ...values }
    try {
      await updateProfile(profileRes).then(() => {
        toast.success('Your profile is updated', '')

        setProfile(profileRes)
      })
    } catch (error) {
      console.error(error)
      toast.warning('Failed to save', '')
    } finally {
      setIsUpdating(false)
    }
    navigateTo(-1)
  }
  useEffect(() => {
    if (profile) {
      form.setFieldsValue(profile)
      setSelectedGoal(profile.goals)
    }
  }, [profile, form])

  if (!profile) return <ShimmerSocialPost type="both" />

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo)
    toast.error('Failed to save', 'Please fill the required fields')
  }

  return (
    <div className="body_layout">
      <Row>
        <Col span={24}>
          <div className="profile_edit">
            <div>
              <Form
                form={form}
                name="profile-edit-form"
                onFinish={onFinish}
                className="grid-3"
                layout="vertical"
                onFinishFailed={onFinishFailed}
              >
                <div className="sub_header">
                  <div
                    className="back_icon"
                    role="button"
                    tabIndex={0}
                    onClick={() => navigateTo(-1)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        navigateTo(-1)
                      }
                    }}
                  >
                    <BackArrow />
                    <h3>Back</h3>
                  </div>
                </div>
                <div className="wvp-p-flex-d profile_edit_content">
                  <h3 className="soft_green_title ">Personal Information</h3>

                  <div className="grid-3">
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Please enter valid Email',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Email" disabled />
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="Phone Number">
                      <Input placeholder="Enter Phone" type="number" />
                    </Form.Item>
                    {/* <Form.Item label=" ">
                      <Button
                        loading={isUpdating}
                        type="default"
                        htmlType="button"
                      >
                        Update Password
                      </Button>
                    </Form.Item> */}
                  </div>

                  {/* option settings start */}
                  <h3
                    className="Secondary-textcolor"
                    style={{ marginBottom: '1rem' }}
                  >
                    Optional Settings
                  </h3>
                  <div className="grid-3">
                    <Form.Item name="goals" label="What are your goals?">
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select your goal"
                        onChange={handleGoalChange}
                        options={[
                          { value: 'Investing', label: 'Investing' },
                          { value: 'Advising', label: 'Advising' },
                          {
                            value: 'Strategic Guidance',
                            label: 'Strategic Guidance',
                          },
                          {
                            value: 'Operational Support',
                            label: 'Operational Support',
                          },
                          { value: 'Network Access', label: 'Network Access' },
                          { value: 'Other', label: 'Other' },
                          { value: 'None', label: 'None' },
                        ]}
                      />
                    </Form.Item>

                    {/* Conditionally render the text box if "Other" is selected */}
                    {selectedGoal?.includes('Other') && (
                      <Form.Item
                        name="otherGoal"
                        label="Please specify your goal"
                        rules={[
                          {
                            required: true,
                            message: 'Please specify your goal',
                          },
                        ]}
                      >
                        <Input placeholder="Specify your goal" />
                      </Form.Item>
                    )}
                  </div>
                  {/* option settings end */}
                  <div className="actions_btn">
                    <Form.Item>
                      <div className="del_account">Delete Account</div>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        loading={isUpdating}
                        type="primary"
                        htmlType="submit"
                        className="bg-primary"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
