import {
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Space, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { FullScreen } from '../common/utilComponents'

export interface ImageViewerProps {
  imageUrls: string[]
  onFullscreen: () => void // Add this prop for handling fullscreen
}

export function ImageViewer({ imageUrls, onFullscreen }: ImageViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [zoomLevel, setZoomLevel] = useState(100) // Initialize zoomLevel as 100%
  const [loading, setLoading] = useState(true) // Initialize loading state
  const [iconPosition, setIconPosition] = useState({ bottom: 0, right: 0 })
  const containerRef = useRef<HTMLDivElement | null>(null) // Add type for the ref

  useEffect(() => {
    const updateIconPosition = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        setIconPosition({
          bottom: window.innerHeight - rect.bottom + 10, // 10px from the container's bottom
          right: window.innerWidth - rect.right + 10, // 10px from the container's right
        })
      }
    }

    updateIconPosition() // Initial calculation
    window.addEventListener('scroll', updateIconPosition)
    window.addEventListener('resize', updateIconPosition)

    return () => {
      window.removeEventListener('scroll', updateIconPosition)
      window.removeEventListener('resize', updateIconPosition)
    }
  }, [])

  if (!imageUrls || !imageUrls.length) {
    return <div className="no-docs">No Documents to view</div>
  }

  const currentImage = imageUrls[currentIndex]

  const nextImage = () => {
    if (currentIndex < imageUrls.length - 1) {
      setCurrentIndex(currentIndex + 1)
      setLoading(true) // Set loading to true when moving to the next image
    }
  }

  const prevImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
      setLoading(true) // Set loading to true when moving to the previous image
    }
  }

  const zoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 25, 200)) // Cap zoom at 200%
  }

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 25, 25)) // Cap zoom out at 25%
  }

  return (
    <div className="doc-list-content">
      <div
        className="gallery_container"
        onClick={onFullscreen}
        ref={containerRef}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onFullscreen()
          }
        }}
      >
        {loading && (
          <div className="loader-container">
            <Spin size="large" indicator={<LoadingOutlined spin />} />
          </div>
        )}
        <img
          className={`zoom-${zoomLevel}`}
          src={currentImage}
          alt="pitch"
          onLoad={() => setLoading(false)} // Set loading to false when the image is fully loaded
          style={{
            transition: 'transform 0.3s ease',
            display: loading ? 'none' : 'block', // Hide the image while loading
          }}
        />
        <div
          style={{
            bottom: `${iconPosition.bottom}px`,
            right: `${iconPosition.right}px`,
          }}
          className="fixed w-10 h-10 flex justify-center items-center text-sm rounded-full "
        >
          <FullScreen onClick={onFullscreen} />
        </div>
      </div>

      <div className="doc_footer_control">
        <Space className="zoom-control">
          <ZoomOutOutlined onClick={zoomOut} />
          {`${zoomLevel}%`}{' '}
          {/* Display the zoom level as an integer percentage */}
          <ZoomInOutlined onClick={zoomIn} />
        </Space>
        <Space className="full_screen"></Space>
        <Space className="doc-control">
          <LeftOutlined onClick={prevImage} />
          {currentIndex + 1} / {imageUrls.length}
          <RightOutlined onClick={nextImage} />
        </Space>
      </div>
    </div>
  )
}
